<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div>
        <el-form
          class="filter-form"
          :model="form"
          label-width="100px"
          size="small"
        >
          <div class="flex-x-between">
            <div class="flex-warp flex-shrink0">
              <el-form-item label="车牌号">
                <el-input
                  v-model="form.carNum"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="doSearch"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      effect="light"
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader
                  v-model="form.operateCompanyId"
                ></operateCompanyCascader>
              </el-form-item>
              <el-form-item label="进店日期">
                <el-date-picker
                  class="w100"
                  v-model="form.enterTime"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="截止时间"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="flex">
              <div class="el-form-item--small">
                <!-- <div
                  @click="showMore = !showMore"
                  class="el-form-item__label pointer"
                >
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"
                  ></i>
                </div> -->
                <div class="flex">
                  <el-button
                    class="btn_search"
                    type="primary"
                    size="small"
                    @click="doSearch"
                    >查询</el-button
                  >
                  <el-button class="btn_search" size="small" @click="empty"
                    >置空</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-warp">
            <el-form-item label="业务来源">
              <el-select
                v-model="form.billSourceTypeId"
                @change="selectBillSource"
                @clear="deleteBillSource"
                filterable
                clearable
                remote
                placeholder="搜索业务来源"
                :remote-method="searchBillSource"
                :loading="searchBillSourceLoading"
              >
                <el-option
                  v-for="item in billSourceList"
                  :key="item.billSourceTypeId"
                  :label="item.name"
                  :value="item.billSourceTypeId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入"
                clearable
                @keyup.enter.native="doSearch"
              ></el-input>
            </el-form-item>
            <el-form-item label="车单状态">
              <el-select
                v-model="form.billState"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in carBillState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="施工时间">
              <el-date-picker
                class="w100"
                v-model="form.constructTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="完工时间">
              <el-date-picker
                class="w100"
                v-model="form.completeTime"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="截止时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div style="display: flex; justify-content: space-between">
        <div class="custom-tabs">
          <div
            @click="changeBillType('')"
            :class="{
              'custom-tab-item': true,
              active: billType === '',
            }"
          >
            全部
          </div>
          <div
            @click="changeBillType(0)"
            :class="{
              'custom-tab-item': true,
              active: billType === 0,
            }"
          >
            自费
          </div>
          <div
            @click="changeBillType(1)"
            :class="{
              'custom-tab-item': true,
              active: billType === 1,
            }"
          >
            包干
          </div>
          <div
            @click="changeBillType(2)"
            :class="{
              'custom-tab-item': true,
              active: billType === 2,
            }"
          >
            理赔
          </div>
          <div
            @click="changeBillType(3)"
            :class="{
              'custom-tab-item': true,
              active: billType === 3,
            }"
          >
            索赔
          </div>
        </div>
        <el-button
          v-auth="610"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <div class="flex h100">
        <el-table
          class="custom-table"
          :data="tableData"
          border
          v-fixedScrollBar="$refs.baseContainer"
        >
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column min-width="180" label="操作门店" prop="companyName">
          </el-table-column>
          <el-table-column width="100" label="车牌号" prop="carNum">
          </el-table-column>
          <el-table-column
            min-width="170"
            label="接车单号/业务类型"
            prop="billCode"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="goCarBillDetail(row.billId)">{{
                row.billCode
              }}</span>
              <div>{{ row.billTypeName }}</div>
            </template>
          </el-table-column>
          <el-table-column width="100" label="进店时间" prop="enterTime">
          </el-table-column>
          <el-table-column width="100" label="预交车时间" prop="planFinishTime">
          </el-table-column>
          <el-table-column width="100" label="交车超时" prop="finishOverTime">
            <template slot="header">
              <span>交车超时</span>
              <el-tooltip content="整单完工时间-预交车时间">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="施工超时"
            prop="constructOverTime"
          >
            <template slot="header">
              <span>施工超时</span>
              <el-tooltip content="维修用时-车单服务时效之和">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            min-width="180"
            label="初诊与备注"
            prop="firstVisitRemark"
          >
            <template #default="{ row }">
              <el-tooltip :content="row.firstVisitRemark">
                <div class="text-ellipsis-2-row">
                  {{ row.firstVisitRemark }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="100" label="车单状态">
            <template #default="{ row }">
              <div>{{ row.billState }}</div>
              <div class="color626">{{ row.dispatchState }}</div>
            </template>
          </el-table-column>
          <el-table-column width="140" label="车单标签" prop="billLabels">
          </el-table-column>
          <el-table-column width="100" label="确定故障时间" prop="diagnoseTime">
          </el-table-column>
          <el-table-column width="100" label="维修技师" prop="technician">
          </el-table-column>
          <el-table-column width="100" label="施工时间" prop="constructTime">
          </el-table-column>
          <el-table-column width="100" label="完工时间" prop="finishTime">
          </el-table-column>
          <el-table-column width="110" label="检车人" prop="checker">
          </el-table-column>
          <el-table-column
            min-width="140"
            label="服务项目"
            prop="billServiceName"
          >
          </el-table-column>
          <el-table-column width="120" label="服务标准价" prop="servicePrice">
            <template slot="header">
              <span>服务标准价</span>
              <el-tooltip
                content="当前服务项标准价，总部统一标准，见服务管理-服务标准价"
              >
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </template>
            <template #default="{ row }">
              {{ row.servicePrice | toThousands }}
            </template>
          </el-table-column>
          <el-table-column width="120" label="配件报价" prop="goodPrice">
            <template slot="header">
              <span>配件报价</span>
              <el-tooltip content="当前车单该服务项配件报价小计">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </template>
            <template #default="{ row }">
              {{ row.goodPrice | toThousands }}
            </template>
          </el-table-column>
          <el-table-column width="120" label="配件成本" prop="goodCost">
            <template #default="{ row }">
              {{ row.goodCost | toThousands }}
            </template>
          </el-table-column>
          <el-table-column width="120" label="配件出库明细" prop="goodsOutboundDetail" show-overflow-tooltip>
            <template #default="{ row }">
              {{ row.goodsOutboundDetail }}
            </template>
          </el-table-column>
          <el-table-column width="120" label="标准毛利" prop="grossMarginPrice	">
            <template #header>
              <span>标准毛利</span>
              <el-tooltip content="=服务标准价+配件报价-配件成本">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </template>
            <template #default="{ row }">
              {{ row.grossMarginPrice | toThousands }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="服务用时" prop="serviceTime">
          </el-table-column>
          <el-table-column width="130" label="整单维修用时" prop="allTaketime">
            <template slot="header">
              <span>整单维修用时</span>
              <el-tooltip content="整单最后完工时间-整单最早开始施工时间">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader.vue';

// 列表接口
import {
  getFaultDiagnosisDetail,
  faultDiagnosisDetailExport,
} from '@/api/dataBoard';
// 业务来源接口
import { getListForBill } from '@/api/billSource';

// 工具
import { mapState } from 'vuex';

// 字典
import { carLossBillState } from '@/service/dict/dictData';

export default {
  name: 'faultDiagnosisDetail',
  components: {
    pagination,
    ExportDialog,
    operateCompanyCascader,
  },
  data() {
    return {
      form: {},
      // 展示更多筛选
      showMore: false,
      // 导出设置
      exportUrl: faultDiagnosisDetailExport,
      showExportDialog: false,

      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      // 单据类型
      billType: '',
      //搜索业务来源字段
      searchBillSourceLoading: false,
      billSourceList: [],

      // 排除已取消状态
      carBillState: carLossBillState.filter((item) => item.value !== 3),

      // 列表数据
      tableData: [],

      activeId: '',
      billId: '',
      detailDrawerVisibility: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  watch: {},
  activated() {},
  created() {
    this.form.operateCompanyId = [this.companyId];

    this.$set(this.form, 'enterTime', [
      new Date().Format('yyyy-MM-dd'),
      new Date().Format('yyyy-MM-dd'),
    ]);

    this.getData();
  },
  mounted() {},
  methods: {
    //远程搜索业务来源列表
    searchBillSource(keyWord = '') {
      this.searchBillSourceLoading = true;
      getListForBill({
        // ...this.pagination,
        name: keyWord,
      })
        .then((res) => {
          this.billSourceList = res.records;
        })
        .finally(() => {
          this.searchBillSourceLoading = false;
        });
    },
    // 选择业务来源
    selectBillSource(billSourceId) {},
    //删除业务来源
    deleteBillSource() {},
    /**
     * 变更单据类型
     */
    changeBillType(type) {
      this.billType = type;
      this.doSearch();
    },
    /**
     * 跳转详情页
     */
    goCarBillDetail(id) {
      this.$router.push({
        name: 'carBillDetail',
        query: {
          id,
        },
      });
    },

    /**
     * 清空查询条件
     */
    empty() {
      this.form = {};
    },

    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },

    /**
     * 点击查询
     */
    doSearch() {
      this.getData();
    },

    /**
     * 获取数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var part = {
        enterStartTime: this.form.enterTime?.[0]
          ? this.form.enterTime?.[0] + ' 00:00:00'
          : '',
        enterEndTime: this.form.enterTime?.[1]
          ? this.form.enterTime?.[1] + ' 23:59:59'
          : '',
        constructStartTime: this.form.constructTime?.[0]
          ? this.form.constructTime?.[0] + ' 00:00:00'
          : '',
        constructEndTime: this.form.constructTime?.[1]
          ? this.form.constructTime?.[1] + ' 23:59:59'
          : '',
        completeStartTime: this.form.completeTime?.[0]
          ? this.form.completeTime?.[0] + ' 00:00:00'
          : '',
        completeEndTime: this.form.completeTime?.[1]
          ? this.form.completeTime?.[1] + ' 23:59:59'
          : '',

        billType: this.billType,
      };
      var data = Object.assign(this.form, part, this.pageOption);
      getFaultDiagnosisDetail(data).then((res) => {
        this.total = res.total;
        this.tableData = res.records;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
