<template>
  <div class="wrapper">
    <div class="table-wrap">
      <el-tabs ref="tabsRef" v-model="tabIndex" @tab-click="tabChange">
        <el-tab-pane label="交付中心信息" name="0" />
        <el-tab-pane label="配置" name="1" />
      </el-tabs>
      <div v-if="tabIndex == 0">
        <el-form ref="exportRef" :inline="true" :model="searchForm" label-width="70px" class="search-form">
          <el-form-item label="整备组" prop="preparationGroupName">
            <el-input
              v-model="searchForm.preparationGroupName"
              clearable
              placeholder="请输入整备组"
              @clear="onSubmit"
              @keyup.enter.native="onSubmit" />
          </el-form-item>
          <el-form-item label="交付中心" prop="deliveryCenterName">
            <el-input
              v-model="searchForm.deliveryCenterName"
              clearable
              placeholder="请输入交付中心"
              @clear="onSubmit"
              @keyup.enter.native="onSubmit" />
          </el-form-item>
          <el-form-item label="车牌号码" prop="carNumber">
            <el-input v-model="searchForm.carNumber" clearable placeholder="请输入车牌" @clear="onSubmit" @keyup.enter.native="onSubmit" />
          </el-form-item>
          <el-form-item label="项目" prop="carNumber">
            <el-select v-model="searchForm.billType" clearable placeholder="请选择">
              <el-option v-for="(item, index) in billTypeOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="建单时间" prop="createdTime">
            <el-date-picker
              v-model="searchForm.createdTime"
              clearable
              end-placeholder="结束日期"
              range-separator="至"
              size="small"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd" />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="onSubmit">查询</el-button>
            <el-button v-auth="825" type="primary" @click="showExportDialog = true">导出</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="tableData" :header-cell-style="{ height: '32px', padding: '0!important' }" :height="tableHei" class="new-table">
          <el-table-column align="center" label="序号" type="index" width="55" />
          <el-table-column align="center" label="整备单号" property="billCode" show-overflow-tooltip width="220" />
          <el-table-column align="center" label="整备组" prop="preparationGroupName" show-overflow-tooltip width="150"/>
          <el-table-column align="center" label="交付中心" prop="deliveryCenterName" show-overflow-tooltip width="220" />
          <el-table-column align="center" label="车牌号" prop="carNumber" show-overflow-tooltip />
          <el-table-column align="center" label="项目" prop="billTypeDesc" show-overflow-tooltip />
          <el-table-column align="center" label="项目价" prop="servPrice" show-overflow-tooltip>
            <template #default="{ row }">
              {{ row.servPrice | toThousands }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="处理人" prop="creator" show-overflow-tooltip />
          <el-table-column align="center" label="建单时间" prop="dateCreated" show-overflow-tooltip />
          <el-table-column align="center" label="图片组">
            <template #default="{ row }">
              <div style="padding: 10px 0 0">
                <el-badge :value="row.imageUrls?.length">
                  <img
                    :src="row.imageUrls[0]"
                    alt=""
                    class="thumbnail-img"
                    style="width: 30px; height: 30px"
                    @click="previewImgs(row.imageUrls, [])" />
                </el-badge>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap">
          <div class="total-text">共{{ total }}条</div>
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[20, 30, 50, 100]"
            :total="total"
            layout="->, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
        </div>
      </div>
      <div v-if="tabIndex == 1" class="settings-wrap">
        <div class="flex h100">
          <!-- 整备交付中心 -->
          <div style="width: 34%">
            <div class="flex-x-between table_top">
              <span>整备交付中心</span>
            </div>
            <el-table
              ref="singleTable"
              :data="DeliveryCenterData"
              border
              class="customer-table"
              height="85%"
              highlight-current-row
              @row-click="clickGroupRow">
              <el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
              <el-table-column label="整备交付中心" property="preparationName"></el-table-column>
            </el-table>
            <template class="footer">
              <pagination :total="DeliveryCenterTotal" :typeStyle="typeStyle" type="mini" @pageChange="DeliveryCenterChange"></pagination>
            </template>
          </div>
          <!-- 整备组 -->
          <div style="width: 34%; margin: 0 16px">
            <div class="flex-x-between table_top">
              <span>整备组</span>
              <p v-auth="816" @click="groupOperate(0)">新增</p>
            </div>
            <el-table
              ref="singleTable"
              :data="groupData"
              border
              class="customer-table"
              empty-text="请先选择整备交付中心"
              height="85%"
              highlight-current-row
              @row-click="clickCenterRow">
              <el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
              <el-table-column label="整备组" property="preparationGroupName"></el-table-column>
              <el-table-column align="center" label="操作" width="100">
                <template #default="{ row }">
                  <div class="flex" style="justify-content: center">
                    <p v-auth="817" class="blue pointer" @click.stop="groupOperate(1, row)">编辑</p>
                    <el-popconfirm
                      v-auth="818"
                      icon="el-icon-info"
                      icon-color="red"
                      title="确定删除该整备组吗？"
                      @confirm="groupOperate(2, row)">
                      <p slot="reference" class="red pointer" @click.stop style="margin-left: 8px">删除</p>
                    </el-popconfirm>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <template class="footer">
              <pagination :total="groupTotal" :typeStyle="typeStyle" type="mini" @pageChange="changeGroup"></pagination>
            </template>
          </div>
          <!-- 交付中心 -->
          <div style="width: 30%">
            <div class="flex-x-between table_top">
              <span>交付中心</span>
              <p v-auth="820" @click="deliveryCenterOperate(0)">新增</p>
            </div>
            <el-table ref="singleTable" :data="deliveryCenterData" border empty-text="请先选择整备组" height="85%">
              <el-table-column label="序号" align="center" type="index" width="60"></el-table-column>
              <el-table-column label="交付中心" property="name"></el-table-column>
              <el-table-column label="操作" align="center" width="100">
                <template #default="{ row }">
                  <div class="flex" style="justify-content: center">
                    <p v-auth="821" class="blue pointer" @click="deliveryCenterOperate(1, row)">编辑</p>
                    <el-popconfirm
                      v-auth="822"
                      icon="el-icon-info"
                      icon-color="red"
                      title="确定删除该交付中心吗？"
                      @confirm="deliveryCenterOperate(2, row)">
                      <p slot="reference" class="red pointer" style="margin-left: 8px">删除</p>
                    </el-popconfirm>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <template class="footer">
              <pagination :total="deliveryCenterTotal" :typeStyle="typeStyle" type="mini" @pageChange="changeDeliveryCenter"></pagination>
            </template>
          </div>
        </div>
      </div>
    </div>
    <export-dialog
      :filterForm="form"
      :pageOption="pageOption"
      :setWid="'30%'"
      :total="total"
      :url="exportUrl"
      :visibility.sync="showExportDialog"
    />
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form class="dialog-wrap" v-if="dialogType == 0" ref="dialogRef" :model="dialogForm" :rules="dialogRule">
        <el-form-item label="整备组名称" label-width="100" prop="preparationGroupName">
          <el-input v-model="dialogForm.preparationGroupName" autocomplete="off" clearable />
        </el-form-item>
        <el-form-item label="负责人" label-width="100" prop="responsibleUserName">
          <el-input v-model="dialogForm.responsibleUserName" autocomplete="off" clearable />
        </el-form-item>
      </el-form>
      <el-form v-if="dialogType == 1" ref="dialogRef" :model="dialogForm" :rules="dialogRule2">
        <el-form-item label="交付中心名称" label-width="100" prop="companyId">
          <el-select
            class="dialog-select"
            v-model="dialogForm.companyId"
            filterable
            placeholder="请选择交付中心名称"
          >
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.companyId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSave('dialogRef')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCarDeliverMsgList,
  getCarDeliverMsgUrl,
  getDeliveryCenterList,
  getGroupAdd,
  getGroupDel,
  getGroupEdit,
  getGroupList,
  getCenterList,
  getCenterAdd,
  getCenterEdit,
  getCenterDel,
} from "@/api/customer/carFactoryCompensationActivity";
import { listAllCompany } from "@/api/system/GyBillRepairNotificationSetting";
import ExportDialog from "@/components/ExportDialog.vue";
import { previewImage } from "@/utils/fileApi";
import pagination from "@/components/Pagination.vue";

export default {
  name: "carDeliverMsg",
  components: {  pagination, ExportDialog },
  data() {
    return {
      tableHei: "50vh",
      tableData: [],
      tabIndex: 0,
      searchForm: {},
      billTypeOptions: [
        {id: 0, label: "洗车", value: 0},
        {id: 1, label: "发车", value: 1},
        {id: 2, label: "装GPS", value: 2},
      ],
      showExportDialog: false,
      total: 0,
      pageOption: {
        size: 20,
        current: 1,
      },
      form: {},
      exportUrl: getCarDeliverMsgUrl,
      pageSize: 20,
      currentPage: 1,
      DeliveryCenterData: [],
      DeliveryCenterTotal: 0,
      groupData: [],
      groupRowData: {},
      groupTotal: 0,
      pageGroupSize: 10,
      currentGroupPage: 1,
      typeStyle: true,
      dialogTitle: "",
      dialogVisible: false,
      dialogForm: {},
      dialogRule: {
        preparationGroupName: [{ required: true, message: "请输入整备组名称", trigger: "blur" }],
        responsibleUserName: [{ required: true, message: "请输入负责人名称", trigger: "blur" }],
      },
      dialogRule2: {
        companyId: [{ required: true, message: "请选择交付中心名称", trigger: "change" }],
      },
      groupOperateType: 0,
      deliveryCenterData: [],
      deliveryCenterTotal: 0,
      DeliveryRowData: {},
      pageDeliverySize: 10,
      currentDeliveryPage: 1,
      dialogType: 0,
      deliveryOperateType: 0,
      companyList: [],
    };
  },
  created() {
    this.getListData();
  },
  mounted() {
    this.setTableHei(170);
  },
  methods: {
    /** 动态控制表格高度 **/
    setTableHei(num) {
      this.$nextTick(() => {
        let windowsHei = document.documentElement.clientHeight;
        let exportHei = this.$refs.exportRef.$el.offsetHeight;
        let tabsHei = this.$refs.tabsRef.$el.offsetHeight;
        this.tableHei = windowsHei - tabsHei - exportHei - num;
      });
    },
    /** tab点击 **/
    tabChange(e) {
      if (this.tabIndex == "0") {
        this.setTableHei(170);
        this.getListData();
      } else {
        this.pageSize = 10;
        this.currentPage = 1;
        this.getDeliveryCenterFn();
      }
    },
    /** 获取列表数据 **/
    getListData() {
      let obj = {
        current: this.currentPage,
        size: this.pageSize,
        preparationGroupName: this.searchForm.preparationGroupName, // 整备组名称
        deliveryCenterName: this.searchForm.deliveryCenterName, // 交付中心名称
        carNumber: this.searchForm.carNumber, // 车牌号码
        billType: this.searchForm.billType, // 建单类型
        createdBeginDateTime: this.searchForm?.createdTime?.length > 0 ? `${this.searchForm?.createdTime[0]} 00:00:00` : "", // 建单开始时间
        createdEndDateTime: this.searchForm?.createdTime?.length > 0 ? `${this.searchForm?.createdTime[1]} 23:59:59` : "", // 建单结束时间
      };
      this.tableData = [];
      getCarDeliverMsgList(obj).then(res => {
        console.log("res---->>>", res);
        this.tableData = res.records;
        this.total = Number(res.total);
      });
    },
    /** 预览图片 */
    previewImgs(imgs, titles) {
      previewImage(imgs, 0, titles);
    },
    /** 获取整备交付中心列表数据 **/
    getDeliveryCenterFn() {
      let obj = {
        current: this.currentPage,
        size: this.pageSize,
      };
      this.DeliveryCenterData = [];
      getDeliveryCenterList(obj).then(res => {
        this.DeliveryCenterData = res.records;
        this.DeliveryCenterTotal = res.total;
      });
    },
    /** 整备交付中心页码切换 **/
    DeliveryCenterChange(e) {
      this.pageSize = e.size;
      this.currentPage = e.current;
      this.getDeliveryCenterFn();
    },
    /** 点击获取整备组列表数据 **/
    clickGroupRow(e) {
      this.groupRowData = e;
      this.getGroupFn();
    },
    /** 获取整备组列表数据函数 **/
    getGroupFn() {
      let obj = {
        current: this.currentGroupPage,
        size: this.pageGroupSize,
        preparationId: this.groupRowData?.id, // 整备交付中心ID
      };
      this.groupData = [];
      getGroupList(obj).then(res => {
        this.groupData = res.records;
        this.groupTotal = res.total;
      });
    },
    /** 整备组页码切换 **/
    changeGroup(e) {
      console.log("eeee--->>>", e);
      this.pageGroupSize = e.size;
      this.currentGroupPage = e.current;
      this.getGroupFn();
    },
    /** 整备组操作 **/
    groupOperate(type, row) {
      this.dialogType = 0;
      this.groupOperateType = type;
      switch (type) {
        case 0: // 新增
          this.dialogTitle = "新增整备组";
          this.dialogVisible = true;
          this.$set(this.dialogForm, "preparationGroupName", "");
          this.$set(this.dialogForm, "responsibleUserName", "");
          break;
        case 1: // 编辑
          this.dialogTitle = "编辑整备组";
          this.dialogVisible = true;
          this.$set(this.dialogForm, "id", row.id);
          this.$set(this.dialogForm, "preparationGroupName", row.preparationGroupName);
          this.$set(this.dialogForm, "responsibleUserName", row.responsibleUserName);
          break;
        case 2: // 删除
          let obj = {
            id: row.id,
          };
          getGroupDel(obj).then(res => {
            this.$message.success("删除成功！");
            this.getGroupFn();
          });
          break;
      }
    },
    /** 弹框确认操作 **/
    dialogSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.dialogType == 0) {
            let obj = {
              preparationId: this.groupRowData.id,
              preparationGroupName: this.dialogForm.preparationGroupName,
              responsibleUserName: this.dialogForm.responsibleUserName,
            };
            switch (this.groupOperateType) {
              case 0: // 新增
                getGroupAdd(obj).then(res => {
                  this.$message.success("操作成功！");
                  this.dialogVisible = false;
                  this.getGroupFn();
                });
                break;
              case 1: // 编辑
                obj.id = this.dialogForm.id;
                getGroupEdit(obj).then(res => {
                  this.$message.success("编辑成功！");
                  this.dialogVisible = false;
                  this.getGroupFn();
                });
                break;
            }
          } else {
            let data = {
              groupId: this.DeliveryRowData.id,
              companyId: this.dialogForm.companyId,
            };
            switch (this.deliveryOperateType) {
              case 0: // 新增
                getCenterAdd(data).then(res => {
                  this.$message.success("操作成功！");
                  this.dialogVisible = false;
                  this.getDeliveryFn();
                });
                break;
              case 1: // 编辑
                data.id = this.dialogForm.id;
                getCenterEdit(data).then(res => {
                  this.$message.success("编辑成功！");
                  this.dialogVisible = false;
                  this.getDeliveryFn();
                });
                break;
            }
          }
        }
      });
    },
    /** 点击获取交付中心列表数据 **/
    clickCenterRow(e) {
      this.DeliveryRowData = e;
      this.getDeliveryFn();
    },
    /** 获取交付中心数据 **/
    getDeliveryFn() {
      let obj = {
        current: this.currentDeliveryPage,
        size: this.pageDeliverySize,
        groupId: this.DeliveryRowData.id,
      };
      this.deliveryCenterData = [];
      getCenterList(obj).then(res => {
        this.deliveryCenterData = res.records;
        this.deliveryCenterTotal = res.total;
      });
      listAllCompany({}).then((res) => {
        this.companyList = res;
      });
    },
    /** 交付中心操作 **/
    deliveryCenterOperate(type, row) {
      this.dialogType = 1;
      this.deliveryOperateType = type;
      switch (type) {
        case 0: // 新增
          this.dialogTitle = "新增交付中心";
          this.dialogVisible = true;
          this.$set(this.dialogForm, "companyId", "");
          break;
        case 1: // 编辑
          this.dialogTitle = "编辑交付中心";
          this.dialogVisible = true;
          this.$set(this.dialogForm, "id", row.id);
          this.$set(this.dialogForm, "companyId", Number(row.companyId));
          break;
        case 2: // 删除
          let obj = {
            id: row.id,
          };
          getCenterDel(obj).then(res => {
            this.$message.success("删除成功！");
            this.getDeliveryFn();
          });
          break;
      }
    },
    /** 交付中心页码切换 **/
    changeDeliveryCenter(e) {
      this.pageDeliverySize = e.size;
      this.currentDeliveryPage = e.current;
      this.getGroupFn();
    },
    /** 搜索 **/
    onSubmit() {
      this.pageSize = 20;
      this.currentPage = 1;
      let obj = {
        ...this.searchForm,
        createdBeginDateTime: this.searchForm?.createdTime?.length > 0 ? `${this.searchForm?.createdTime[0]} 00:00:00` : "", // 建单开始时间
        createdEndDateTime: this.searchForm?.createdTime?.length > 0 ? `${this.searchForm?.createdTime[1]} 23:59:59` : "", // 建单结束时间
      }
      this.form = obj;
      this.getListData();
    },
    /** 切换一页显示多少数据 **/
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.pageOption.size = newSize;
      this.getListData();
    },
    /** 切换页数 **/
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getListData();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .table-wrap {
    padding: 14px;

    .search-form {
      :deep .el-input__inner {
        width: 225px !important;
        height: 32px !important;
        line-height: 32px !important;
      }

      :deep .el-date-editor--daterange {
        width: 225px !important;
      }
    }

    .settings-wrap {
      .table_top {
        height: 50px;
        background: #f5f7fa;

        span {
          line-height: 50px;
          text-indent: 1em;
          font-size: 14px;
          color: #242526;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
        }

        p {
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
          border: 1px solid #33ab79;
          color: #33ab79;
          font-size: 12px;
          padding: 0px 15px;
          margin: 10px 12px 0 0;
          cursor: pointer;
        }
      }
    }
  }

  .pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .total-text {
      font-size: 14px;
      color: #444;
    }
  }


}
  :deep .dialog-select{
    width: 100% !important;
  }
</style>
