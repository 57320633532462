let tableDom;
let contentTableFatherDom;
let contentTableDom;
let scrollBar;

let handleBarScroll = (e) => {
  contentTableFatherDom.scrollTo(
    e.target.scrollLeft,
    contentTableFatherDom.scrollTop
  );
};

let handleTableScroll = (e) => {
  scrollBar?.removeEventListener('scroll', handleBarScroll);
  scrollBar?.scrollTo(e.target.scrollLeft, 0);
  scrollBar?.addEventListener('scroll', handleBarScroll);
};

let handleWidthResize = (e) => {
  scrollBar.children[0].style.width =
    contentTableFatherDom.children?.[0].clientWidth + 'px';

  scrollBar.style.width = tableDom.clientWidth + 'px';
  scrollBar.style.opacity =
    contentTableDom.clientWidth > tableDom.clientWidth ? 1 : 0;
};

// 指令所在组件的 VNode 及其子 VNode 全部更新后调用。
function componentUpdated(el, binding, vnode, oldVnode) {
  // console.log(el);
  tableDom = el;
  // 1.获取表格内容宽度
  contentTableFatherDom = el.getElementsByClassName(
    'el-table__body-wrapper'
  )[0];
  contentTableDom = contentTableFatherDom.children?.[0];
  // console.log(contentTableDom);
  let tableWidth = contentTableDom?.clientWidth;

  // 2.如果已存在自定义滚动条，则修改宽度 否则，生成滚动条元素
  let baseContainer = binding.value.$el;
  let left = getComputedStyle(baseContainer.children[1]).paddingLeft;
  let bars = baseContainer.getElementsByClassName('fixed-scroll-bar');
  if (bars?.length) {
    bars[0].style.width = tableWidth;
    return;
  }
  var objE = document.createElement('div');
  // calc(100% - 67px)

  let marginTop = '-7px';
  if (window.navigator.userAgent.toLowerCase().includes('firefox')) {
    marginTop = '-18px';
  }
  // if (window.navigator.userAgent.toLowerCase().includes('mac')) {
  //   marginTop = '-14px';
  // }
  objE.innerHTML = `<div
        class="fixed-scroll-bar"
        style="
          margin-left: ${left};
          width: ${tableDom.clientWidth}px;
          overflow-x: auto;
          z-index: 2;
          margin-top: ${marginTop};
          border-bottom: 1px solid #d0d3d9;
          border-left: 1px solid #d0d3d9;
          background: #fff;
        "
    >
      <div style="width: ${tableWidth}px; height: 1px;"></div>
    </div>`;

  scrollBar = objE.children[0];

  // 3.把自定义滚动条插入到base-container组件footer插槽前
  // 如果已有自定义滚动条
  let baseContainerFooter = binding.value.$el.children[2];

  baseContainerFooter.insertAdjacentElement('beforebegin', scrollBar);

  // 4.绑定滚动事件，同步更新表格滚动位置
  scrollBar.addEventListener('scroll', handleBarScroll);
  contentTableFatherDom.addEventListener('scroll', handleTableScroll);
  window.addEventListener('resize', handleWidthResize);
  setTimeout(handleWidthResize, 0);
}
// 只调用一次，指令与元素解绑时调用。
function unbind(el, binding, vnode, oldVnode) {
  // 1.解除绑定事件
  scrollBar?.removeEventListener('scroll', handleBarScroll);
  scrollBar?.remove();
  contentTableFatherDom?.removeEventListener('scroll', handleTableScroll);
  window?.removeEventListener('resize', handleWidthResize);
}

const directiveOptions = {
  componentUpdated,
  unbind,
};

export default directiveOptions;
