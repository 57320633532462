<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="客户信息">
              <el-input
                v-model="form.clientMsg"
                placeholder="请输入姓名/手机/车牌"
                clearable
                @keyup.enter.native="getDataList"
              ></el-input>
            </el-form-item>
            <el-form-item label="会员类型">
              <el-select v-model="form.vipType" placeholder="请选择" clearable>
                <el-option
                  v-for="item in vipType"
                  :key="item.index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div class="flex flex-x-center">
                  建档门店
                  <el-tooltip
                    effect="light"
                    content="客户档案的建档门店,可查连锁范围内全部门店"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <el-select
                class="span6_input"
                v-model="form.filingStoreCompanyId"
                filterable
                clearable
                remote
                placeholder="搜索车店名称、编号"
                :remote-method="searchStore"
                :loading="searchStoreLoading"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                  <span style="float: left; color: #8492a6; font-size: 13px">{{
                    item.companyId
                  }}</span>
                  <span style="float: right">{{ item.companyName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: true,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getDataList()"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>

        <template v-if="showMore">
          <div class="flex">
            <el-form-item label="客户等级">
              <el-select
                v-model="form.clientLevel"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in clientLevel"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="流失客户">
              <el-select
                v-model="form.clientState"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in clientState"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="双系客户">
              <el-select
                v-model="form.isDualSystem"
                placeholder="请选择"
                clearable
              >
                <el-option :value="true" label="是">是</el-option>
                <el-option :value="false" label="否">否</el-option>
              </el-select>
            </el-form-item>
          </div>
        </template>
      </el-form>
    </template>

    <template>
      <div style="margin-bottom: 15px" class="btns flex-x-between">
        <el-button
          v-auth="61"
          class="btn_insert"
          type="primary"
          size="small"
          @click="addCuster"
          >新增客户</el-button
        >
        <el-button
          v-auth="282"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <el-table
        class="custom-table"
        :data="tableData"
        border
        @row-dblclick="goDetail"
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" width="60" label="序号">
        </el-table-column>
        <el-table-column prop="date" label="操作" width="80">
          <template slot-scope="scope">
            <span
              v-auth="62"
              class="pointer text_operation blue"
              @click="getUpdate(scope.$index, scope.row)"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="clientId" label="客户ID" width="100">
        </el-table-column>
        <el-table-column prop="name" label="客户名称" width="100">
          <template slot-scope="scope">
            <span
              class="pointer text_operation blue"
              @click="goDetail(scope.row)"
              >{{ scope.row.name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="vipType" label="会员类型" width="120">
        </el-table-column>
        <el-table-column label="客户等级" width="120">
          <template #default="{ row }">
            {{ row.clientLevel == '无' ? '无等级' : row.clientLevel }}
          </template>
        </el-table-column>
        <!-- <el-table-column
            prop="clientSourceMsg"
            label="客户来源类型/来源客户"
            min-width="180"
          >
          </el-table-column> -->
        <el-table-column
          prop="clientLicensePlateNum"
          label="名下车辆"
          width="180"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              class="pointer text_operation primary"
              @click="goCarList(scope.row)"
              >{{ scope.row.clientLicensePlateNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="clientNotIntoTime"
          label="未进店天数"
          min-width="140"
        >
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span class="pointer text_operation">{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="clientCreateTime" label="建档日期" width="180">
        </el-table-column>
        <el-table-column prop="clientCreatePerpon" label="建档人" width="140">
        </el-table-column>
        <el-table-column prop="companyName" label="建档门店"> </el-table-column>
      </el-table>
      <add-customer-dialog
        v-if="showAddCusterDialog"
        :showAddCusterDialog.sync="showAddCusterDialog"
        :id="editId"
        @update="getDataList"
      ></add-customer-dialog>
      <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
import pagination from '@/components/Pagination.vue';
import addCustomerDialog from './addCustomer.vue';
import ExportDialog from '@/components/ExportDialog';

import {
  getCustomerList,
  searchSourceClient,
  exportCusterListUrl,
} from '@/api/customer/customer';
import { downloadFile } from '@/utils/fileApi';
import { searchStore } from '@/api/customer/store';

import { mapState, mapGetters, mapMutations } from 'vuex';

// 工具
import Big from 'big.js';

export default {
  name: 'customerFiles',
  components: { pagination, addCustomerDialog, ExportDialog },
  data() {
    return {
      form: {},
      showAddCusterDialog: false,
      showExportDialog: false,

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      // 搜索门店
      storeList: [],
      searchStoreLoading: false,
      loading: false,

      //搜索客户
      customers: [],

      // 展示更多筛选
      showMore: false,

      editId: '',

      vipType: [
        {
          value: 1,
          label: '非会员',
        },
        {
          value: 2,
          label: '会员卡客户',
        },
        {
          value: 3,
          label: '协议客户',
        },
        {
          value: 4,
          label: '会员卡/协议客户',
        },
      ],
      clientLevel: [
        {
          value: 0,
          label: 'A',
        },
        {
          value: 1,
          label: ' B',
        },
        {
          value: 2,
          label: ' C',
        },
        {
          value: 3,
          label: '无等级',
        },
      ],
      isBindingWx: [
        {
          value: 0,
          label: '否',
        },
        {
          value: 1,
          label: '是',
        },
      ],
      clientState: [
        {
          value: 0,
          label: '30天内到店',
        },
        {
          value: 1,
          label: '30天未到店',
        },
        {
          value: 2,
          label: '90天未到店',
        },
        {
          value: 3,
          label: '180天未到店',
        },
      ],
      clientSource: [
        {
          value: 0,
          label: '分红渠道',
        },
        {
          value: 1,
          label: '分红股东',
        },
      ],

      //表格
      tableData: [
        {
          clientId: '',
          name: '',
          phone: '',
          clientTypeAndGender: '',
          vipType: '',
          clientLevel: '',
          clientSourceMsg: '',
          clientLicensePlateNum: '',
          clientNotIntoTime: '',
          remark: '',
          clientCreateTime: '',
          clientCreatePerpon: '',
          companyName: '',
        },
      ],
      dialogTableVisible: false,
      custlist: '',
      searchClientLoading: false,
      exportUrl: exportCusterListUrl,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.getDataList();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getDataList(false);
    },
    /**
     * 加载数据
     */
    getDataList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      this.form.companyId =
        this.form.operateCompanyId ||
        this.$store?.state?.user?.userInfo?.companyId;
      let data = Object.assign({}, this.form, this.pageOption);
      getCustomerList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        this.tableData = res.records;
      });
    },
    /**
     * 远程搜索门店列表
     */
    searchStore(keyWord) {
      if (keyWord) {
        this.searchStoreLoadings = true;
        searchStore(keyWord, this.companyId)
          .then((res) => {
            this.storeList = res;
          })
          .finally(() => {
            this.searchClientLoading = false;
          });
      }
    },
    /**
     * 跳转详情页
     */
    goDetail(row) {
      this.$router.push({
        name: 'customerDetails',
        query: { id: row.clientId },
      });
    },
    /**
     * 新增客户信息
     */
    addCuster() {
      this.editId = '';
      this.$nextTick(() => {
        this.showAddCusterDialog = true;
      });
    },
    /**
     * 跳转编辑客户信息
     */
    getUpdate(index, row) {
      this.editId = row.clientId;
      this.$nextTick(() => {
        // this.$refs.popWindow.dataInit(row);
        this.showAddCusterDialog = true;
      });
    },
    /**
     * 远程搜索客户列表
     */
    searchClient(keyWord) {
      if (keyWord) {
        this.searchClientLoading = true;
        searchSourceClient(keyWord)
          .then((res) => {
            this.custlist = res.records;
          })
          .finally(() => {
            this.searchClientLoading = false;
          });
      }
    },
    /**
     * 选择客户
     */
    selectClient(clientId) {
      if (clientId) {
        const client = this.custlist.find((item) => item.id === clientId);
        // this.form.clientId = client.id;
        // this.form.clientLevels = client.clientLevel;
        // this.form.clientNames = client.clientName;
        // this.form.clientPhones = client.clientPhone;
        // this.form.gender = client.gender;
        // this.form.clientSourceId = client.id;
        // this.form.isBindingWxs = client.isBindingWx;
      }
    },
    /**
     * 跳转名下车辆
     */
    goCarList(row) {
      this.$router.push({
        name: 'carList',
        params: {
          form: {
            clientMsg: row.name,
          },
        },
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.form-top {
  height: 28px;
}
.form-item__customlabel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > i:last-child {
    margin-left: 5px;
  }
}
.primary {
  color: $primary;
}
.btn_search {
  width: 76px;
}
</style>
