import { render, staticRenderFns } from "./pakeManufacturer.vue?vue&type=template&id=71f99822&scoped=true"
import script from "./pakeManufacturer.vue?vue&type=script&lang=js"
export * from "./pakeManufacturer.vue?vue&type=script&lang=js"
import style0 from "./pakeManufacturer.vue?vue&type=style&index=0&id=71f99822&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f99822",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\grow_task\\code\\multi_workspace_code\\geyue-back-end-management-web\\branch_prod-fix-bug\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71f99822')) {
      api.createRecord('71f99822', component.options)
    } else {
      api.reload('71f99822', component.options)
    }
    module.hot.accept("./pakeManufacturer.vue?vue&type=template&id=71f99822&scoped=true", function () {
      api.rerender('71f99822', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/systemManagement/pakeManufacturer.vue"
export default component.exports