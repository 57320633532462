<template>
  <base-container paddingTop>
    <template>
      <el-form
        class="filter-form"
        style="display: block"
        :model="editForm"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="开启服务" prop="enabledStatus">
              <el-switch
                v-model="editForm.enabledStatus"
                :active-value="1"
                placeholder="选择开启服务"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="serviceType !== 3">
          <el-col :span="24">
            <el-form-item label="推荐车型" prop="smCloudCarSeriesAllId">
              <el-select
                v-model="editForm.smCloudCarSeriesAllId"
                filterable
                remote
                clearable
                placeholder="搜索车型"
                :remote-method="searchCarModel"
                :loading="searchModelLoading"
                @clear="clearCarModel"
              >
                <el-option
                  v-for="(item, index) in carModelData"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="门店地址" prop="companyAddress">
              {{ companyAddress }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="联系电话" prop="customerService">
              <el-input
                placeholder="请输入联系电话"
                v-model="editForm.customerService"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24"> 设置每日可预约总数： </el-col>
        </el-row>
      </el-form>
      <div class="box">
        <div class="calendar">
          <miniProgramBookingConfigCalendar
            ref="refBookingCalendarComponents"
            class="customCalendar"
            @changeTime="changeNewCalendarDate"
            @startDataHandle="changeNewCalendarDate"
            @goNowTime="changeNewCalendarDate"
          ></miniProgramBookingConfigCalendar>
        </div>
        <div class="calendar">
          <miniProgramBookingConfigCalendarTimePhase
            ref="refTimePhaseComponents"
            :defaultMaxBookingNum="30"
            class="customCalendar"
          ></miniProgramBookingConfigCalendarTimePhase>
        </div>
        <div style="margin: 16px 24px">
          <div>
            <el-button
              class="btn_search"
              type="primary"
              style="width: 106px"
              size="small"
              @click="resetMaxBookingNum"
              >重置预约数</el-button
            >
          </div>
          <div style="margin-top: 20px">
            <el-button
              class="btn_search"
              style="width: 106px"
              type="primary"
              size="small"
              v-auth="691"
              @click="saveCurrentBookingDateTimePhase"
              >保存当日</el-button
            >
          </div>
          <div style="margin-top: 20px">
            <el-checkbox
              v-model="markTemplateConfig"
              @change="markTemplateConfigChange"
              >设为模板</el-checkbox
            >
            <el-tooltip
              effect="light"
              content="数量按照当前日期时间段配置进行每天数量"
              placement="bottom-start"
            >
              <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
            </el-tooltip>
          </div>
          <div style="margin-top: 20px" v-if="lastTemplateDate !== ''">
            最近模板日期 {{ lastTemplateDate }}
          </div>
        </div>
      </div>
      <div style="text-align: right">
        <el-button
          class="btn_search"
          type="primary"
          size="small"
          v-auth="690"
          @click="submitCompanyConfig"
          >提交</el-button
        >
      </div>
    </template>
  </base-container>
</template>

<script>
import miniProgramBookingConfigCalendar from './miniProgramBookingConfigCalendar.vue';
import miniProgramBookingConfigCalendarTimePhase from './miniProgramBookingConfigCalendarTimePhase.vue';
//接口
import {
  availableDateTimePhaseChange,
  getAvailableTimePhase,
  saveOrUpdate,
  info,
  carModelList,
} from '@/api/carBill/miniProgramBooking';
import { hasAuth } from '@/utils/permissions';
import { mapState } from 'vuex';
export default {
  name: 'miniProgramBookingServiceConfig',
  components: {
    miniProgramBookingConfigCalendar,
    miniProgramBookingConfigCalendarTimePhase,
  },
  props: {
    serviceType: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      searchModelLoading: false,
      showCompleteBookingDialog: false,
      completeBookingId: 0,
      showMore: false,
      bookingDate: '',
      timePhaseItems: {},
      carModelData: [],
      // 默认预约数
      defaultMaxBookingNum: 0,
      id: undefined,
      companyConfigInfo: undefined,
      todayAvailableRespDto: undefined,
      carModelSearchTimer: undefined,
      editForm: {
        id: 0,
        companyId: 0,
        serviceType: 0,
        enabledStatus: 0,
        smCloudCarSeriesAllId: '',
        customerService: '',
        isSaveDirect: false,
        remark: '',
        companyAddress: '',
      },
      companyAddress: '',
      // 是否标记为模板
      markTemplateConfig: false,
      // 当前日期的最近模板日
      lastTemplateDate: '',
      // 当前选择的日期
      currentDate: '',
      // 现在有无配置为模板
      templateConfigData: {
        hasSet: false,
        templateDate: '',
      },
      rules: {
        enabledStatus: [
          { required: true, message: '选择开启服务', trigger: 'blur' },
        ],
        companyAddress: [
          { required: true, message: '请输入门店地址', trigger: 'blur' },
        ],
        compancustomerServiceyAddress: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.companyAddress = (
        JSON.parse(localStorage.getItem('company')) || {}
      )?.address;
      this.editForm.companyAddress = this.companyAddress;
      this.getDataInfo();
    },
    clearCarModel() {
      this.editForm.smCloudCarSeriesAllId = '';
    },
    searchCarModel(name) {
      const _this = this;
      if (_this.carModelSearchTimer) {
        window.clearTimeout(this.carModelSearchTimer);
      }
      if (name === '') {
        _this.carModelData = [];
      } else {
        _this.carModelSearchTimer = setTimeout(() => {
          carModelList({ name: name }).then((res) => {
            _this.carModelData = res;
          });
        }, 500);
      }
    },
    /**
     * 点击变更复选框的时候
     * @param newValue 新值 true 或者 false
     */
    markTemplateConfigChange(newValue) {
      if (newValue === true) {
        this.templateConfigData.hasSet = true;
        this.templateConfigData.templateDate = this.currentDate;
      } else {
        this.templateConfigData.hasSet = false;
        this.templateConfigData.templateDate = '';
      }
    },
    getCurrentTemplateConfigData() {
      if (
        this.templateConfigData.hasSet &&
        this.templateConfigData.templateDate !== ''
      ) {
        const tempDate = this.templateConfigData.templateDate;
        let _timePhaseItems = this.timePhaseItems[tempDate];
        if (_timePhaseItems !== undefined) {
          const thresholdItems = [];
          for (let i = 0; i < _timePhaseItems.items.length; i++) {
            const elem = _timePhaseItems.items[i];
            if (elem.disable === true) {
              continue;
            }
            thresholdItems.push({
              id: null,
              bookingDate: elem.bookingDate,
              timePhase: elem.bookingTimePhase,
              maxBookingNum: elem.maxBookingNum,
            });
          }
          return thresholdItems;
        }
      }
      return [];
    },
    getDataInfo() {
      const _this = this;
      const reqData = {
        serviceType: this.serviceType,
      };
      info(reqData).then((res) => {
        let { companyConfigEntity, todayAvailableRespDto, carModelItem } = res;
        _this.companyConfigInfo = companyConfigEntity || {};
        _this.todayAvailableRespDto = todayAvailableRespDto || {};
        if (carModelItem) {
          _this.carModelData = [carModelItem];
        }
        console.table(_this.carModelData);
        _this.id = _this.companyConfigInfo?.id;
        if (_this.companyConfigInfo !== undefined) {
          _this.editForm.companyId =
            _this.companyConfigInfo?.companyId || _this.companyId;
          _this.editForm.enabledStatus = _this.companyConfigInfo?.enabledStatus;
          if (_this.companyConfigInfo.smCloudCarSeriesAllId !== undefined) {
            _this.editForm.smCloudCarSeriesAllId =
              _this.companyConfigInfo.smCloudCarSeriesAllId + '';
          }
          _this.editForm.customerService =
            _this.companyConfigInfo?.customerService;
        }
        if (
          _this.todayAvailableRespDto !== undefined &&
          _this.todayAvailableRespDto.bookingDate
        ) {
          _this.bookingDate = _this.todayAvailableRespDto.bookingDate;
          _this.$refs.refTimePhaseComponents.handleChangeDate(
            _this.bookingDate,
            todayAvailableRespDto.items
          );
          this.currentDate = _this.bookingDate;
        }
        // 模板设置
        if (_this.todayAvailableRespDto?.lastTemplateDate !== undefined) {
          _this.lastTemplateDate =
            _this.todayAvailableRespDto?.lastTemplateDate || '';
          _this.templateConfigData.hasSet = true;
          _this.templateConfigData.hasSet = _this.lastTemplateDate;
        }
        // 当前时间是不是一个模板日期
        _this.markTemplateConfig = false;
        if (
          (_this.todayAvailableRespDto?.currentDateIsTemplateDate || false) ===
          true
        ) {
          _this.markTemplateConfig = true;
        }
      });
    },
    /**
     * 日历日期选择变更
     * @param {String} newDateTime 日期，格式：yyyy-MM-dd
     */
    changeNewCalendarDate(newDateTime) {
      const _this = this;
      // 切换日期，设置模板就不选择了，
      _this.markTemplateConfig = false;
      if (
        _this.templateConfigData.hasSet &&
        _this.templateConfigData.templateDate !== '' &&
        _this.templateConfigData.templateDate === newDateTime
      ) {
        _this.markTemplateConfig = true;
      }
      // 拿到时间段的数据
      const _timePhaseItems =
        this.$refs.refTimePhaseComponents.getUserInputData();
      // 存储
      this.timePhaseItems[_timePhaseItems.bookingDate] = _timePhaseItems;
      // 如果没有，则从服务器拿到远程配置的最新时间段数据
      const _newTimePhaseItems = this.timePhaseItems[newDateTime];
      if (_newTimePhaseItems !== undefined) {
        _this.$refs.refTimePhaseComponents.handleChangeDate(
          newDateTime,
          _newTimePhaseItems.items
        );
      } else {
        const reqData = {
          bookingDate: newDateTime,
          serviceType: _this.serviceType,
        };
        getAvailableTimePhase(reqData).then((res) => {
          let _lastTemplateDate = res.lastTemplateDate || '';
          // 处理下，如果不是系统的日期，并且小于本次配置新模板的日期的，调整items为模板配置
          let changeItems = res.items;
          if (
            _lastTemplateDate === '' &&
            _this.templateConfigData.hasSet &&
            _this.templateConfigData.templateDate !== ''
          ) {
            // 如果日期小于上次设置的日期，则显示
            if (newDateTime > _this.templateConfigData.templateDate) {
              _lastTemplateDate = _this.templateConfigData.templateDate;
              const templateConfigData = _this.getCurrentTemplateConfigData();
              if (templateConfigData.length > 0) {
                changeItems.forEach((item) => {
                  if (item.isSysDefault) {
                    templateConfigData.forEach((item1) => {
                      if (item1.timePhase === item.bookingTimePhase) {
                        item.maxBookingNum =
                          item1.maxBookingNum || item.maxBookingNum;
                        item.remainBookingNum = item.maxBookingNum;
                      }
                    });
                  }
                });
              }
            }
          }
          _this.lastTemplateDate = _lastTemplateDate;

          _this.$refs.refTimePhaseComponents.handleChangeDate(
            res.bookingDate,
            changeItems
          );
        });
      }
      this.bookingDate = newDateTime;
      this.currentDate = newDateTime;
    },
    /**
     * 重置预约数
     */
    resetMaxBookingNum() {
      this.$refs.refTimePhaseComponents.resetMaxBookingNum(
        this.defaultMaxBookingNum
      );
    },
    /**
     * 设置当日
     */
    saveCurrentBookingDateTimePhase() {
      const thresholdItems = [];
      const _timePhaseItems =
        this.$refs.refTimePhaseComponents.getUserInputData();
      for (let i = 0; i < _timePhaseItems.items.length; i++) {
        const elem = _timePhaseItems.items[i];
        if (elem.disable === true) {
          continue;
        }
        thresholdItems.push({
          id: null,
          bookingDate: elem.bookingDate,
          timePhase: elem.bookingTimePhase,
          maxBookingNum: elem.maxBookingNum,
        });
      }
      if (thresholdItems.length === 0) {
        this.$message.warning(
          '没有需要更新的预约时间段，请确认预约时间是否大于当前日期和当前时间段'
        );
        return;
      }
      const data = {
        id: this.id,
        companyId: this.companyId,
        serviceType: this.serviceType,
        enabledStatus: this.editForm.enabledStatus || 0,
        smCloudCarSeriesAllId: this.editForm.smCloudCarSeriesAllId,
        customerService: this.editForm.customerService,
        isSaveDirect: false,
        remark: '',
        thresholdItems: thresholdItems,
      };
      availableDateTimePhaseChange(data)
        .then((res) => {
          this.$message.info('保存成功');
        })
        .catch((err) => {
          this.$message.info(err.message || '保存失败');
        });
    },
    /**
     * 提交更新门店预约配置
     */
    submitCompanyConfig() {
      const thresholdItems = [];
      const bookingDateArray = [];
      let _timePhaseItems = {};
      // 当前日期的
      _timePhaseItems = this.$refs.refTimePhaseComponents.getUserInputData();
      bookingDateArray.push(_timePhaseItems.bookingDate);
      for (let i = 0; i < _timePhaseItems.items.length; i++) {
        const elem = _timePhaseItems.items[i];
        if (elem.disable === true) {
          continue;
        }
        thresholdItems.push({
          id: null,
          bookingDate: elem.bookingDate,
          timePhase: elem.bookingTimePhase,
          maxBookingNum: elem.maxBookingNum,
        });
      }
      // 之前配置的
      for (let key in this.timePhaseItems) {
        if (Object.prototype.hasOwnProperty.call(this.timePhaseItems, key)) {
          _timePhaseItems = this.timePhaseItems[key];
          if (bookingDateArray.includes(_timePhaseItems.bookingDate)) {
            // 已经获取过的了， 不重复获取
            continue;
          }
          bookingDateArray.push(_timePhaseItems.bookingDate);
          for (let i = 0; i < _timePhaseItems.items.length; i++) {
            const elem = _timePhaseItems.items[i];
            if (elem.disable === true) {
              continue;
            }
            thresholdItems.push({
              id: null,
              bookingDate: elem.bookingDate,
              timePhase: elem.bookingTimePhase,
              maxBookingNum: elem.maxBookingNum,
            });
          }
        }
      }
      // 模板处理
      let templateConfig = {};
      if (
        this.templateConfigData.hasSet &&
        this.templateConfigData.templateDate !== ''
      ) {
        const tempDate = this.templateConfigData.templateDate;
        const thresholdTemplateItems = [];
        thresholdItems.forEach((item) => {
          if (item.bookingDate === tempDate) {
            thresholdTemplateItems.push({
              bookingDate: item.bookingDate,
              timePhase: item.timePhase,
              maxBookingNum: item.maxBookingNum,
            });
          }
        });
        if (thresholdTemplateItems.length > 0) {
          templateConfig.thresholdTemplateItems = thresholdTemplateItems;
        }
      } else {
        templateConfig = null;
      }
      const data = {
        id: this.id,
        companyId: this.companyId,
        serviceType: this.serviceType,
        enabledStatus: this.editForm.enabledStatus,
        smCloudCarSeriesAllId: this.editForm.smCloudCarSeriesAllId,
        customerService: this.editForm.customerService,
        isSaveDirect: true,
        remark: '',
        thresholdItems: thresholdItems,
        templateConfig: templateConfig,
      };
      saveOrUpdate(data)
        .then((res) => {
          this.$message.info(res.message || '保存成功');
          this.id = res.data;
        })
        .catch((err) => {
          this.$message.info(err?.message || '保存失败');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  width: 100%;
  display: inline-flex;
}
.el-row {
  width: 100%;
}
.custom-tabs {
  ::v-deep .el-tabs__header {
    margin: 0px 0px 0px !important;
    border-bottom: none;
    .el-tabs__nav {
      // border-bottom: 1px solid #e4e7ed;
    }
    .el-tabs__item {
      height: auto;
      border-bottom: 1px solid #e4e7ed;
      &.is-active {
        background: rgba(51, 171, 121, 0.1);
      }
    }
  }
}

.box {
  width: 100%;
  height: calc(100% - 89px);
  display: flex;
  padding-top: 16px;
  box-sizing: border-box;
  // background-color: #f1f7fa;
  // height: 80%;
  .calendar {
    height: calc(100% + 32px);
    background-color: #ffffff;
    border-radius: 4px;
    .customCalendar {
      margin: 16px 24px;
    }
  }
  .table {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    background: #fff;
    margin-left: 16px;
    border-radius: 4px;
    position: relative;
    height: 100%;
    .right-content {
      flex: 1;
      // height: 100%;
      // margin-left: 16px;
      // padding: 8px;
      // background: #fff;
      // min-width: 0;
      padding-bottom: calc(4vh + 32px);
      overflow: auto;
    }
    .pagination-box {
      width: calc(100% - 48px);
      background: #fff;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      text-align: center;
      z-index: 2;
    }
  }
  ::v-deep .el-tabs__nav-wrap::after {
    position: static !important;
  }
}
.last_border_bottom {
  :last-child {
    border-bottom: 1px solid;
  }
}
</style>
