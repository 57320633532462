<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="商品品名">
              <el-input
                v-model="form.name"
                placeholder="请输入商品品名"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>

            <el-form-item label="商品分类">
              <select-goods-class-cascader
                ref="goodsClass"
                :value="[form.firstCategoryId, form.secondCategoryId]"
                @select="handleSelectGoodsClass"
                checkStrictly
              ></select-goods-class-cascader>
            </el-form-item>
            <el-form-item label="启用状态">
              <el-select
                v-model="form.isActive"
                placeholder="请选择启用状态"
                clearable
              >
                <el-option label="全部" value=""> </el-option>
                <el-option label="已启用" :value="true"> </el-option>
                <el-option label="未启用" :value="false"> </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="flex">
              <el-button
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div>
          <el-button v-auth="583" size="small" class="btn_insert" @click="add"
            >新增</el-button
          >
        </div>

        <!-- <el-button
          v-auth="283"
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button> -->
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <span
              class="text_operation blue"
              @click="handleEdit(scope.row)"
              v-auth="584"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="品名编码"
          prop="productCode"
        ></el-table-column>
        <el-table-column width="180" label="品名" prop="name"></el-table-column>
        <el-table-column
          width="140"
          label="所属大类"
          prop="firstCategoryName"
        ></el-table-column>
        <el-table-column
          width="140"
          label="所属细类"
          prop="secondCategoryName"
        ></el-table-column>
        <el-table-column label="俗称" prop="vulgo"></el-table-column>
        <el-table-column label="启用状态" width="100" prop="isActive">
          <template #default="{ row }">
            {{ row.isActive === 1 ? '启用' : '未启用' }}
          </template>
        </el-table-column>
        <el-table-column min-width="140" label="项目" prop="projContent">
          <template #default="{ row }">
            <el-tooltip :content="row.projContent">
              <div class="text-ellipsis-2-row">
                {{ row.projContent }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="creator"></el-table-column>
        <el-table-column width="200" label="创建时间" prop="dateCreated"></el-table-column>
        <el-table-column label="修改人" prop="updater"></el-table-column>
        <el-table-column width="200" label="修改时间" prop="lastUpdated"></el-table-column>
      </el-table>
      <edit-goods-alias-dialog
        v-if="showEditDialog"
        :visibility.sync="showEditDialog"
        :detail="editDetail"
        @save="getData"
      ></edit-goods-alias-dialog>
      <!-- <export-dialog
        :visibility.sync="showExportDialog"
        :total="total"
        :pageOption="pageOption"
        :filterForm="form"
        :url="exportUrl"
      ></export-dialog> -->
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import ExportDialog from '@/components/ExportDialog';
import editGoodsAliasDialog from './components/editGoodsAliasDialog';
import selectGoodsClassCascader from '@/components/businessComponent/selectGoodsClassCascader.vue';

// 接口
import { getProductNameList } from '@/api/stock/goodsSetting';

import { mapState } from 'vuex';

export default {
  name: 'goodsAliasManage',
  components: {
    editGoodsAliasDialog,
    selectGoodsClassCascader,
    pagination,
    // ExportDialog
  },
  data() {
    return {
      editDetail: {},

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      showEditDialog: false,
      // showExportDialog: false,
      // exportUrl: 'exportListUrl',

      form: {
        isActive: '',
      },

      // 搜索用户
      clientList: [],
      searchClientLoading: false,
      // 搜索门店
      storeList: [],
      searchStoreLoading: false,
      //搜索车型信息列表字段
      searchModelLoading: false,
      models: [],

      // 列表数据
      listData: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  activated() {},
  created() {
    this.getData();
  },
  methods: {
    /**
     * 选择商品分类
     */
    handleSelectGoodsClass(val) {
      this.form.firstCategoryId = val[0] || '';
      this.form.secondCategoryId = val[1] || '';
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }

      var data = Object.assign({}, this.form, this.pageOption);
      getProductNameList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },

    /**
     * 新增
     */
    add() {
      this.editDetail = {};
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 编辑
     */
    handleEdit(row) {
      this.editDetail = row;
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },

    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
      this.$refs.goodsClass.clear();
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
