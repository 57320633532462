<template>
  <base-container paddingTop>
    <el-tabs class="tabs" v-model="activeName">
      <el-tab-pane
        label="事故车进店日报(门店)"
        name="entryReportDaily"
        v-auth="762"
      >
        <wreckedCarEntryCountDailyStatList></wreckedCarEntryCountDailyStatList>
      </el-tab-pane>
      <el-tab-pane label="目标配置" name="targetSettings" v-auth="764">
        <wreckedCarEntryCountCompanySettings></wreckedCarEntryCountCompanySettings>
      </el-tab-pane>
    </el-tabs>
  </base-container>
</template>

<script>
import wreckedCarEntryCountDailyStatList from './wreckedCarEntryCountDailyStatList.vue';
import wreckedCarEntryCountCompanySettings from './wreckedCarEntryCountCompanySettings.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'wreckedCarEntryCountDailyStat',
  components: {
    wreckedCarEntryCountDailyStatList,
    wreckedCarEntryCountCompanySettings,
  },
  data() {
    return {
      activeName: 'entryReportDaily',
    };
  },
  created() {
    this.activeName = this.$route.params?.activeTabName || 'entryReportDaily';
  },
  computed: {
    ...mapGetters(['activeTagIndex']),
  },
  methods: {
    ...mapMutations({
      closeTag: 'DEL_TAG',
    }),
  },
};
</script>

<style lang="scss" scoped>
.el-descriptions ::v-deep .table-label {
  vertical-align: middle;
  color: #666;
  text-align: center;
  background: $descriptions-label-bg;
}
.rows-tip {
  color: #999;
  line-height: 18px;
}
.box {
  .title {
    margin: 24px 0 8px 0;
    color: #303133;
    font-size: 16px;
    font-weight: 600;
  }
}
.foot-btn {
  padding: 10px 20px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  .el-dropdown {
    margin-left: 24px;
  }
  .el-dropdown-item ::v-deep .el-dropdown-menu {
    padding: 0;
  }
}
.client-info {
  i {
    margin: 0 5px;
  }
  .icon_grade {
    color: $blue;
    position: absolute;
    left: -30px;
  }
  .icon_grade {
    font-size: 22px;
  }
}
.btn-dropdown:focus,
.btn-dropdown:hover {
  color: #606266;
  background: #f5f7fa;
  border-color: #f5f7fa;
}
.cell-left {
  width: 20%;
  min-height: 46px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-right: 1px solid #ebeef5;
  text-align: center;
  span {
    width: 100%;
  }
}
.cell-right {
  height: 100%;
  flex: 1;
  padding: 0 12px;
}
.tabs {
  margin-bottom: 30px;
}
.el-descriptions ::v-deep .el-descriptions-item__content {
  height: 46px;
  min-height: 46px;
}
</style>
