import { render, staticRenderFns } from "./editSubmitWorkRemarkDialog.vue?vue&type=template&id=13517548&scoped=true"
import script from "./editSubmitWorkRemarkDialog.vue?vue&type=script&lang=js"
export * from "./editSubmitWorkRemarkDialog.vue?vue&type=script&lang=js"
import style0 from "./editSubmitWorkRemarkDialog.vue?vue&type=style&index=0&id=13517548&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13517548",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\grow_task\\code\\multi_workspace_code\\geyue-back-end-management-web\\branch_prod-fix-bug\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13517548')) {
      api.createRecord('13517548', component.options)
    } else {
      api.reload('13517548', component.options)
    }
    module.hot.accept("./editSubmitWorkRemarkDialog.vue?vue&type=template&id=13517548&scoped=true", function () {
      api.rerender('13517548', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/financialManagement/transaction/submitwork/components/editSubmitWorkRemarkDialog.vue"
export default component.exports