<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="滞留类型" prop="strandedType">
              <el-select
                v-model="form.strandedType"
                disabled
                placeholder="请选择滞留类型"
              >
                <el-option
                  v-for="item in strandedTypeEnumList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="解决方案" prop="solutionType">
              <el-select
                v-model="form.solutionType"
                clearable
                filterable
                placeholder="请选择解决方案"
                @change="solutionTypeChange"
              >
                <el-option
                  v-for="item in solutionTypeEnumList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div></div>
            <div class="flex"></div>
          </div>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div>
          <el-button size="small" v-auth="769" @click="openImportDialog()">
            导入滞留单解决方案<i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
        <div>
          <el-button
            v-auth="649"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <span
              v-auth="768"
              class="text_operation blue"
              style="margin-right: 10px"
              @click="handleEdit(scope.row)"
              >编辑</span
            >
          </template>
        </el-table-column>
        <el-table-column width="200" label="操作门店" prop="companyName">
        </el-table-column>
        <el-table-column width="150" label="车牌号" prop="carNumber">
        </el-table-column>
        <el-table-column width="100" label="客户" prop="clientName">
        </el-table-column>
        <el-table-column width="100" label="送修人" prop="customerName">
        </el-table-column>
        <el-table-column width="200" label="车单单号" prop="billCode">
          <template #default="{ row }">
            <gotoCarDetail
              :billCode="row.billCode"
              :billId="row.billId"
            ></gotoCarDetail>
          </template>
        </el-table-column>
        <el-table-column width="140" label="单据类型" prop="billTypeDesc">
        </el-table-column>
        <el-table-column width="200" label="业务来源" prop="billSourceTypeName">
        </el-table-column>
        <el-table-column width="140" label="施工类型" prop="difficultyDesc">
        </el-table-column>
        <el-table-column width="200" label="进店时间" prop="dateCreated">
        </el-table-column>
        <el-table-column width="100" label="进店里程" prop="mileage">
        </el-table-column>
        <el-table-column width="140" label="单据金额" prop="paid">
        </el-table-column>
        <el-table-column width="100" label="单据状态" prop="billStateDesc">
        </el-table-column>
        <el-table-column width="100" label="施工状态" prop="dispatchStateDesc">
        </el-table-column>
        <el-table-column
          width="200"
          label="完工时间"
          prop="dispatchCompletionTime"
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="滞留原因"
          prop="strandedReason"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="卡点责任人"
          prop="stuckPointResponsibleUserName"
        >
        </el-table-column>
        <el-table-column
          width="100"
          label="清单责任人"
          prop="detailListResponsibleUserName"
        >
        </el-table-column>
        <el-table-column
          width="200"
          label="完成时间"
          prop="strandedCompletionTime"
        >
        </el-table-column>
        <el-table-column width="150" label="解决方案" prop="solutionTypeDesc">
        </el-table-column>
      </el-table>
      <editCarStrandedDailyStatDetailDialog
        v-if="showEditDialog"
        :visibility.sync="showEditDialog"
        :id="editId"
        :rowEntityData="rowEntityData"
        :solutionTypeEnumList="solutionTypeEnumList"
        @save="getData(false)"
      ></editCarStrandedDailyStatDetailDialog>

      <!-- 导入弹窗 -->
      <import-dialog
        v-if="importDialogVisibility"
        :visibility.sync="importDialogVisibility"
        :showTip="false"
        title="滞留单据解决方案批量导入"
        templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/%E6%BB%9E%E7%95%99%E5%8D%95%E6%8D%AE%E6%97%A5%E6%8A%A5%E6%98%8E%E7%BB%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_20241027.xlsx"
        :uploadFileApi="importApi"
        :getLastResultApi="importResultApi"
        :importFailDetail="importMessageExportUrlApi"
      ></import-dialog>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from '@/components/Pagination.vue';
import gotoCarDetail from '@/components/businessComponent/gotoCarDetail.vue';
import importDialog from '@/components/businessComponent/importDialog.vue';
import editCarStrandedDailyStatDetailDialog from './components/editCarStrandedDailyStatDetailDialog.vue';

// 接口
import {
  carStrandedCountDailyPreDetailList,
  carStrandedCountDailyDetailList,
  carStrandedCountDailyDetailListExportUrl,
  saveOrUpdateCarStrandedSolution,
  carStrandedDetailBatchImport,
  carStrandedDetailBatchImportResult,
  carStrandedDetailBatchImportFailResultExportUrl,
} from '@/api/carBill/carWreckedAndStranded';
import { hasAuth } from '@/utils/permissions';
import { downloadFile } from '@/utils/fileApi';

import { mapState } from 'vuex';

export default {
  name: 'carStrandedDailyStatDetailList',
  components: {
    pagination,
    importDialog,
    editCarStrandedDailyStatDetailDialog,
    gotoCarDetail,
  },
  data() {
    return {
      importApi: carStrandedDetailBatchImport,
      importMessageExportUrlApi:
        carStrandedDetailBatchImportFailResultExportUrl,
      importResultApi: carStrandedDetailBatchImportResult,
      exportUrl: carStrandedCountDailyDetailListExportUrl,

      showEditDialog: false,
      editId: '',
      rowEntityData: {},

      total: 0,
      listData: [],
      pageOption: {
        size: 10,
        current: 1,
      },

      form: {},
      // 展示更多筛选
      showMore: true,
      strandedTypeEnumList: [],
      solutionTypeEnumList: [],
      // 导入弹窗可见性
      importDialogVisibility: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
    // 拉取用户的门店列表，以便给用户新增、编辑使用
    this.preLoadSaveOrUpdateDropListData();
    this.getData();
  },
  methods: {
    init() {
      this.strandedTypeEnumList.push({
        value: '',
        desc: '全部',
      });
      this.solutionTypeEnumList.push({
        value: '',
        desc: '全部',
      });
      if (this.$route.params.filterForm) {
        this.form = this.$route.params.filterForm;
      } else {
        return;
      }
      this.preLoadSaveOrUpdateDropListData();
      this.getData(true);
    },
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    /**
     * 预先加载新增/编辑的下拉框数据
     */
    preLoadSaveOrUpdateDropListData() {
      if (!hasAuth(766)) {
        return;
      }
      this.getInsuranceCompanyListAndInvoicingEntities();
    },
    /**
     * 查询体系内的门店列表
     */
    async getInsuranceCompanyListAndInvoicingEntities() {
      const _this = this;
      carStrandedCountDailyPreDetailList().then((res) => {
        const { strandedTypeEnumList, solutionTypeEnumList } = res;
        _this.strandedTypeEnumList = strandedTypeEnumList;
        _this.solutionTypeEnumList = solutionTypeEnumList;
      });
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(766);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {};

      var data = Object.assign({}, this.form, this.pageOption, times);

      carStrandedCountDailyDetailList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    openImportDialog() {
      if (!hasAuth(663)) {
        this.$message.warning('没有权限');
      }
      this.importDialogVisibility = true;
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign({}, this.form, {}),
      });
    },
    /**
     * 编辑
     */
    handleEdit(row) {
      this.editId = row.billId;
      this.rowEntityData = row;
      this.$nextTick().then(() => {
        this.showEditDialog = true;
      });
    },
    solutionTypeChange(newVal) {
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
