<template>
  <el-popover
    placement="bottom-end"
    trigger="manual"
    v-model="visible"
    popper-class="popper-class"
  >
    <el-table
      class="bill-table"
      :max-height="400"
      border
      v-loading="isLoading"
      :data="listData"
      @row-click="handleClick"
    >
      <el-table-column type="index" width="60" label="序号"> </el-table-column>
      <el-table-column
        width="120"
        label="车牌"
        prop="carNumber"
      ></el-table-column>
      <el-table-column
        width="170"
        label="单据号"
        prop="billCode"
      ></el-table-column>
      <el-table-column width="60" label="类型">
        <template #default="{ row }">
          {{ row.billType | dict('carBillType') }}
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        label="业务来源"
        prop="source"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column width="175" label="车架号" prop="uniqueId">
      </el-table-column>
      <el-table-column width="100" label="车单状态">
        <template #default="{ row }">
          {{ row.billState | dict('carBillState') }}
        </template>
      </el-table-column>
      <el-table-column width="160" label="进店时间" prop="dateCreated">
      </el-table-column>
      <el-table-column width="80" label="接车员" prop="creator.userName">
      </el-table-column>
      <el-table-column
        min-width="140"
        label="操作门店"
        prop="operateCompanyName"
      >
      </el-table-column>
      <!-- show-overflow-tooltip -->
      <template slot="append">
        <div v-if="hasMore" class="load-more-btn" @click="getData(false, true)">
          加载更多数据
        </div>
      </template>
    </el-table>
    <template slot="reference">
      <el-input
        style="width: 160px"
        ref="keyWordInput"
        v-model="keyWord"
        size="small"
        placeholder="搜索车牌"
        @keyup.native.enter="getData(true)"
        @focus="listData.length && (visible = true)"
        @clear="handleClear"
        clearable
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search pointer"
          @click="getData(true)"
        ></i>
      </el-input>
    </template>
  </el-popover>
</template>

<script>
import lodash from 'lodash';
import { mapState } from 'vuex';
import { searchByCarNumber } from '@/api/financial/pickSingle';
export default {
  name: 'searchCarBillByCarNumberPopover',
  props: {
    billType: {
      type: [Number, String],
    },
    searchKeyWord: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // 可见性
      visible: false,

      // 列表数据
      listData: [],

      // 搜索关键字
      keyWord: '',

      // 分页所在页数
      pageCurrent: 0,

      // 是否未加载完所有数据
      hasMore: false,

      // 是否正在加载
      isLoading: false,

      // 是否首次加载
      isFirstLoad: true,
    };
  },
  watch: {
    searchKeyWord(val) {
      console.log(val);
      this.keyWord = val;
    },
    permissionChangeCount() {
      this.visible = false;
      this.listData = [];
      this.keyWord = '';
      this.pageCurrent = 0;
      this.hasMore = false;
      this.isLoading = false;
      this.isFirstLoad = true;
    },
  },
  computed: {
    ...mapState({
      permissionChangeCount: (state) => state.user.permissionChangeCount,
    }),
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
  },
  created() {
    this.keyWord = this.searchKeyWord;
    // this.getData();
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  destroyed() {
    document?.removeEventListener('click', this.handleDocumentClick);
  },
  methods: {
    /**
     * 输入框失焦事件
     */
    inputBlur() {},
    /**
     * 加载数据
     */
    getData(reset, loadMore) {
      this.debounceGetData(reset, loadMore);
      // if (!this.isFirstLoad)
      this.isLoading = true;
      this.visible = true;
    },
    debounceGetData: lodash.debounce(function (reset, loadMore) {
      if (loadMore) this.current++;
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;
        // fcd 滚动到头部
        // this.hasMore = true;
      } else {
        this.pageCurrent++;
      }
      searchByCarNumber(
        { carNumber: this.keyWord, ...this.pagination },
        { noShowWaiting: true }
      )
        .then((res) => {
          if (reset) this.listData = [];
          console.log(res);
          this.listData.push(...res.records);
          this.hasMore = this.pageCurrent < res.pages;
        })
        .finally(() => {
          this.isLoading = false;
          this.isFirstLoad = false;
          // if (loadMore) this.$refs.keyWordInput.focus();
        });
    }, 3e2),
    /**
     * 选中
     */
    handleClick(val) {
      console.log('val', val);
      this.keyWord = '';
      this.visible = false;
      this.$emit('select', val);
    },
    /**
     * 清空
     */
    handleClear() {
      this.visible = false;
      this.listData = [];
      this.$emit('clear');
    },
    /**
     * 清空搜索关键字
     */
    clearKeyword() {
      this.keyWord = '';
    },
    handleDocumentClick(e) {
      if (!this.$el || this.$el.contains(e.target) || !e.target.className) {
        return;
      }
      this.visible = false;
    },
  },
};
</script>

<style>
.popper-class {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.bill-table {
  // width: 1000px;
  max-width: 80vw;
  ::v-deep .el-table__body-wrapper {
    overflow: auto;
  }
}

.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}
</style>
