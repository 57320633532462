<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form
        class="filter-form"
        :model="form"
        label-width="100px"
        size="small"
      >
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item>
              <template slot="label">
                <div>
                  车单门店
                  <el-tooltip
                    effect="light"
                    content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                    placement="bottom-start"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip>
                </div>
              </template>
              <operateCompanyCascader
                v-model="form.operateCompanyIds"
              ></operateCompanyCascader>
            </el-form-item>
            <el-form-item label="车单号">
              <el-input
                v-model="form.billCode"
                placeholder="请输入车单号"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input
                v-model="form.goodsName"
                placeholder="请输入商品名称"
                clearable
                @keyup.enter.native="getData"
              ></el-input>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div
              @click="showMore = !showMore"
              class="el-form-item__label pointer"
            >
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"
              ></i>
            </div>
            <div class="flex">
              <el-button
                v-auth="758"
                class="btn_search"
                type="primary"
                size="small"
                @click="getData"
                >查询</el-button
              >
              <el-button class="btn_search" size="small" @click="empty"
                >置空</el-button
              >
            </div>
          </div>
        </div>
        <div class="flex-warp" v-show="showMore">
          <template></template>
        </div>
      </el-form>
    </template>

    <template>
      <div style="margin: 30px 0 14px 0" class="btns flex-x-between">
        <div></div>
        <div>
          <el-button
            v-auth="759"
            size="small"
            type="text"
            class="blue"
            @click="downloadAllRecords()"
            >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
          ></el-button>
        </div>
      </div>

      <el-table
        class="custom-table"
        :data="listData"
        border
        v-fixedScrollBar="$refs.baseContainer"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column width="220" label="门店" prop="companyName">
        </el-table-column>
        <el-table-column width="200" label="车单号" prop="billCode">
          <template #default="{ row }">
            <gotoCarDetail
              :billCode="row.billCode"
              :billId="row.billId"
            ></gotoCarDetail>
          </template>
        </el-table-column>
        <el-table-column width="260" label="商品名称" prop="goodsName">
        </el-table-column>
        <el-table-column width="130" label="车单报价数量" prop="goodsNum">
        </el-table-column>
        <el-table-column width="130" label="领料单报价数量" prop="num">
        </el-table-column>
        <el-table-column width="130" label="领料数量" prop="pickedNum">
        </el-table-column>
      </el-table>
    </template>

    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import operateCompanyCascader from '@/components/businessComponent/operateCompanyCascader';
import pagination from '@/components/Pagination.vue';
import gotoCarDetail from '@/components/businessComponent/gotoCarDetail.vue';
// 接口
import { downloadFile } from '@/utils/fileApi';
import {
  waitReturnInInventoryList,
  waitReturnInInventoryListExportUrl,
} from '@/api/stock/materialbill';
import { hasAuth } from '@/utils/permissions';
import { mapState } from 'vuex';

export default {
  name: 'waitReturnInInventory',
  components: {
    operateCompanyCascader,
    pagination,
    gotoCarDetail,
  },
  data() {
    return {
      total: 0,
      listData: [],
      pageOption: {
        size: 10,
        current: 1,
      },
      exportUrl: waitReturnInInventoryListExportUrl,
      form: {},
      // 展示更多筛选
      showMore: true,
    };
  },

  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  activated() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.empty();
      this.getData();
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      var res = hasAuth(758);
      if (!res) {
        return;
      }
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var data = this.buildRequestBodyData();
      waitReturnInInventoryList(data).then((res) => {
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.listData = res.records;
      });
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign({}, this.buildRequestBodyData()),
      });
    },
    buildRequestBodyData() {
      var times = {};
      var data = Object.assign({}, this.form, this.pageOption, times);
      return data;
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form.operateCompanyIds = [this.companyId];
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}
</style>
