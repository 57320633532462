<template>
  <el-popover
    placement="bottom"
    trigger="manual"
    v-model="visible"
    popper-class="popper-class"
  >
    <el-table
      class="car-table"
      :max-height="400"
      border
      :data="listData"
      @row-click="handleClick"
    >
      <el-table-column width="100 " label="车辆" prop="carNumber">
        <template #default="{ row }">
          <span>{{ row.carNumber }}</span>
          <span
            v-if="
              row.billClientMemberMessageVo != undefined &&
              row.billClientMemberMessageVo.isAgreementClient != undefined &&
              row.billClientMemberMessageVo.state != undefined &&
              row.billClientMemberMessageVo.isAgreementClient == 1 &&
              row.billClientMemberMessageVo.state == 1
            "
            style="font-size: 15px; color: #e84931"
            >(包干车辆)</span
          >
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        width="170"
        label="车型"
        prop="brandName"
      >
      </el-table-column>
      <el-table-column show-overflow-tooltip width="180" label="客户">
        <template #default="{ row }">
          <span>{{ row.clientName }}</span>
        </template>
      </el-table-column>
      <el-table-column width="140" label="协议客户">
        <template #default="{ row }">
          {{
            row.billClientMemberMessageVo?.agreementClientId
              ? row.billClientMemberMessageVo?.isAgreementClient
                ? '协议客户 - 包干'
                : '协议客户'
              : ''
          }}
        </template>
      </el-table-column>
      <template slot="append">
        <div v-if="hasMore" class="load-more-btn" @click="getData(false, true)">
          加载更多数据
        </div>
      </template>
    </el-table>
    <template slot="reference">
      <el-input
        style="width: 260px"
        ref="keyWordInput"
        v-model="keyWord"
        size="small"
        placeholder="搜索车牌号、车主姓名"
        @input="getData(true)"
        @focus="visible = true"
        @clear="handleClear"
        clearable
      ></el-input>
    </template>
  </el-popover>
</template>

<script>
import { searchCarForCreateCarBill } from '@/api/carBill';
import lodash from 'lodash';
import { levelIcons } from '@/const/client';
export default {
  name: 'selectCarForCreateCarBill',
  props: {
    billType: {
      type: [Number, String],
    },
    searchKeyWord: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // 可见性
      visible: false,

      // 列表数据
      listData: [],

      // 搜索关键字
      keyWord: '',

      // 分页所在页数
      pageCurrent: 0,

      // 是否未加载完所有数据
      hasMore: false,

      // 是否正在加载
      isLoading: false,

      // 是否首次加载
      isFirstLoad: true,

      // 客户等级对应图标
      levelIcons,
    };
  },
  watch: {
    searchKeyWord(val) {
      console.log(val);
      this.keyWord = val;
    },
  },
  computed: {
    pagination() {
      return { size: 5, current: this.pageCurrent };
    },
  },
  created() {
    this.keyWord = this.searchKeyWord;
    this.getData();
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  destroyed() {
    document?.removeEventListener('click', this.handleDocumentClick);
  },
  methods: {
    /**
     * 输入框失焦事件
     */
    inputBlur() {},
    /**
     * 加载数据
     */
    getData(reset, loadMore) {
      this.debounceGetData(reset, loadMore);
    },
    debounceGetData: lodash.debounce(function (reset, loadMore) {
      if (!this.isFirstLoad) this.isLoading = true;
      if (loadMore) this.current++;
      // 重置分页，清空已有数据
      if (reset) {
        this.pageCurrent = 1;
        // fcd 滚动到头部
        // this.hasMore = true;
      } else {
        this.pageCurrent++;
      }
      searchCarForCreateCarBill(
        {
          msg: this.keyWord,
          type: +this.billType,
          companyId: this.$store.state.user.userInfo.companyId,
          ...this.pagination,
        },
        { noShowWaiting: true }
      )
        .then((res) => {
          if (reset) this.listData = [];
          console.log(res);
          this.listData.push(...res.records);
          this.hasMore = this.pageCurrent < res.pages;
        })
        .finally(() => {
          this.isLoading = false;
          this.isFirstLoad = false;
          // if (loadMore) this.$refs.keyWordInput.focus();
        });
    }, 5e2),
    /**
     * 选中
     */
    handleClick(val) {
      console.log('val', val);
      this.keyWord = val.carNumber;
      this.visible = false;
      this.$emit('select', val);
    },
    /**
     * 清空
     */
    handleClear() {
      this.visible = false;
      this.$emit('clear');
    },
    /**
     * 清空搜索关键字
     */
    clearKeyword() {
      this.keyWord = '';
    },
    handleDocumentClick(e) {
      if (!this.$el || this.$el.contains(e.target) || !e.target.className) {
        return;
      }
      this.visible = false;
    },
  },
};
</script>

<style>
.popper-class {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.car-table ::v-deep .el-table__body-wrapper {
  overflow: auto;
}

.load-more-btn {
  text-align: center;
  line-height: 50px;
  color: #3eb1e0;
  cursor: pointer;
  &:hover {
    color: #14a3db;
  }
}

.client-base-info {
  .icon_sex {
    color: $blue;
  }
  .icon_grade {
    color: $blue;
  }
  .grow-icon_girl {
    color: #f48080;
  }
  .icon_wechat {
    color: #999;
  }
  .icon_wechat_active {
    color: #00c800;
  }
  i {
    margin-left: 15px;
  }
}
</style>
