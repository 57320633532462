<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <el-form :model="form" class="filter-form" label-width="100px" size="small">
        <div class="flex-x-between">
          <div class="flex flex-shrink0">
            <el-form-item label="客户信息">
              <el-input v-model="form.clientMsg" clearable placeholder="请输入姓名/车牌" @keyup.enter.native="getData"></el-input>
            </el-form-item>
            <el-form-item label="车架号">
              <el-input
                v-model="form.uniqueId"
                clearable
                placeholder="请输入"
                @input="inputUniqueId"
                @keyup.enter.native="getData"></el-input>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div>
                  建档门店
                  <el-tooltip content="车辆档案的建档门店,可查连锁范围内全部门店" effect="light" placement="bottom-start">
                    <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
                  </el-tooltip>
                </div>
              </template>

              <el-select
                v-model="form.filingCompanyId"
                :loading="searchStoreLoading"
                :remote-method="searchStore"
                clearable
                filterable
                placeholder="搜索车店名称、编号"
                remote>
                <el-option v-for="item in storeList" :key="item.companyId" :label="item.companyName" :value="item.companyId">
                  <span style="float: left">{{ item.companyId }}</span>
                  <span style="float: right; color: #8492a6">{{ item.companyName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="el-form-item--small">
            <div class="el-form-item__label pointer" @click="showMore = !showMore">
              更多筛选
              <i
                :class="{
                  primary: showMore,
                  'el-icon-arrow-right': !showMore,
                  'el-icon-arrow-down': showMore,
                }"></i>
            </div>
            <div class="flex">
              <el-button class="btn_search" size="small" type="primary" @click="getData">查询</el-button>
              <el-button class="btn_search" size="small" @click="empty">置空</el-button>
            </div>
          </div>
        </div>
        <div class="flex-warp">
          <el-form-item label="车型">
            <el-select
              v-model="form.carModel"
              :loading="searchModelLoading"
              :remote-method="searchModel"
              allow-create
              clearable
              filterable
              placeholder="请选择"
              remote>
              <el-option
                v-for="item in models"
                :key="$lodash.get(item, 'series.id')"
                :value="$lodash.get(item, 'series.name', $lodash.get(item, 'brand.name', $lodash.get(item, 'logo.name')))"
                @click.native="selectCarModel(item)">
                <span v-html="item.markedRedName"></span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆状态">
            <el-select v-model="form.state" clearable placeholder="请选择">
              <el-option v-for="item in carState" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="在保状态">
            <el-select v-model="form.underWarrantyState" clearable placeholder="请选择">
              <el-option v-for="item in underWarrantyState" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="注册日期">
            <el-date-picker
              v-model="form.registerTime"
              end-placeholder="截止时间"
              range-separator="至"
              start-placeholder="起始时间"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <!-- <el-divider v-if="showMore"></el-divider> -->
          <template v-if="showMore">
            <el-form-item label="在租状态">
              <el-select v-model="form.underLeaseState" clearable placeholder="请选择">
                <el-option v-for="item in underLeaseState" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车辆所有人">
              <el-input v-model="form.vehicleOwner" clearable placeholder="请输入车辆所有人名称" @keyup.enter.native="getData"></el-input>
            </el-form-item>

            <el-form-item label="保险起保时间">
              <el-date-picker
                v-model="form.insuranceDueTime"
                end-placeholder="截止时间"
                range-separator="至"
                start-placeholder="起始时间"
                type="daterange"
                unlink-panels
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </template>
        </div>
      </el-form>
    </template>

    <template>
      <div class="btns flex-x-between" style="margin: 30px 0 14px 0">
        <div>
          <el-button v-auth="54" class="btn_insert" size="small" @click="addCar">新增</el-button>
          <el-dropdown placement="top" size="small" style="margin-left: 10px" trigger="click">
            <el-button v-auth="53" style="padding: 8px 28px !important">
              批量
              <i class="el-icon-arrow-right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" style="width: 90px">
              <el-dropdown-item class="btn-dropdown" @click.native="editor">编辑</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <el-button v-auth="283" class="blue" size="small" type="text" @click="showExportDialog = true">
          导出列表
          <i class="iconfont grow-icon_daochu el-icon--right"></i>
        </el-button>
      </div>

      <el-table
        v-fixedScrollBar="$refs.baseContainer"
        :data="carListData"
        border
        class="custom-table"
        @selection-change="handleSelectionChange"
        @row-dblclick="goDetail">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" type="index" width="60"></el-table-column>
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <span v-auth="53" class="text_operation blue" @click="handleEdit(scope.row)">编辑</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号" width="100">
          <template slot-scope="scope">
            <span class="text_operation blue" @click="goDetail(scope.row)">{{ scope.row.carNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车型/车架号" prop="uniqueId" width="180">
          <template #default="{ row }">
            <el-tooltip :content="row.carModelName">
              <p class="text-ellipsis">{{ row.carModelName }}</p>
            </el-tooltip>
            <p>{{ row.uniqueId }}</p>
          </template>
        </el-table-column>
        <el-table-column label="签约客户" prop="clientName" width="120">
          <template #default="{ row }">
            <p>{{ row.clientName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="最后到店里程" prop="lastIntoStoreMileage" width="120"></el-table-column>
        <el-table-column label="最后到店时间" prop="lastIntoStoreDate" width="120"></el-table-column>
        <el-table-column label="注册时间" prop="registerDate" width="100"></el-table-column>

        <el-table-column label="整车质保" width="100">
          <template #default="{ row }">
            <span v-if="row.hasVehicleWarranty">
              整车质保
              <el-tooltip :content="row.vehicleWarrantyContent" effect="light" placement="bottom-start">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasVehicleWarranty === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column label="三电质保" width="100">
          <template #default="{ row }">
            <span v-if="row.hasThreePowerWarranty">
              三电质保
              <el-tooltip :content="row.threePowerWarrantyContent" effect="light" placement="bottom-start">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasThreePowerWarranty === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column label="动力电池质保" width="100">
          <template #default="{ row }">
            <span v-if="row.hasPowerBatteryWarranty">
              动力电池质保
              <el-tooltip :content="row.powerBatteryWarrantyContent" effect="light" placement="bottom-start">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasPowerBatteryWarranty === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column label="电芯质保" width="100">
          <template #default="{ row }">
            <span v-if="row.hasBatteryWarranty">
              动力电池质保
              <el-tooltip :content="row.batteryWarrantyContent" effect="light" placement="bottom-start">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasBatteryWarranty === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column label="易损件质保" width="100">
          <template #default="{ row }">
            <span v-if="row.hasWarrantyWearingParts">
              易损件质保
              <el-tooltip :content="row.warrantyWearingPartsContent" effect="light" placement="bottom-start">
                <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
              </el-tooltip>
            </span>
            <span v-else-if="row.hasWarrantyWearingParts === 0">-</span>
          </template>
        </el-table-column>
        <el-table-column label="车辆状态" width="100">
          <template #default="{ row }">
            <span>{{ row.state | dict("carState") }}</span>
          </template>
        </el-table-column>
        <el-table-column label="在租状态" width="100">
          <template #default="{ row }">
            <span>{{ row.underLeaseState | dict("underLeaseState") }}</span>
          </template>
        </el-table-column>
        <el-table-column label="到店详情" prop="intoStoreDetails" width="180"></el-table-column>
        <el-table-column label="车辆所有人" width="160">
          <template #default="{ row }">
            <el-tooltip :content="row.vehicleOwner">
              <div class="text-ellipsis-2-row">
                {{ row.vehicleOwner }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="电动机/发动机号" width="160">
          <template #default="{ row }">
            <el-tooltip :content="row.engineNumber">
              <div class="text-ellipsis-2-row">
                {{ row.engineNumber }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="新车续航" prop="newVehicleMileage" width="100"></el-table-column>
        <el-table-column label="真实续航" prop="trueEndurance" width="100"></el-table-column>
        <el-table-column label="动力类型" width="100">
          <template #default="{ row }">
            <span>{{ row.fuelType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车辆来源" width="100">
          <template #default="{ row }">
            <span>{{ row.vehicleSource }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发车模式" width="100">
          <template #default="{ row }">
            <span>{{ row.departureMode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车损险" width="120">
          <template #default="{ row }">
            <p>{{ row.insuranceCompanyName }}</p>
            <p v-if="row.insuranceValidDate">
              <!-- <span
                :class="
                  (Date.parse(row.insuranceValidDate.substring(0, 10)) -
                    Date.now()) /
                    (1 * 24 * 60 * 60 * 1000) <=
                  30
                    ? 'red'
                    : ''
                "
              >
                {{ row.insuranceValidDate.substring(0, 10) }}到期</span
              > -->
              <span>{{ row.insuranceValidDate }}起保</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="三者险" width="120">
          <template #default="{ row }">
            <p>{{ row.thirdPartyInsuranceCompanyName }}</p>
            <p v-if="row.thirdPartyInsuranceValidDate">
              <!-- <span
                :class="
                  (Date.parse(row.thirdPartyInsuranceValidDate) - Date.now()) /
                    (1 * 24 * 60 * 60 * 1000) <=
                  30
                    ? 'red'
                    : ''
                "
              >
                {{ row.thirdPartyInsuranceValidDate }}到期</span
              > -->
              <span>{{ row.thirdPartyInsuranceValidDate }}起保</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="座位险" width="120">
          <template #default="{ row }">
            <p>{{ row.seatInsuranceCompanyName }}</p>
            <p v-if="row.seatInsuranceValidDate">
              <!-- <span
                :class="
                  (Date.parse(row.seatInsuranceValidDate) - Date.now()) /
                    (1 * 24 * 60 * 60 * 1000) <=
                  30
                    ? 'red'
                    : ''
                "
              >
                {{ row.seatInsuranceValidDate }}到期</span
              > -->
              <span>{{ row.seatInsuranceValidDate }}起保</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="交强险" width="120">
          <template #default="{ row }">
            <p>{{ row.tcInsuranceCompanyName }}</p>
            <p v-if="row.tcInsuranceValidDate">
              <!-- <span
                :class="
                  (Date.parse(row.tcInsuranceValidDate) - Date.now()) /
                    (1 * 24 * 60 * 60 * 1000) <=
                  30
                    ? 'red'
                    : ''
                "
              >
                {{ row.tcInsuranceValidDate }}到期</span
              > -->
              <span>{{ row.tcInsuranceValidDate }}起保</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="承运险" width="120">
          <template #default="{ row }">
            <p>{{ row.vehicleInsuranceCompanyName }}</p>
            <p v-if="row.vehicleInsuranceValidDate">
              <!-- <span
                :class="
                  (Date.parse(row.vehicleInsuranceValidDate) - Date.now()) /
                    (1 * 24 * 60 * 60 * 1000) <=
                  30
                    ? 'red'
                    : ''
                "
              >
                {{ row.vehicleInsuranceValidDate }}到期</span
              > -->
              <span>{{ row.vehicleInsuranceValidDate }}起保</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="驾乘险" width="120">
          <template #default="{ row }">
            <p>{{ row.drivingInsuranceCompanyName }}</p>
            <p v-if="row.drivingInsuranceValidDate">
              <!-- <span
                :class="
                  (Date.parse(row.drivingInsuranceValidDate) - Date.now()) /
                    (1 * 24 * 60 * 60 * 1000) <=
                  30
                    ? 'red'
                    : ''
                "
              >
                {{ row.drivingInsuranceValidDate }}到期</span
              > -->
              <span>{{ row.drivingInsuranceValidDate }}起保</span>
            </p>
          </template>
        </el-table-column>

        <el-table-column label="格悦出单" width="100">
          <template #default="{ row }">
            <p v-if="row.geyueIssuer || row.geyueIssuer == 0">
              {{ row.geyueIssuer == 0 ? "否" : "是" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="团车政策" width="100">
          <template #default="{ row }">
            <p v-if="row.groupCarPolicy || row.groupCarPolicy == 0">
              {{ row.groupCarPolicy == 0 ? "否" : "是" }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="团车代码" prop="groupCarCode" width="100"></el-table-column>
        <el-table-column label="承保特殊要求" prop="insureSpecialAsk" show-overflow-tooltip width="150">
          <template #default="{ row }">
            <span class="pointer">{{ row.insureSpecialAsk }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip width="180">
          <template slot-scope="scope">
            <span class="pointer">{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="建档人/建档时间" width="150">
          <template #default="{ row }">
            <p>{{ row.createOwnerName }}</p>
            <p>{{ row.dateCreated }}</p>
          </template>
        </el-table-column>
        <el-table-column label="建档门店" prop="companyName" width="180"></el-table-column>
      </el-table>
      <add-car-dialog v-if="showAddCarDialog" :id="editCarId" :showAddCarDialog.sync="showAddCarDialog" @save="getData"></add-car-dialog>
      <export-dialog
        :filterForm="form"
        :pageOption="pageOption"
        :total="total"
        :url="exportUrl"
        :visibility.sync="showExportDialog"></export-dialog>
    </template>

    <template slot="footer">
      <pagination :total="total" @pageChange="changePage"></pagination>
    </template>
  </base-container>
</template>

<script>
// 组件
import pagination from "@/components/Pagination.vue";
import AddCarDialog from "./addCar/addCarDialog.vue";
import ExportDialog from "@/components/ExportDialog";

// 接口
import { exportCarListUrl, getCarList, searchCarModel } from "@/api/customer/car";
import { searchClient } from "@/api/customer/customer";
import { searchStore } from "@/api/customer/store";

// 字典数据
import { carState, carType, underLeaseState, underWarrantyState } from "@/service/dict/dictData";

import { mapState } from "vuex";

// 工具

export default {
  name: "carList",
  components: { pagination, AddCarDialog, ExportDialog },
  data() {
    return {
      editCarId: "",

      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },

      showAddCarDialog: false,
      showExportDialog: false,
      exportUrl: exportCarListUrl,

      form: {
        filingCompanyId: "",
      },

      // 搜索用户
      clientList: [],
      searchClientLoading: false,
      // 搜索门店
      storeList: [],
      searchStoreLoading: false,
      //搜索车型信息列表字段
      searchModelLoading: false,
      models: [],

      // 展示更多筛选
      showMore: false,

      carType: carType,
      carState: carState,
      underWarrantyState,
      underLeaseState,

      // 车辆列表数据
      carListData: [],

      cloneForm: null,
      //选中列表数据
      selectedCarIds: [],
    };
  },

  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  activated() {
    if (this.$route.params.form && !this.$lodash.isEqual(this.$route.params.form, this.cloneForm)) {
      this.form = this.$lodash.cloneDeep(this.$route.params.form);
      this.cloneForm = this.$lodash.cloneDeep(this.$route.params.form);
      this.getData();
    }
  },
  created() {
    // 从其他页面跳转过来时附带了查询条件
    if (this.$route.params.form) {
      this.form = this.$route.params.form;
    }
    // this.form.filingCompanyId = this.user?.companyId;
    // this.storeList.unshift({
    //   companyId: this.user?.companyId,
    //   companyName: this.user?.companyName,
    // });
    this.getData();
    this.searchModel();
  },
  methods: {
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getData(false);
    },
    /**
     * 加载数据
     */
    getData(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var times = {
        companyId: this.user.companyId,
        //注册日期
        registerStartTime: this.form.registerTime?.[0] ? this.form.registerTime?.[0] : "",
        registerEndTime: this.form.registerTime?.[1] ? this.form.registerTime?.[1] : "",
        //保险到期时间
        insuranceDueStartTime: this.form.insuranceDueTime?.[0] ? this.form.insuranceDueTime?.[0] : "",
        insuranceDueEndTime: this.form.insuranceDueTime?.[1] ? this.form.insuranceDueTime?.[1] : "",
        insuranceCompanyType: this.form.insuranceCompanyType || 0,
      };
      var data = Object.assign(this.form, this.pageOption, times);
      getCarList(data).then(res => {
        console.log(res);
        // 修改总条数
        this.total = res.total;
        // 赋值records
        this.carListData = res.records;
      });
    },
    /**
     * 远程搜索客户列表
     */
    searchClient(keyWord) {
      if (keyWord) {
        this.searchClientLoading = true;
        searchClient(keyWord)
          .then(res => {
            this.clientList = res.records;
          })
          .finally(() => {
            this.searchClientLoading = false;
          });
      }
    },
    /**
     * 远程搜索门店列表
     */
    searchStore(keyWord) {
      if (keyWord) {
        this.searchStoreLoading = true;
        searchStore(keyWord, this.user?.companyId)
          .then(res => {
            this.storeList = res || [];
            // this.storeList = this.$lodash.uniqBy(
            //   this.storeList.concat(res),
            //   'companyId'
            // );
          })
          .finally(() => {
            this.searchStoreLoading = false;
          });
      }
    },
    /**
     * 跳转详情页
     */
    goDetail(row) {
      this.$router.push({
        name: "carDetail",
        // path?: string
        query: { id: row.carId },
      });
    },
    /**
     * 新增
     */
    addCar() {
      this.editCarId = "";
      this.$nextTick().then(() => {
        this.showAddCarDialog = true;
      });
    },
    // 车架号输入事件
    inputUniqueId(w) {
      var reg = /[^A-Za-z0-9]/;
      this.form.uniqueId = w.replace(reg, "");
    },

    /**
     * 编辑
     */
    handleEdit(row) {
      this.editCarId = row.carId;
      this.$nextTick().then(() => {
        this.showAddCarDialog = true;
      });
    },
    /**
     * 置空输入框数据
     */
    empty() {
      this.form = {};
    },
    selectCarModel(val) {
      // this.carLogoName = val.logo.name;
      // this.carBrandName = val.brand.name;
      // this.carSeriesName = val.series.name;
      console.log("val", val);
    },
    //远程搜索车型信息列表
    searchModel(key = "") {
      this.searchModelLoading = true;
      searchCarModel(key)
        .then(res => {
          this.models = res?.records || [];
        })
        .finally(() => {
          this.searchModelLoading = false;
        });
    },
    //选中的列表数据
    handleSelectionChange(val) {
      this.selectedCarIds = val.map(item => {
        return item.carId;
      });
    },
    //跳转到批量编辑车辆页面
    editor() {
      if (this.selectedCarIds.length) {
        this.$router.push({
          name: "carEditor",
          params: { ids: this.selectedCarIds },
        });
      } else {
        this.$message({
          type: "warning",
          message: "请先选择车辆!",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
}

.fade-enter-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.export-dialog__body {
  margin: 20px 0;
  text-align: center;

  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }

  .export-dialog__tip {
    margin-top: 20px;
  }
}

.btn-dropdown:focus,
.btn-dropdown:hover {
  color: #606266;
  background: #f5f7fa;
  border-color: #f5f7fa;
}
</style>
