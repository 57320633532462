<template>
  <div class="main-view">
    <transition name="fade">
      <keep-alive :include="openTags" :exclude="excludeView">
        <router-view v-if="isRouterAlive"></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import bus from "@/service/bus";
import { mapState, mapGetters } from "vuex";
export default {
  name: "MainView",
  data() {
    return {
      isRouterAlive: true,
      excludeView: null,
    };
  },
  computed: {
    ...mapState({
      tagList: (state) => state.tags.tagList,
      tagWel: (state) => state.tags.tagWel,
      tag: (state) => state.tags.tag,
    }),
    ...mapGetters(["activeTagIndex"]),
    openTags() {
      return this.tagList.map((item) => item.name);
    },
  },
  methods: {
    refresh() {
      this.excludeView = this.tag?.name;
      this.isRouterAlive = false;
      this.$nextTick().then(() => {
        this.excludeView = null;
        this.isRouterAlive = true;
      });
    },
  },
  created() {
    bus.$on("refresh", this.refresh);
  },
  beforeDestroy() {
    bus.$off("refresh", this.refresh);
  },
};
</script>

<style lang="scss" scoped>
.main-view {
  flex: 1;
  overflow: auto;
  background: #fff;
  //margin: 16px 16px 0 16px;
  margin: 12px;
  border-radius: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
