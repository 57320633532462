import HomeView from "@/page/home";

const pages = [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "@/page/Login.vue"),
    meta: {
      isPage: true,
    },
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import(/* webpackChunkName: "demo" */ "@/views/demo.vue"),
    meta: {
      isPage: true,
    },
  },
  {
    path: "/system-data",
    name: "systemData",
    component: () =>
      import(
        /* webpackChunkName: "systemData" */ "@/page/system-data/index.vue"
      ),
    meta: {
      isPage: true,
    },
  },
  {
    path: "*",
    redirect: "login",
  },
];

const views = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      isPage: true,
    },
    redirect: "/login",
  },
  {
    path: "/wel",
    component: HomeView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '@/views/wel.vue'),
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "/",
        name: "wel",
        meta: {
          title: "首页",
        },
        component: () =>
          import(/* webpackChunkName: "wel" */ "@/views/wel.vue"),
      },
    ],
  },
  {
    path: "/taskOverview",
    component: HomeView,
    meta: {
      title: "任务面板",
    },
    children: [
      {
        path: "/",
        name: "taskOverview",
        meta: {
          title: "任务面板",
        },
        component: () =>
          import(
            /* webpackChunkName: "taskOverview" */ "@/views/taskOverview.vue"
          ),
      },
    ],
  },
  {
    path: "/unTreated",
    component: HomeView,
    meta: {
      title: "待办",
    },
    children: [
      {
        path: "/",
        name: "unTreated",
        meta: {
          title: "待办",
        },
        component: () =>
          import(/* webpackChunkName: "userInfo" */ "@/views/unTreated.vue"),
      },
    ],
  },
  {
    path: "/userInfo",
    component: HomeView,
    meta: {
      title: "个人信息",
    },
    children: [
      {
        path: "/",
        name: "userInfo",
        meta: {
          title: "个人信息",
        },
        component: () =>
          import(/* webpackChunkName: "userInfo" */ "@/views/userInfo.vue"),
      },
    ],
  },
  /** 车单 **/
  {
    path: "/carBill",
    name: "carBill",
    component: HomeView,
    children: [
      {
        path: "carBillList",
        name: "carBillList",
        meta: {
          title: "车单记录",
          permission: 35,
        },
        component: () =>
          import(
            /* webpackChunkName: "carBillList" */ "@/views/carBill/carBillList/carBillList.vue"
          ),
      },
      {
        path: "carLossDeterminationBillList",
        name: "carLossDeterminationBillList",
        meta: {
          title: "定损单",
          permission: 457,
        },
        component: () =>
          import(
            /* webpackChunkName: "carLossDeterminationBillList" */ "@/views/carBill/carLossDeterminationBill/carLossDeterminationBillList.vue"
          ),
      },
      {
        path: "reverseProcessRecordList",
        name: "reverseProcessRecordList",
        meta: {
          title: "车单反向记录",
          permission: 439,
        },
        component: () =>
          import(
            /* webpackChunkName: "reverseProcessRecordList" */ "@/views/carBill/reverseProcessRecordList.vue"
          ),
      },
      {
        path: "repairDiscoverRecordList",
        name: "repairDiscoverRecordList",
        meta: {
          title: "疑似返修记录",
          permission: 668,
        },
        component: () =>
          import(
            /* webpackChunkName: "repairDiscoverRecordList" */ "@/views/carBill/repairDiscoverRecordList.vue"
          ),
      },
      {
        path: "miniProgramBookingRecordList",
        name: "miniProgramBookingRecordList",
        meta: {
          title: "预约信息",
          permission: 1007,
        },
        component: () =>
          import(
            /* webpackChunkName: "miniProgramBookingRecordList" */ "@/views/carBill/miniProgramBooking/miniProgramBookingRecordList.vue"
          ),
      },
      {
        path: "miniProgramBookingRegisterList",
        name: "miniProgramBookingRegisterList",
        meta: {
          title: "预约注册",
          permission: 1012,
        },
        component: () =>
          import(
            /* webpackChunkName: "miniProgramBookingRegisterList" */ "@/views/carBill/miniProgramBooking/miniProgramBookingRegisterList.vue"
          ),
      },
      {
        path: "miniProgramBookingNumberTakeList",
        name: "miniProgramBookingNumberTakeList",
        meta: {
          title: "现场排号",
          permission: 1013,
        },
        component: () =>
          import(
            /* webpackChunkName: "miniProgramBookingNumberTakeList" */ "@/views/carBill/miniProgramBooking/miniProgramBookingNumberTakeList.vue"
          ),
      },
      {
        path: "editCarBill",
        name: "editCarBill",
        meta: {
          title: "接待开单",
        },
        component: () =>
          import(
            /* webpackChunkName: "carBill" */ "@/views/carBill/editCarBill/index.vue"
          ),
      },
      {
        path: "carBillDetail",
        name: "carBillDetail",
        meta: {
          title: "车单详情",
          permission: 35,
        },
        component: () =>
          import(
            /* webpackChunkName: "carBillDetail" */ "@/views/carBill/carBillDetail/index.vue"
          ),
      },
      {
        path: "strandedCarStatistics",
        name: "strandedCarStatistics",
        meta: {
          title: "今日在场统计",
          permission: 521,
        },
        component: () =>
          import(
            /* webpackChunkName: "presentCar" */ "@/views/carBill/presentCar/strandedCarStatistics.vue"
          ),
      },
      {
        path: "presentCar",
        name: "presentCar",
        meta: {
          title: "今日在场明细",
          permission: 447,
        },
        component: () =>
          import(
            /* webpackChunkName: "presentCar" */ "@/views/carBill/presentCar/newPresentCar.vue"
          ),
      },
      {
        path: "strandedCar",
        name: "strandedCar",
        meta: {
          title: "历史滞留快照",
          permission: 448,
        },
        component: () =>
          import(
            /* webpackChunkName: "presentCar" */ "@/views/carBill/presentCar/newStrandedCar.vue"
          ),
      },
      {
        path: "constructionBill",
        name: "constructionBill",
        meta: {
          title: "施工单",
          permission: 94,
        },
        component: () =>
          import(
            /* webpackChunkName: "workshop" */ "@/views/carBill/workshopBill/constructionBill.vue"
          ),
      },
      {
        path: "batchPrint",
        name: "batchPrint",
        meta: {
          title: "批量打印",
          permission: 501,
        },
        component: () =>
          import(
            /* webpackChunkName: "batchPrint" */ "@/views/carBill/carBillList/batchPrint/batchPrint.vue"
          ),
      },
      {
        path: "vehicleEvaluationRecord",
        name: "vehicleEvaluationRecord",
        meta: {
          title: "车单评价记录",
          permission: 600,
        },
        component: () =>
          import(
            /* webpackChunkName: "batchPrint" */ "@/views/carBill/vehicleEvaluationRecord/vehicleEvaluationRecord.vue"
          ),
      },
    ],
  },
  /** 财务管理 **/
  {
    path: "/financialManagement",
    name: "financialManagement",
    component: HomeView,
    children: [
      {
        path: "invoiciDetailsList",
        name: "invoiciDetailsList",
        meta: {
          title: "车单结账明细",
          permission: 159,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/business/invoiciDetailsList.vue"
          ),
      },
      {
        path: "projectDetailsList",
        name: "projectDetailsList",
        meta: {
          title: "车单项目明细",
          permission: 213,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/business/projectDetailsList.vue"
          ),
      },
      {
        path: "subsidiaryDetailsList",
        name: "subsidiaryDetailsList",
        meta: {
          title: "车单实收明细",
          permission: 411,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/business/subsidiaryDetailsList.vue"
          ),
      },
      {
        path: "itemGrossProfitList",
        name: "itemGrossProfitList",
        meta: {
          title: "项目毛利明细",
          permission: 509,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/business/itemGrossProfitList.vue"
          ),
      },
      {
        path: "customerReconciliation",
        name: "customerReconciliation",
        meta: {
          title: "客户对账",
          permission: 527,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/business/customerReconciliation.vue"
          ),
      },
      {
        path: "reconciliationLot",
        name: "reconciliationLot",
        meta: {
          title: "对账批次",
          permission: 529,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/business/reconciliationLot.vue"
          ),
      },
      {
        path: "paymentDetailsList",
        name: "paymentDetailsList",
        meta: {
          title: "收支明细",
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/paymentDetails/paymentDetailsList.vue"
          ),
      },
      {
        path: "showBusinessDetailsList",
        name: "showBusinessDetailsList",
        meta: {
          title: "营业总表",
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/showBusiness/showBusinessDetailsList.vue"
          ),
      },
      {
        path: "receivableSummaryFirst",
        name: "receivableSummaryFirst",
        meta: {
          title: "应收账款汇总",
          permission: 495,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/receivableSummaryFirst.vue"
          ),
      },
      {
        path: "receivableDetailsSecond",
        name: "receivableDetailsSecond",
        meta: {
          title: "应收账款明细",
          permission: 161,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/receivableDetailsSecond.vue"
          ),
      },
      {
        path: "accountsReceivableList",
        name: "accountsReceivableList",
        meta: {
          title: "应收账款详情",
          permission: 161,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/accountsReceivableList.vue"
          ),
      },

      {
        path: "ClaimsFollowUp",
        name: "ClaimsFollowUp",
        meta: {
          title: "理赔跟进",
          permission: 1024,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/ClaimsFollowUp.vue"
          ),
      },
      {
        path: "SettlementFollowUp",
        name: "SettlementFollowUp",
        meta: {
          title: "结算跟进",
          permission: 1025,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/SettlementFollowUp.vue"
          ),
      },
      {
        path: "AccountingFollowUp",
        name: "AccountingFollowUp",
        meta: {
          title: "会计跟进",
          permission: 1026,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/AccountingFollowUp.vue"
          ),
      },
      {
        path: "FollowUpDetails",
        name: "FollowUpDetails",
        meta: {
          title: "跟进明细",
          permission: 1023,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/FollowUpDetails.vue"
          ),
      },
      {
        path: "ClaimDamage",
        name: "ClaimDamage",
        meta: {
          title: "索赔跟进",
          permission: 1028,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/ClaimDamage.vue"
          ),
      },
      {
        path: "DeductibleChanges",
        name: "DeductibleChanges",
        meta: {
          title: "免赔变动信息表",
          permission: 1030,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/DeductibleChanges.vue"
          ),
      },

      {
        path: "salesCollectionThird",
        name: "salesCollectionThird",
        meta: {
          title: "销账收款记录",
          permission: 216,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/salesCollectionThird.vue"
          ),
      },
      {
        path: "parcelDryFourch",
        name: "parcelDryFourch",
        meta: {
          title: "包干分摊批次",
          permission: 444,
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/parcelDryFourch.vue"
          ),
      },
      {
        path: "collectionChargeOffs",
        name: "collectionChargeOffs",
        meta: {
          title: "收款销账",
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/accountsReceivable/collectionChargeOffs.vue"
          ),
      },
      {
        path: "transaction/bank",
        name: "transaction_bank",
        meta: {
          title: "回款认领流水",
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/transaction/bank/bankList.vue"
          ),
      },
      {
        path: "transaction/claim",
        name: "transaction_claim",
        meta: {
          title: "回款认领记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/transaction/claim/claimList.vue"
          ),
      },
      {
        path: "transaction/submitwork",
        name: "transaction_submitwork",
        meta: {
          title: "回款认领提单",
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/transaction/submitwork/submitworkList.vue"
          ),
      },
      {
        path: "transaction/submitwork/complete",
        name: "transaction_submitwork_complete",
        meta: {
          title: "回款认领提单确认",
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/transaction/submitwork/submitworkCompleteList.vue"
          ),
      },
      {
        path: "transaction/submitwork/detail",
        name: "transaction_submitwork_detail",
        meta: {
          title: "认领提单核销明细",
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/transaction/submitwork/submitworkDetailList.vue"
          ),
      },
      {
        path: "transaction/claim/detail",
        name: "transaction_claim_detail",
        meta: {
          title: "回款认领明细",
        },
        component: () =>
          import(
            /* webpackChunkName: "financial" */ "@/views/financialManagement/transaction/claim/claimListDetail.vue"
          ),
      },
    ],
  },
  /** 客户管理 **/
  {
    path: "/customer",
    name: "customer",
    component: HomeView,
    redirect: "/customer/customerFiles",
    children: [
      {
        path: "customerFiles",
        name: "customerFiles",
        meta: {
          title: "客户档案",
          permission: 107,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/views/customerManagement/customerFiles.vue"
          ),
      },
      {
        path: "customerDetails",
        name: "customerDetails",
        meta: {
          title: "客户档案详情",
          permission: 107,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/views/customerManagement/customerDetails.vue"
          ),
      },
      {
        path: "carList",
        name: "carList",
        meta: {
          title: "车辆档案",
          permission: 52,
        },
        component: () =>
          import(
            /* webpackChunkName: "car" */ "@/views/customerManagement/car/carList.vue"
          ),
      },
      {
        path: "carDetail",
        name: "carDetail",
        meta: {
          title: "车辆档案详情",
          permission: 52,
        },
        component: () =>
          import(
            /* webpackChunkName: "car" */ "@/views/customerManagement/car/carDetail/carDetail.vue"
          ),
      },
      {
        path: "carEditor",
        name: "carEditor",
        meta: {
          title: "批量编辑车辆",
          permission: 52,
        },
        component: () =>
          import(
            /* webpackChunkName: "car" */ "@/views/customerManagement/car/carDetail/carEditor.vue"
          ),
      },
      {
        path: "agreementList",
        name: "agreementList",
        meta: {
          title: "客户协议",
          permission: 474,
        },
        component: () =>
          import(
            /* webpackChunkName: "agreement" */ "@/views/customerManagement/agreement/agreementList.vue"
          ),
      },
      {
        path: "agreementPriceList",
        name: "agreementPriceList",
        meta: {
          title: "客户协议价",
          permission: 725,
        },
        component: () =>
          import(
            /* webpackChunkName: "agreement" */ "@/views/customerManagement/agreement/agreementPriceList.vue"
          ),
      },
      {
        path: "agreementPriceDetailList",
        name: "agreementPriceDetailList",
        meta: {
          title: "客户协议明细",
          permission: 727,
        },
        component: () =>
          import(
            /* webpackChunkName: "agreement" */ "@/views/customerManagement/agreement/agreementPriceDetailList.vue"
          ),
      },
      {
        path: "agreementDetail",
        name: "agreementDetail",
        meta: {
          title: "协议详情",
          permission: 474,
        },
        component: () =>
          import(
            /* webpackChunkName: "agreement" */ "@/views/customerManagement/agreement/agreementDetail/agreementDetail.vue"
          ),
      },
      {
        path: "addAgreement",
        name: "addAgreement",
        meta: {
          title: "新增协议",
          //  permission: {codes: [475, 476],type:'some'},
        },
        component: () =>
          import(
            /* webpackChunkName: "agreement" */ "@/views/customerManagement/agreement/addAgreement/addAgreement.vue"
          ),
      },
      {
        path: "carInventory",
        name: "carInventory",
        meta: {
          title: "车辆盘点表",
          permission: 525,
        },
        component: () =>
          import(
            /* webpackChunkName: "car" */ "@/views/customerManagement/car/carInventory.vue"
          ),
      },
      {
        path: "usualQuote",
        name: "usualQuote",
        meta: {
          title: "常用报价模板",
          permission: 580,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/views/customerManagement/usualQuote/usualQuote.vue"
          ),
      },
      {
        path: "carModelList",
        name: "carModelList",
        meta: {
          title: "车型管理",
          permission: 586,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/views/customerManagement/carModelManage/carModelList.vue"
          ),
      },
      {
        path: "VMfeedback",
        name: "VMfeedback",
        meta: {
          title: "车管反馈",
          permission: 586,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/views/customerManagement/VMfeedback/VMfeedback.vue"
          ),
      },
      {
        path: "annualReview",
        name: "annualReview",
        meta: {
          title: "车辆年审申请",
          permission: 1008,
        },
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "@/views/customerManagement/mini/annualReview/list.vue"
          ),
      },
      {
        path: "carFactoryCompensationActivity",
        name: "carFactoryCompensationActivity",
        meta: {
          title: "厂家批量活动管理",
          permission: 1016,
        },
        component: () =>
          import(
            /* webpackChunkName: "CarFactoryCompensationActivity" */ "@/views/customerManagement/carFactoryCompensationActivity/list.vue"
          ),
      },
      {
        path: "carDeliverMsg",
        name: "carDeliverMsg",
        meta: {
          title: "车场交付中心",
          permission: 1031,
        },
        component: () =>
          import(
            /* webpackChunkName: "CarFactoryCompensationActivity" */ "@/views/customerManagement/carDeliverMsg/carDeliverMsg.vue"
            ),
      },
    ],
  },
  /** 数据看板 **/
  {
    path: "/dataBoard",
    name: "dataBoard",
    component: HomeView,
    children: [
      {
        path: "turnoverTargetBoard",
        name: "turnoverTargetBoard",
        meta: {
          /* 指挥中心 */
          title: "指挥中心",
          permission: 535,
        },
        component: () =>
          import(
            /* webpackChunkName: "turnoverTargetBoard" */ "@/views/dataBoard/turnoverTargetBoard.vue"
          ),
      },
      {
        path: "grossMarginRankList",
        name: "grossMarginRankList",
        meta: {
          title: "毛利总榜",
          permission: 567,
        },
        component: () =>
          import(
            /* webpackChunkName: "grossMarginRankList" */ "@/views/dataBoard/grossMarginRankList.vue"
          ),
      },
      {
        path: "marginDetail",
        name: "marginDetail",
        meta: {
          /* 毛利明细 */
          title: "毛利明细",
          permission: 568,
        },
        component: () =>
          import(
            /* webpackChunkName: "marginDetail" */ "@/views/dataBoard/marginDetail.vue"
          ),
      },
      {
        path: "lossAssessmentOverview",
        name: "lossAssessmentOverview",
        meta: {
          title: "定损业绩看板",
          permission: 602,
        },
        component: () =>
          import(
            /* webpackChunkName: "lossAssessmentOverview" */ "@/views/dataBoard/lossAssessment/lossAssessmentOverview.vue"
          ),
      },
      {
        path: "lossAssessmentExecutionReport",
        name: "lossAssessmentExecutionReport",
        meta: {
          title: "定损执行报表",
          permission: 603,
        },
        component: () =>
          import(
            /* webpackChunkName: "lossAssessmentExecutionReport" */ "@/views/dataBoard/lossAssessment/lossAssessmentExecutionReport.vue"
          ),
      },
      {
        path: "lossAssessmentDetailList",
        name: "lossAssessmentDetailList",
        meta: {
          title: "定损明细",
          permission: 605,
        },
        component: () =>
          import(
            /* webpackChunkName: "lossAssessmentDetailList" */ "@/views/dataBoard/lossAssessment/lossAssessmentDetailList.vue"
          ),
      },
      {
        path: "faultDiagnosisDetail",
        name: "faultDiagnosisDetail",
        meta: {
          title: "故障分诊明细",
          permission: 609,
        },
        component: () =>
          import(
            /* webpackChunkName: "faultDiagnosisDetail" */ "@/views/dataBoard/faultDiagnosisDetail.vue"
          ),
      },
      {
        path: "carStrandedDailyStatList",
        name: "carStrandedDailyStatList",
        meta: {
          title: "滞留单据日报",
          permission: 1019,
        },
        component: () =>
          import(
            /* webpackChunkName: "carStrandedDailyStatList" */ "@/views/dataBoard/carStrandedDailyStatList.vue"
          ),
      },
      {
        path: "wreckedCarEntryCountDailyStat",
        name: "wreckedCarEntryCountDailyStat",
        meta: {
          title: "事故车进店日报(门店)",
          permission: 766,
        },
        component: () =>
          import(
            /* webpackChunkName: "wreckedCarEntryCountDailyStat" */ "@/views/dataBoard/wreckedCarEntryCountDailyStat.vue"
          ),
      },
      {
        path: "carStrandedDailyStatDetailList",
        name: "carStrandedDailyStatDetailList",
        meta: {
          title: "滞留车单明细",
          permission: 1020,
        },
        component: () =>
          import(
            /* webpackChunkName: "carStrandedDailyStatDetailList" */ "@/views/dataBoard/carStrandedDailyStatDetailList.vue"
          ),
      },
      {
        path: "wreckedCarEntryCountWithClientDailyStat",
        name: "wreckedCarEntryCountWithClientDailyStat",
        meta: {
          title: "事故车进店日报(客户)",
          permission: 1021,
        },
        component: () =>
          import(
            /* webpackChunkName: "wreckedCarEntryCountWithClientDailyStat" */ "@/views/dataBoard/wreckedCarEntryCountWithClientDailyStat.vue"
          ),
      },
      {
        path: "companyOverview",
        name: "companyOverview",
        meta: {
          title: "门店接待调度面板",
          permission: 1022,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyOverview" */ "@/views/dataBoard/companyOverview.vue"
          ),
      },
      {
        path: "AccountsSummary",
        name: "AccountsSummary",
        meta: {
          title: "理赔应收账款汇总",
          permission: 1027,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyOverview" */ "@/views/dataBoard/AccountsSummary.vue"
          ),
      },
      {
        path: "ClaimsSummary",
        name: "ClaimsSummary",
        meta: {
          title: "索赔应收账款汇总",
          permission: 1029,
        },
        component: () =>
          import(
            /* webpackChunkName: "companyOverview" */ "@/views/dataBoard/ClaimsSummary.vue"
          ),
      },
    ],
  },
  /** 系统管理 **/
  {
    path: "/system",
    name: "system",
    component: HomeView,
    redirect: "/system/pakeManufacturer",
    children: [
      {
        path: "pakeManufacturer",
        name: "pakeManufacturer",
        meta: {
          title: "三包厂家",
          permission: 486,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/pakeManufacturer.vue"
          ),
      },
      {
        path: "systemManagement/carOrderSettings/serviceManagement",
        name: "serviceManagement",
        meta: {
          title: "服务管理",
          permission: 24,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/carOrderSettings/serviceManagement/serviceManagement.vue"
          ),
      },
      {
        path: "lossWarningSet",
        name: "lossWarningSet",
        meta: {
          title: "定损提醒设置",
          permission: 516,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/lossWarningSet.vue"
          ),
      },
      {
        path: "insuranceCompany",
        name: "insuranceCompany",
        meta: {
          title: "保险公司",
          permission: 479,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/insuranceCompany.vue"
          ),
      },
      {
        path: "businessSource",
        name: "businessSource",
        meta: {
          title: "业务来源管理",
          permission: 343,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/businessSource/businessSource.vue"
          ),
      },
      {
        path: "warrantyRule",
        name: "warrantyRule",
        meta: {
          title: "质保规则",
          permission: 532,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/warranty/warrantyRule.vue"
          ),
      },
      {
        path: "carSet",
        name: "carSet",
        meta: {
          title: "接车基础设置",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/carSet.vue"
          ),
      },
      {
        path: "storeManagement",
        name: "storeManagement",
        meta: {
          title: "开票主体",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/storeManagement.vue"
          ),
      },
      {
        path: "systemManagement/gyBillRepairNotificationSetting/GyBillRepairNotificationSetting",
        name: "GyBillRepairNotificationSetting",
        meta: {
          title: "返修追溯通知配置",
        },
        component: () =>
          import(
            /* webpackChunkName: "GyBillRepairNotificationSetting" */ "@/views/systemManagement/gyBillRepairNotificationSetting/GyBillRepairNotificationSetting.vue"
          ),
      },
      {
        path: "singleSet",
        name: "singleSet",
        meta: {
          title: "工单设置",
          permission: 347,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/singleSet.vue"
          ),
      },
      {
        path: "carDataImport",
        name: "carDataImport",
        meta: {
          title: "车辆信息导入",
          permission: 420,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/dataImport/carDataImport.vue"
          ),
      },
      {
        path: "carInfoTransferImport",
        name: "carInfoTransferImport",
        meta: {
          title: "车辆信息过户导入",
          // permission: 830,
        },
        component: () =>
          import(/* webpackChunkName: "system" */ "@/views/systemManagement/dataImport/carInfoTransferImport.vue"),
      },
      {
        path: "warrantyRulesImport",
        name: "warrantyRulesImport",
        meta: {
          title: "质保规则导入",
          permission: 542,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/dataImport/warrantyRulesImport.vue"
          ),
      },
      {
        path: "clientCommandImport",
        name: "clientCommandImport",
        meta: {
          title: "客户指令导入",
          permission: 547,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/dataImport/clientCommandImport.vue"
          ),
      },
      {
        path: "consultationModelImport",
        name: "consultationModelImport",
        meta: {
          title: "问诊模型导入",
          // permission: 547,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/dataImport/consultationModelImport.vue"
          ),
      },
      {
        path: "dataImportResultView",
        name: "dataImportResultView",
        meta: {
          title: "数据导入记录",
          permission: 1011,
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/views/systemManagement/dataImport/dataImportResultView.vue"
          ),
      },
    ],
  },
  /** 技术中心 **/
  {
    path: "/technologyCenter",
    name: "technologyCenter",
    component: HomeView,
    children: [
      {
        path: "materialList",
        name: "materialList",
        meta: {
          title: "素材管理",
          permission: 549,
        },
        component: () =>
          import(
            /* webpackChunkName: "materialList" */ "@/views/technologyCenter/materialManagement/materialList.vue"
          ),
      },
      {
        path: "materialEdit",
        name: "materialEdit",
        meta: {
          title: "素材编辑",
          // permission: 535,
        },
        component: () =>
          import(
            /* webpackChunkName: "materialEdit" */ "@/views/technologyCenter/materialManagement/materialEdit.vue"
          ),
      },
      {
        path: "consultationLibrary",
        name: "consultationLibrary",
        meta: {
          title: "问诊模型库",
          permission: 553,
        },
        component: () =>
          import(
            /* webpackChunkName: "materialList" */ "@/views/technologyCenter/inquiryModel/consultationLibrary.vue"
          ),
      },
      {
        path: "faultPhenomenon",
        name: "faultPhenomenon",
        meta: {
          title: "故障现象库",
          permission: 554,
        },
        component: () =>
          import(
            /* webpackChunkName: "materialList" */ "@/views/technologyCenter/inquiryModel/faultPhenomenon.vue"
          ),
      },
      {
        path: "failureCause",
        name: "failureCause",
        meta: {
          title: "故障原因库",
          permission: 555,
        },
        component: () =>
          import(
            /* webpackChunkName: "materialList" */ "@/views/technologyCenter/inquiryModel/failureCause.vue"
          ),
      },
      {
        path: "complicatedDisease",
        name: "complicatedDisease",
        meta: {
          title: "疑难杂症库",
          permission: 556,
        },
        component: () =>
          import(
            /* webpackChunkName: "materialList" */ "@/views/technologyCenter/inquiryModel/complicatedDisease.vue"
          ),
      },
    ],
  },
  /** 库存管理 **/
  {
    path: "/stock",
    name: "stock",
    component: HomeView,
    children: [
      {
        name: "goodsManage",
        path: "setting/goodsManage",
        meta: {
          title: "商品管理",
          permission: 18,
        },
        component: () =>
          import(
            /* webpackChunkName: "goodsManage" */ "@/views/stock/setting/goodsManage/goodsManage.vue"
          ),
      },
      {
        name: "goodsAliasManage",
        path: "setting/goodsAliasManage",
        meta: {
          title: "品名管理",
          permission: 582,
        },
        component: () =>
          import(
            /* webpackChunkName: "goodsAliasManage" */ "@/views/stock/setting/goodsAliasMange/goodsAliasManage.vue"
          ),
      },
      {
        name: "goodsControlManage",
        path: "setting/goodsControlManage",
        meta: {
          title: "商品管控管理",
          permission: 630,
        },
        component: () =>
          import(
            /* webpackChunkName: "goodsControlManage" */ "@/views/stock/setting/goodsControlManage/goodsControlManage.vue"
          ),
      },
      {
        name: "inventorySummary",
        path: "query/inventorySummary",
        meta: {
          title: "库存总览",
          permission: 131,
        },
        component: () =>
          import(
            /* webpackChunkName: "inventorySummary" */ "@/views/stock/query/inventorySummary/inventorySummary.vue"
          ),
      },
      {
        name: "inventoryWarning",
        path: "query/inventoryWarning",
        meta: {
          title: "库存预警",
          permission: 624,
        },
        component: () =>
          import(
            /* webpackChunkName: "inventoryWarning" */ "@/views/stock/query/inventoryWarning/inventoryWarningList.vue"
          ),
      },
      {
        name: "inventoryReplenishment",
        path: "query/inventoryReplenishment",
        meta: {
          title: "库存补货",
          permission: 629,
        },
        component: () =>
          import(
            /* webpackChunkName: "inventoryReplenishment" */ "@/views/stock/query/inventoryReplenishment/inventoryReplenishmentList.vue"
          ),
      },
      {
        name: "purchasePriceControlList",
        path: "setting/purchase/price/control/list",
        meta: {
          title: "采购价管控",
          permission: 1009,
        },
        component: () =>
          import(
            /* webpackChunkName: "purchasePriceControlList" */ "@/views/stock/setting/purchase/priceControlList.vue"
          ),
      },
      {
        name: "purchaseReportByGoods",
        path: "report/purchase/reportByGoods",
        meta: {
          title: "采购单入库监控",
          permission: 1010,
        },
        component: () =>
          import(
            /* webpackChunkName: "purchaseReportByGoods" */ "@/views/stock/report/purchase/reportByGoods.vue"
          ),
      },
      {
        name: "waitReturnInInventory",
        path: "report/material/waitReturnInInventory",
        meta: {
          title: "待退料入库",
          permission: 1018,
        },
        component: () =>
          import(
            /* webpackChunkName: "waitReturnInInventory" */ "@/views/stock/report/material/waitReturnInInventory.vue"
          ),
      },
    ],
  },
];

const routes = pages.concat(views);
export default routes;
