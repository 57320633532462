<template>
  <base-container :defaultPadding="false">
    <el-form
      class="filter-form"
      :model="form"
      label-position="left"
      label-width="100px"
      size="small"
    >
      <div class="flex-x-between" style="width: 100%">
        <div class="flex flex-shrink0">
          <el-form-item label="选择时间">
            <el-date-picker
              class="w100"
              v-model="form.statDayTime"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="el-form-item--small">
          <div class="flex">
            <el-button
              v-auth="762"
              class="btn_search"
              type="primary"
              size="small"
              @click="getDataList"
              >查询</el-button
            >
          </div>
        </div>
      </div>
    </el-form>
    <div class="btns flex-x-between">
      <div style="line-height: 34px">当前汇总：{{ form.statDayTime }}</div>
      <div>
        <el-button
          v-auth="763"
          size="small"
          type="text"
          class="blue"
          @click="downloadAllRecords"
          >导出汇总<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      id="elMainTableWce"
      border
      class="custom-table"
      show-summary
      :summary-method="getSummaries"
      :header-cell-style="handHeaderCellMerge"
      style="height: calc(63vh)"
    >
      <el-table-column
        type="index"
        label="序号"
        width="60"
        header-align="center"
      ></el-table-column>
      <el-table-column
        width="120"
        label="操作门店"
        prop="companyName"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        width="90"
        label="责任人"
        prop="responsibleUserName"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        width="90"
        label="当月目标"
        prop="responsibilityTargetNum"
        header-align="center"
      >
      </el-table-column>
      <el-table-column width="180" label="当月累计进场量" header-align="center">
        <el-table-column
          width="40"
          label="全责"
          prop="fullResponsibilityMonthOnMonthState"
          header-align="center"
        >
          <template #default="{ row }">
            <img
              v-if="row.fullResponsibilityMonthOnMonthState === -1"
              src="../../assets/img/car/down_red.png"
              class="monthOnMonthStateClass"
            />
            <img
              v-if="row.fullResponsibilityMonthOnMonthState === 0"
              src="../../assets/img/car/balance_yellow.png"
              class="monthOnMonthStateClass"
            />
            <img
              v-if="row.fullResponsibilityMonthOnMonthState === 1"
              src="../../assets/img/car/up_green.png"
              class="monthOnMonthStateClass"
            />
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          label="全责"
          prop="fullResponsibilityCount"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          width="40"
          label="三者"
          prop="noResponsibilityMonthOnMonthState"
          header-align="center"
        >
          <template #default="{ row }">
            <img
              v-if="row.noResponsibilityMonthOnMonthState === -1"
              src="../../assets/img/car/down_red.png"
              class="monthOnMonthStateClass"
            />
            <img
              v-if="row.noResponsibilityMonthOnMonthState === 0"
              src="../../assets/img/car/balance_yellow.png"
              class="monthOnMonthStateClass"
            />
            <img
              v-if="row.noResponsibilityMonthOnMonthState === 1"
              src="../../assets/img/car/up_green.png"
              class="monthOnMonthStateClass"
            />
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          label="三者"
          prop="noResponsibilityCount"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          width="40"
          label="合计"
          prop="allCountMonthOnMonthState"
          header-align="center"
        >
          <template #default="{ row }">
            <img
              v-if="row.allCountMonthOnMonthState === -1"
              src="../../assets/img/car/down_red.png"
              class="monthOnMonthStateClass"
            />
            <img
              v-if="row.allCountMonthOnMonthState === 0"
              src="../../assets/img/car/balance_yellow.png"
              class="monthOnMonthStateClass"
            />
            <img
              v-if="row.allCountMonthOnMonthState === 1"
              src="../../assets/img/car/up_green.png"
              class="monthOnMonthStateClass"
            />
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          label="合计"
          prop="allCount"
          header-align="center"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column
        width="110"
        label="目标进度"
        prop="targetProcessNum"
        header-align="center"
      >
        <template #default="{ row }"> {{ row.targetProcessNum }}% </template>
      </el-table-column>
      <el-table-column
        width="150"
        label="目标-时间进度差"
        prop="targetSubtractTimeProcessNum"
        header-align="center"
      >
        <template #default="{ row }">
          {{ row.targetSubtractTimeProcessNum }}%
        </template>
      </el-table-column>
      <el-table-column width="180" label="上月累计进场量" header-align="center">
        <el-table-column
          width="80"
          label="全责"
          prop="lastMonthFullResponsibilityCount"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          width="80"
          label="三者"
          prop="lastMonthNoResponsibilityCount"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          width="80"
          label="合计"
          prop="lastMonthAllCount"
          header-align="center"
        >
        </el-table-column>
      </el-table-column>
    </el-table>
  </base-container>
</template>

<script>
//接口
import { downloadFile } from '@/utils/fileApi';
import {
  wreckedCarEntryCountDailyList,
  wreckedCarEntryCountDailyListExportUrl,
} from '@/api/carBill/carWreckedAndStranded';
import { hasAuth } from '@/utils/permissions';
import { mapState } from 'vuex';
export default {
  name: 'wreckedCarEntryCountDailyStatList',
  components: {},
  props: {},
  data() {
    return {
      exportUrl: wreckedCarEntryCountDailyListExportUrl,
      form: {
        statDayTime: '',
      },
      tableData: [],
      sumData: {},
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.user.userInfo.companyId,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const days = today.getDate();
      const currentDate = new Date(`${year}-${month}-${days}`);
      const todayDateTime = new Date(
        currentDate.setDate(currentDate.getDate() - 1)
      );
      this.form.statDayTime = todayDateTime.Format('yyyy-MM-dd');
      this.getDataList();
    },
    /**
     * 加载数据
     */
    getDataList(reset = true) {
      const _this = this;
      var res = hasAuth(762);
      if (!res) {
        return;
      }
      var data = this.getFormDataBody();
      wreckedCarEntryCountDailyList(data).then((res) => {
        _this.sumData = res.sumStatItem;
        _this.sumData.companyName = '合计';
        let _tableData = res.statItems || [];
        // _tableData.push(_this.sumData);
        _this.tableData = _tableData;
        setTimeout(() => {
          // 处理因表头合并，导致表尾也合并，数据错乱
          const tds = document.querySelectorAll(
            '#elMainTableWce .el-table__footer td'
          );
          tds.forEach((item) => {
            item.setAttribute('colspan', 1);
            item.setAttribute('rowspan', 1);
          });
        }, 100);
      });
    },
    getSummaries(param) {
      const _this = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计';
        } else if (index > 2) {
          if (
            ['targetProcessNum', 'targetSubtractTimeProcessNum'].includes(
              column.property
            )
          ) {
            sums[index] =
              _this.$lodash.get(_this.sumData, column.property, '') + '%';
          } else if (
            // eslint-disable-next-line no-dupe-else-if
            [
              'fullResponsibilityMonthOnMonthState',
              'noResponsibilityMonthOnMonthState',
              'allCountMonthOnMonthState',
            ].includes(column.property)
          ) {
            // 升、降、平 不在汇总里面
            // console.log(1);
            sums[index] = '';
          } else {
            sums[index] = _this.$lodash.get(_this.sumData, column.property, '');
          }
        } else {
          sums[index] = '';
        }
      });
      console.table(sums);
      return sums;
    },
    handHeaderCellMerge(params) {
      const { row, column, rowIndex, columnIndex } = params;
      console.log('handHeaderCellMerge', row, column, rowIndex, columnIndex);
      if (rowIndex === 1) {
        row[0].colSpan = 0;
        row[1].colSpan = 2;
        row[2].colSpan = 0;
        row[3].colSpan = 2;
        row[4].colSpan = 0;
        row[5].colSpan = 2;
        if (columnIndex === 0 || columnIndex === 2 || columnIndex === 4) {
          return { display: 'none' };
        }
      }
      return {};
    },
    getFormDataBody() {
      var times = {};
      var data = Object.assign({}, this.form, times);
      return data;
    },
    downloadAllRecords() {
      var data = this.getFormDataBody();
      downloadFile({
        url: this.exportUrl,
        data: data,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  width: 100%;
  display: inline-flex;
}
.monthOnMonthStateClass {
  width: 15px;
  height: 15px;
}
.custom-tabs {
  ::v-deep .el-tabs__header {
    margin: 0px 0px 0px !important;
    border-bottom: none;
    .el-tabs__nav {
      // border-bottom: 1px solid #e4e7ed;
    }
    .el-tabs__item {
      height: auto;
      border-bottom: 1px solid #e4e7ed;
      &.is-active {
        background: rgba(51, 171, 121, 0.1);
      }
    }
  }
}
.el-table .cell {
  white-space: pre-line !important;
}
</style>
