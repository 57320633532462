<template>
  <el-dialog
    :title="title"
    top="5vh"
    :append-to-body="true"
    width="60%"
    :visible="showAddCusterDialog"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div id="client-content-box" style="height: 60vh; overflow-y: scroll">
      <el-row>
        <el-col :span="24">
          <div class="add_more">
            <div>基本信息</div>
          </div>
        </el-col>
      </el-row>
      <el-form
        :model="form"
        label-width="100px"
        size="small"
        :rules="rules"
        ref="form"
      >
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item
              label="客户简称:"
              prop="clientName"
              placeholder="请输入客户简称"
            >
              <el-input
                v-model="form.clientName"
                placeholder="请输入客户简称"
                maxlength="20"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司全称:">
              <el-input
                v-model="form.companyFullName"
                placeholder="请输入公司名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车辆来源:">
              <el-input
                v-model="form.vehicleSource"
                placeholder="请输入车辆来源"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8"
            ><el-form-item label="客户等级:">
              <el-select
                v-model="form.clientLevel"
                placeholder="请选择"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="item in clientLevel"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="车队管理系统:">
              <el-input
                v-model="form.fleetManageSystem"
                placeholder="请输入车队管理系统"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="社会信用代码:">
              <el-input
                v-model="form.idNum"
                placeholder="请输入信用代码"
                maxlength="18"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="股东结构:">
              <el-input
                v-model="form.shareholderStructure"
                placeholder="请输入股东结构"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="法人信息:">
              <el-input
                v-model="form.legalPerson"
                placeholder="请输入法人"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="成立时间:">
              <el-date-picker
                v-model="form.establishedTime"
                type="date"
                placeholder="请选择时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="实际运营车辆:">
              <number-input
                v-model="form.actualVehicle"
                :enabledDecimals="false"
                placeholder="请输入运营中车辆数"
                clearable
              ></number-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司性质:">
              <el-input
                v-model="form.companyNature"
                placeholder="请输入公司性质"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发车模式:">
              <el-input
                v-model="form.departureMode"
                placeholder="请输入发车模式"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="车辆规模:">
              <number-input
                v-model="form.vehicleScale"
                :enabledDecimals="false"
                placeholder="请输入车辆规模"
                clearable
              ></number-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="专属客拓" class="kt">
              <template slot="label">
                <div class="kt-span">
                  <div>专属客拓:</div>
                  <el-tooltip
                    effect="light"
                    content="当前客户的专属客拓经理。支持用在小程序车管可直接联系该员工"
                    placement="bottom-start"
                    style="padding-right: 10px"
                  >
                    <i
                      class="iconfont grow-icon_doubt"
                      style="color: #9a9a9a"
                    ></i>
                  </el-tooltip></div
              ></template>
              <div>
                <el-select
                  v-model="form.exclusiveCustomerManager"
                  filterable
                  clearable
                  remote
                  placeholder="搜索姓名"
                  :remote-method="searchAllUser"
                  :loading="searchAllUserLoading"
                >
                  <el-option
                    v-for="item in allUserList"
                    :key="item.userId"
                    :label="item.realName"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="双系客户:">
              <div>
                <el-select
                  v-model="form.isDualSystem"
                  filterable
                  clearable
                  placeholder="请选择双系客户"
                  style="width: 100%"
                >
                  <el-option :value="true" label="是">是</el-option>
                  <el-option :value="false" label="否">否</el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24"
            ><el-form-item label="备注:">
              <el-input
                v-model="form.remark"
                placeholder="请输入备注"
                maxlength="1000"
                show-word-limit
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="办公地址:">
              <el-input
                v-model="form.address"
                placeholder="请输入办公地址"
                maxlength="100"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24"
            ><el-form-item label="客户图片:"
              ><div class="tipsWord">
                (最多可上传10张图片，建议每张图片大小不超过1M)
              </div>
              <div class="custer-imgs">
                <upload-file
                  v-model="form.picBusinessLicense"
                  title="营业执照"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                ></upload-file>
                <upload-file
                  v-model="form.picLicenceOpeningAccounts"
                  title="开户许可证"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                ></upload-file>
                <upload-file
                  v-model="form.picCertificateOther"
                  title="其他证件"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                ></upload-file>
                <upload-file-list
                  :max="7"
                  v-model="form.picOther"
                  @input="handlerAllPictures"
                  :imagesForPreview="allPictures"
                ></upload-file-list>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="24">
          <el-col :span="23" :offset="1">
            <div class="client-list">
              <div class="client" v-if="form.clientId">
                <i
                  class="iconfont grow-icon_delete3 icon_delete pointer"
                  @click="deleteClient"
                ></i>
                <div class="client-info">
                  <div>
                    <i class="iconfont grow-icon_person icon_person"></i>
                    <span>{{ clientBriefMessageVo.clientName }}</span>
                    <i
                      class="iconfont icon_sex"
                      :class="{
                        'grow-icon_man': clientBriefMessageVo.gender == 0,
                        'grow-icon_girl': clientBriefMessageVo.gender == 1,
                      }"
                    ></i>
                    <i
                      class="iconfont grow-icon_grade icon_grade"
                      :class="levelIconsOther[clientBriefMessageVo.clientLevel]"
                    ></i>
                  </div>
                  <div>
                    <i class="iconfont grow-icon_iphone icon_phone"></i>
                    <span>{{ clientBriefMessageVo.clientPhone }}</span>
                    <i class="iconfont grow-icon_vip icon_vip"></i>
                    会员卡/协议客户（包干）
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="24">
            <div class="add_more">
              <div>其他联系人</div>
              <div @click="addMore()">新增其他联系人</div>
            </div>
          </el-col>
        </el-row>
        <div
          class="add_lies"
          v-for="(item, index) in otherContacts"
          v-bind:key="index"
        >
          <div style="width: 5%">
            <i
              class="iconfont grow-icon_delete3 orange"
              style="position: absolute; top: 40%; font-size: 22px"
              @click="deleteContacts(index, item.contactsId)"
            ></i>
          </div>
          <el-form
            :model="item"
            label-width="100px"
            size="small"
            class="other-form"
          >
            <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="岗位：">
                  <el-select
                    v-model="item.position"
                    placeholder="请选择岗位"
                    clearable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in position"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系人姓名：">
                  <el-input
                    v-model="item.contactsName"
                    placeholder="请输入姓名"
                    maxlength="20"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系方式：">
                  <number-input
                    v-model="item.contactsPhone"
                    placeholder="请输入联系方式"
                    :maxlength="11"
                    clearable
                  ></number-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item label="备注：">
                  <el-input
                    v-model="item.remark"
                    placeholder="请填写联系人备注信息"
                    maxlength="100"
                    show-word-limit
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-row>
          <el-col :span="24">
            <div class="add_more">
              <div>业务信息</div>
            </div>
          </el-col>
        </el-row>
        <el-form
          :model="clientBusinessMessageDto"
          label-width="115px"
          size="small"
          :rules="rules"
          ref="form"
        >
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="合作模式:">
                <el-input
                  v-model="clientBusinessMessageDto.cooperationModel"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="合作时间:">
                <el-date-picker
                  v-model="clientBusinessMessageDto.cooperationTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8"
              ><el-form-item label="客户来源:">
                <el-select
                  v-model="clientBusinessMessageDto.customerSource"
                  placeholder="请选择"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in customerSource"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="自费维保:">
                <el-input
                  v-model="clientBusinessMessageDto.selfPayingMaintenance"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="事故维修:">
                <el-input
                  v-model="clientBusinessMessageDto.accidentMaintenance"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="退还车/整备:">
                <el-input
                  v-model="clientBusinessMessageDto.returnCar"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="车辆保险:">
                <el-input
                  v-model="clientBusinessMessageDto.carInsurance"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="强收车:">
                <el-input
                  v-model="clientBusinessMessageDto.forcedRetracting"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="停车场:">
                <el-input
                  v-model="clientBusinessMessageDto.parkingLot"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="车务代办:">
                <el-input
                  v-model="clientBusinessMessageDto.vehicleAgent"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="保费分期:">
                <el-input
                  v-model="clientBusinessMessageDto.premiumInstalment"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="车辆代发:">
                <el-input
                  v-model="clientBusinessMessageDto.carDispatch"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="车管报表:">
                <el-input
                  v-model="clientBusinessMessageDto.carReport"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="金融服务:">
                <el-input
                  v-model="clientBusinessMessageDto.financialServices"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="新车采购:">
                <el-input
                  v-model="clientBusinessMessageDto.newCarPurchase"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="二手车销售:">
                <el-input
                  v-model="clientBusinessMessageDto.secondHandCarSales"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电池回收:">
                <el-input
                  v-model="clientBusinessMessageDto.batteryRecycling"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="add_more">
                <div>事故信息</div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    <el-tooltip
                      effect="light"
                      content="事故信息保险公司推送接收人号码"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                    <span> 信息接收人</span>
                  </div>
                </template>
                <el-input
                  v-model="clientBusinessMessageDto.messageReceivingNumber"
                  placeholder="输入信息接收人及号码"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    <el-tooltip
                      effect="light"
                      content="事故消息保险公司推送格悦的方式"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                    保司推送方式
                  </div>
                </template>
                <el-input
                  v-model="clientBusinessMessageDto.insuranceCompanyPush"
                  placeholder="输入保险公司推送方式"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    <el-tooltip
                      effect="light"
                      content="事故信息车队推送格悦的方式"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                    车队推送方式
                  </div>
                </template>
                <el-input
                  v-model="clientBusinessMessageDto.fleetPush"
                  placeholder="输入车队推送方式"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" style="margin-top: 36px">
            <el-col :span="8">
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    <el-tooltip
                      effect="light"
                      content="与司机合同约定有责事故联系方式"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                    有责事故约定
                  </div>
                </template>
                <el-input
                  v-model="
                    clientBusinessMessageDto.responsibleAccidentAgreement
                  "
                  placeholder="输入司机合同约定"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    <el-tooltip
                      effect="light"
                      content="与司机合同约定无责事故联系方式"
                      placement="bottom-start"
                    >
                      <i
                        class="iconfont grow-icon_doubt"
                        style="color: #9a9a9a"
                      ></i>
                    </el-tooltip>
                    无责事故约定
                  </div>
                </template>
                <el-input
                  v-model="
                    clientBusinessMessageDto.noResponsibleAccidentAgreement
                  "
                  placeholder="输入司机合同约定"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-form>
    </div>
    <template slot="footer">
      <div>
        <el-button size="small" @click="saveList" type="primary"
          >确定保存</el-button
        >
        <el-button size="small" @click="handleClose">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { levelIconsOther } from '@/const/client';
import {
  getCustomerDetail,
  getClientBusinessMessage,
} from '@/api/customer/customer';
import { insertCustomer } from '@/api/customer/customer';
import { updateCustomer, searchSourceClient } from '@/api/customer/customer';
import UploadFile from '@/components/uploadFile.vue';
import uploadFileList from '@/components/uploadFileList';

// 字典数据
import {
  position,
  otherCertificateType,
  customerSource,
  clientLevel,
} from '@/service/dict/dictData';
import { getAllUser } from '@/api/employee';

export default {
  name: 'addCustomer',
  components: { UploadFile, uploadFileList },
  props: ['showAddCusterDialog', 'id'],
  data() {
    return {
      form: {},
      //业务信息
      clientBusinessMessageDto: {},
      title: '新增客户',
      clientLevel: clientLevel,
      otherCertificateType: otherCertificateType,
      customerSource: customerSource,
      position: position,
      otherContacts: [], //这是存放添加更多联系人的数组对象
      custlist: [],
      searchClientLoading: false,
      // 客户等级对应图标
      levelIconsOther,
      // 表单规则
      rules: {
        clientName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' },
        ],
        // clientPhone: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        // ],
      },
      removeContactIds: [], //这是要删除的其他联系人ID数组
      clientBriefMessageVo: {},

      allPictures: [],
      allUserList: [],
      searchAllUserLoading: false,
    };
  },
  created() {
    if (this.id) {
      this.title = '编辑客户';
      getCustomerDetail(this.id).then((res) => {
        this.form = res;
        this.form.clientId = res.clientBriefMessageVo?.id || '';
        this.clientBriefMessageVo = res.clientBriefMessageVo || {};
        this.custlist = [res.clientBriefMessageVo]; //处理回显数据
        if (res.picClientLists?.length > 0) {
          this.form.picBusinessLicense =
            res.picClientLists.find((item) => item.picTypeName === '营业执照')
              ?.picUrlName ?? '';
          this.form.picLicenceOpeningAccounts =
            res.picClientLists.find((item) => item.picTypeName === '开户许可证')
              ?.picUrlName ?? '';
          this.form.picCertificateOther =
            res.picClientLists.find((item) => item.picTypeName === '其他证件')
              ?.picUrlName ?? '';

          this.form.picOther = res.picClientLists
            .filter((item) => item.picTypeName == '其他资料')
            .map((item) => item.picUrlName)
            .join();

          this.oldImgString =
            String(this.form.picBusinessLicense) +
            String(this.form.picLicenceOpeningAccounts) +
            String(this.form.picCertificateOther) +
            String(this.form.picOther);

          this.handlerAllPictures();
        } else {
          this.form.picOther = '';
          this.handlerAllPictures();
        }

        this.otherContacts = res.otherContacts;
      });
      //查询客户业务信息
      getClientBusinessMessage(this.id).then((res) => {
        this.clientBusinessMessageDto = res;
      });
    } else {
      this.form.picOther = '';
      this.handlerAllPictures();
    }
    this.searchClient();
    this.searchAllUser();
  },
  methods: {
    /**
     * 全部图片
     */
    handlerAllPictures() {
      this.allPictures = [
        this.form.picBusinessLicense,
        this.form.picLicenceOpeningAccounts,
        this.form.picCertificateOther,
        ...this.form.picOther?.split(','),
      ].filter((item) => !!item);
    },
    /**
     * 客户生日禁用日期
     */
    // disabledDate(date) {
    //   return new Date(date).getTime() > new Date().setHours(0, 0, 0, 0);
    // },
    deleteContacts(index, id) {
      this.otherContacts.splice(index, 1);
      if (id != undefined) {
        this.removeContactIds.push(id);
      }
    },
    /**
     * 搜索跟进人列表
     */
    searchAllUser(msg) {
      this.searchAllUserLoading = true;
      getAllUser({ msg })
        .then((res) => {
          this.allUserList = res.records || [];
        })
        .finally(() => {
          this.searchAllUserLoading = false;
        });
    },
    // 添加更多联系人
    addMore() {
      this.otherContacts.push({
        contactsName: '',
        contactsGender: '',
        contactsPhone: '',
        remark: '',
      });
      //让滚动条实现在页面最底部
      // this.$nextTick(function () {
      //   var div = document.getElementById('client-content-box');
      //   div.scrollTop = div.scrollHeight;
      // });
    },
    // 保存新增客户信息
    saveList() {
      this.$refs.form.validate((result, object) => {
        console.log('this.form1111', this.otherContacts);
        if (result) {
          var hasSamePhone = this.form.clientPhone;
          //判断添加其他联系人的时候是否是每个都存在值，并且判断更多其他联系人中是否存在跟上面客户手机号相同的手机号
          if (this.otherContacts.length != 0) {
            var otherContactsInfo = this.otherContacts.every((item) => {
              // const errors = [undefined, '', hasSamePhone];
              const errors = [hasSamePhone];
              return (
                // !errors.includes(item.contactsName) &&
                // !errors.includes(item.contactsPhone) &&
                // !errors.includes(item.remark)
                !errors.includes(item.contactsPhone)
              );
            });
            if (otherContactsInfo) {
              this.doSave();
            } else {
              this.$message.warning('请填写其他联系人,并且手机号不能一样');
            }
          } else {
            this.doSave();
          }
        } else {
          this.$message.warning('请填写必填项');
          console.log(result, object);
        }
      });
    },
    /**
     * 这个是保存是数据调用函数
     */
    doSave() {
      this.form.clientSourceId = this.form.clientId; //这是是客户来源ID
      // 处理图片
      let cloneForm = JSON.parse(JSON.stringify(this.form));
      console.log('this.form', cloneForm);
      cloneForm.viGuaranteeSlipImg = this.form.viGuaranteeSlipImg
        ?.split('/')
        .at(-1);
      cloneForm.picBusinessLicense = this.form.picBusinessLicense
        ?.split('/')
        .at(-1);
      cloneForm.picLicenceOpeningAccounts = this.form.picLicenceOpeningAccounts
        ?.split('/')
        .at(-1);
      cloneForm.viGuaranteeSlipImg = this.form.viGuaranteeSlipImg
        ?.split('/')
        .at(-1);
      cloneForm.picCertificateOther = this.form.picCertificateOther
        ?.split('/')
        .at(-1);

      cloneForm.companyId = this.$store?.state?.user?.userInfo?.companyId; //建档门店ID
      // 处理图片
      cloneForm.picOther = cloneForm.picOther
        ?.split(',')
        .map((item) => item.split('/').at(-1))
        .join();
      cloneForm.clientBusinessMessageDto = this.clientBusinessMessageDto;
      console.log('32323232', !cloneForm.otherCertificateType);
      //判断有证件号的时候必须有证件类型
      // if (cloneForm.otherCertificateNum && !cloneForm.otherCertificateType) {
      //   return this.$message.warning('存在证件号必须选择证件类型');
      // }
      if (
        cloneForm.otherCertificateNum &&
        (cloneForm.otherCertificateType === undefined ||
          cloneForm.otherCertificateType === '')
      ) {
        return this.$message.warning('存在证件号必须选择证件类型');
      }
      //判断社会信用代码或者身份证号是否满18位
      console.log('cloneForm', cloneForm);
      var numlength = this.$lodash.cloneDeep(cloneForm.idNum);
      if (numlength != undefined && numlength.length != 0) {
        if (numlength.toString().length != 18) {
          return this.$message.warning('社会信用代码不满18位');
        }
      }
      // 保存
      if (this.id) {
        // 判断是否修改过图片
        let newImgString =
          String(this.form.picCertificateOther) + String(this.form.picOther);
        cloneForm.isEditPicState = newImgString === this.oldImgString ? 0 : 1;
        // 下面加的是移除其他联系人的字段名
        cloneForm.removeContactIds = this.removeContactIds.toString();
        cloneForm.clientId = this.id;
        cloneForm.companyId = this.$store?.state?.user?.userInfo?.companyId;
        console.log('cloneForm', cloneForm);
        updateCustomer(cloneForm).then((res) => {
          if (res.code === 200) {
            this.oldImgString = newImgString;
            this.$message.success('保存成功');
            this.$emit('update');
            this.$emit('update:showAddCusterDialog', false);
            this.$emit('custerInforma', res.data);
          }
        });
      }
      // 新增
      else {
        cloneForm.otherContacts = this.otherContacts;
        insertCustomer(cloneForm).then((res) => {
          if (res.code === 200) {
            this.$message.success('保存成功');
            this.$emit('update');
            this.$emit('update:showAddCusterDialog', false);
            this.$emit('custerInforma', res.data);
          } else {
            this.$confirm('该客户简称已存在，请重新输入', '提示', {
              confirmButtonText: '确定',
            })
              .then(() => {})
              .catch(() => {});
          }
        });
      }
    },
    handleClose() {
      this.$emit('update:showAddCusterDialog', false);
    },

    /**
     * 远程搜索客户列表
     */
    clientKeyWordChange(keyword) {
      if (keyword) {
        this.searchClient(keyword);
      }
    },
    searchClient(keyWord = '') {
      this.searchClientLoading = true;
      searchSourceClient(keyWord)
        .then((res) => {
          this.custlist = res.records;
        })
        .finally(() => {
          this.searchClientLoading = false;
        });
    },
    /**
     * 选择客户
     */
    selectClient(clientId) {
      if (clientId) {
        const client = this.custlist.find((item) => item.id === clientId);
        this.clientBriefMessageVo = client;
        this.form.clientBriefMessageVo = client;
      }
    },

    /**
     * 删除客户
     */
    deleteClient() {
      this.clientBriefMessageVo = {};
      this.form.clientId = '';
      this.form.clientBriefMessageVo = {};
    },

    //限制手机输入框只能输入数字事件
    // onlyDigit(value, prop) {
    //   this.form[prop] = value.replace(/\D/g, '');
    // },
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style: none;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.tipsWord {
  color: #999999;
  font-size: 14px;
}
::v-deep.el-form-item--small .el-form-item__label {
  color: #242526;
}
// 上传图片的样式
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d7dbe0;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.add_more {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 25px;
  color: #242526;
}
.add_more div:nth-child(2) {
  color: $blue;
  &:hover {
    cursor: pointer;
  }
}
.add_lies {
  display: flex;
  width: 95%;
  margin-left: 2.5%;
  height: 135px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 15px;
  .other-form {
    background: #f5f7fa;
    width: 95%;
    padding: 16px 10px;
    border-radius: 4px;
  }
}
.client-list {
  .client {
    display: flex;
    align-items: center;
    > .icon_delete {
      font-size: 18px;
      color: #ff9200;
      margin-right: 15px;
    }
    .client-info {
      > div + div {
        margin-top: 10px;
      }
      i {
        margin: 0 5px;
      }
      .icon_person {
        color: $blue;
      }
      .icon_sex {
        color: $blue;
      }
      .icon_grade {
        color: $blue;
      }
      .grow-icon_girl {
        color: #f48080;
      }
      .icon_wechat {
        color: #999;
      }
      .icon_wechat_active {
        color: #00c800;
      }
      .icon_phone {
        color: #42bbb8;
      }
      .icon_vip {
        color: $orange;
      }
      .icon_sex,
      .icon_grade,
      .icon_wechat,
      .icon_wechat_active,
      .icon_vip {
        font-size: 14px;
      }
    }
  }
}
.custer-imgs {
  display: flex;
}

.kt {
  .kt-span {
    display: flex;
    justify-content: flex-end;
  }
  ::v-deep .el-form-item__label {
    padding: 0px !important;
  }
  ::v-deep .el-select {
    width: 100% !important;
    display: flex;
    flex: 1;
  }
}
</style>
