<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div class="form-top">
        <el-form :model="form" label-width="100px" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item>
                <template slot="label">
                  <div>
                    操作门店
                    <el-tooltip
                      content="接车单的操作门店,要么只查当前登录门店,要么只查登录用户所属管辖组内门店(登录用户有所属管辖组时)"
                      effect="light"
                      placement="bottom-start">
                      <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
                    </el-tooltip>
                  </div>
                </template>
                <operateCompanyCascader v-model="form.operateCompanyId"></operateCompanyCascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="待收金额">
                <el-select v-model="form.unclearedDebt" placeholder="请选择">
                  <el-option v-for="item in unclearedDebt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="挂账日期">
                <el-date-picker
                  v-model="form.times"
                  :clearable="false"
                  class="w100"
                  end-placeholder="截止时间"
                  range-separator="至"
                  start-placeholder="起始时间"
                  type="daterange"
                  unlink-panels
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <div class="el-form-item--small" style="margin-left: 20%">
                <div class="el-form-item__label pointer" @click="showMore = !showMore">
                  更多筛选
                  <i
                    :class="{
                      primary: true,
                      'el-icon-arrow-right': !showMore,
                      'el-icon-arrow-down': showMore,
                    }"></i>
                </div>
                <div class="flex">
                  <el-button class="btn_search" size="small" type="primary" @click="getList()">查询</el-button>
                  <el-button class="btn_search" size="small" @click="empty">置空</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="消费客户">
                <div class="last-type-sty">
                  <el-select v-model="form.consumerType" clearable placeholder="请选择">
                    <el-option v-for="item in customerList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-input v-model="form.clientId" clearable placeholder="请输入客户名称" @keyup.enter.native="getList"></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <template slot="label">
                  <div class="flex flex-x-center">
                    开票主体
                    <el-tooltip effect="light" placement="bottom-start">
                      <div slot="content" style="color: #666666; font-size: 13px; line-height: 20px; text-indent: 15px">
                        开票主体，即为协议客户、保险公司和三包厂家的应收账款
                        <br />
                        的回款管理门店(保险公司的开票主体也是定损主体)。保险公司
                        <br />
                        管理的开票主体，可查已成为保险公司开票 主体的全部门店。
                      </div>
                      <i class="iconfont grow-icon_doubt" style="color: #9a9a9a"></i>
                    </el-tooltip>
                  </div>
                </template>

                <el-select
                  v-model="form.invoiceCompanyId"
                  :loading="searchinvoiceLoading"
                  :remote-method="searchInvoice"
                  clearable
                  filterable
                  placeholder="搜索开票主体名称"
                  remote
                  @change="selectInvoice"
                  @clear="deleteInvoice">
                  <el-option
                    v-for="item in invoiceList"
                    :key="item.companyId"
                    :label="item.invoicingEntity"
                    :value="item.companyId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 分割线 -->
      <el-divider v-if="showMore"></el-divider>
      <transition name="fade">
        <div v-show="showMore">
          <el-form :model="form" label-width="100px" size="small">
            <el-row>
              <el-col :span="6">
                <el-form-item label="车牌号">
                  <el-input v-model="form.plateNumber" clearable placeholder="请输入" @keyup.enter.native="getList"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="接车单号">
                  <el-input v-model="form.billCode" clearable placeholder="请输入" @keyup.enter.native="getList"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="接车进店日期">
                  <el-date-picker
                    v-model="form.intoTime"
                    class="w100"
                    end-placeholder="截止时间"
                    range-separator="至"
                    start-placeholder="起始时间"
                    type="daterange"
                    unlink-panels
                    value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="事故责任">
                  <el-select v-model="form.dutyType" clearable filterable placeholder="搜索事故责任">
                    <el-option v-for="item in dutyTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="结账收款备注">
                  <el-input v-model="form.remark" clearable placeholder="请输入" @keyup.enter.native="getList"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="开票单据号">
                  <el-input v-model="form.invoiceNum" clearable placeholder="请输入开发票的单据号" @keyup.enter.native="getList"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="业务来源">
                  <el-select
                    v-model="form.sourceId"
                    :loading="searchBillSourceLoading"
                    :remote-method="searchBillSource"
                    clearable
                    filterable
                    placeholder="搜索业务来源"
                    remote
                    @change="selectBillSource"
                    @clear="deleteBillSource">
                    <el-option
                      v-for="item in billSourceList"
                      :key="item.billSourceTypeId"
                      :label="item.name"
                      :value="item.billSourceTypeId"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </transition>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div class="content-container">
        <div class="btns flex-x-between">
          <div>
            <div class="custom-tabs">
              <div v-auth="162" class="collect-btn pointer" @click="collection">
                收款
                <span v-if="selectedData.length > 0">（{{ selectedData.length }}）</span>
              </div>
              <div
                :class="{
                  'custom-tab-item': true,
                  active: status == -1,
                }"
                @click="status = -1">
                全部
              </div>
              <div
                :class="{
                  'custom-tab-item': true,
                  active: status == 0,
                }"
                @click="status = 0">
                自费业务
              </div>
              <div
                :class="{
                  'custom-tab-item': true,
                  active: status == 1,
                }"
                @click="status = 1">
                包干业务
              </div>
              <div
                :class="{
                  'custom-tab-item': true,
                  active: status == 2,
                }"
                @click="status = 2">
                理赔业务
              </div>
              <div
                :class="{
                  'custom-tab-item': true,
                  active: status == 3,
                }"
                @click="status = 3">
                索赔业务
              </div>
            </div>
          </div>
          <div>
            <el-button v-auth="707" class="blue" size="small" style="margin-left: 10px" type="text" @click="importDialogVisibility = true">
              导入核销
              <i class="iconfont grow-icon_daoru el-icon--right"></i>
            </el-button>
            <el-button v-auth="332" class="blue" size="small" style="margin-left: 10px" type="text" @click="showExportDialog = true">
              导出列表
              <i class="iconfont grow-icon_daochu el-icon--right"></i>
            </el-button>
          </div>
        </div>

        <div class="flex" style="flex: 1; overflow: auto">
          <div style="width: 85%; flex: 1; overflow: auto">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :select-on-indeterminate="false"
              :summary-method="getSummaries"
              border
              height="100%"
              show-summary
              @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="105"></el-table-column>
              <el-table-column label="序号" type="index" width="60"></el-table-column>
              <el-table-column label="操作门店" prop="operateCompanyName" width="100"></el-table-column>
              <el-table-column label="接车单号" min-width="200">
                <template slot-scope="scope">
                  <p class="blue pointer" @click="goDetail(scope.row.billId)">
                    {{ scope.row.billCode }}
                  </p>
                  <div v-if="scope.row.billType !== 0">
                    {{ scope.row.billType | dict("carBillType") }}
                  </div>
                  <div v-else>
                    {{ scope.row.isOthersSelfPayState | dict("isOthersSelfPayState") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="结账(挂账）时间" min-width="180" prop="checkDate"></el-table-column>
              <el-table-column label="进店时间" min-width="180" prop="dateCreated"></el-table-column>
              <el-table-column label="车牌号" min-width="180" prop="carNumber"></el-table-column>
              <el-table-column label="签约客户" min-width="150">
                <template #default="{ row }">
                  <div v-if="row.client">
                    <p>{{ row.client.clientName }}</p>
                    <p class="color999">{{ row.client.clientPhone }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="消费客户" min-width="120">
                <template #default="{ row }">
                  <div v-if="row.consumer">
                    <p>{{ row.consumer.consumerName }}</p>
                    <p class="color999">{{ row.consumer.consumerPhone }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="开票主体" min-width="100">
                <template #default="{ row }">
                  {{
                    row.invoiceName
                      .map(i => {
                        return i;
                      })
                      .join()
                  }}
                </template>
              </el-table-column>
              <el-table-column label="开票单据号" min-width="100" prop="invoiceNum"></el-table-column>
              <el-table-column label="业务来源" min-width="100" prop="sourceOfBusiness"></el-table-column>
              <el-table-column label="事故责任" min-width="100">
                <template v-if="status != 3" #default="{ row }">
                  {{ row.dutyType | dict("dutyType") }}
                </template>
              </el-table-column>
              <el-table-column label="单据金额" min-width="100" prop="paid"></el-table-column>
              <el-table-column min-width="200" prop="contractTotal">
                <template slot="header">
                  <div class="flex">
                    挂账(包干）总额
                    <el-tooltip effect="light" placement="bottom-start">
                      <div slot="content" style="color: #666666; font-size: 13px; line-height: 16px; text-indent: 15px">
                        包干业务的挂账金额为包干分摊金额，
                        <br />
                        结账当月显示为查询时点的预分摊金额，
                        <br />
                        结账次月显示为实际分摊金额
                      </div>
                      <i class="iconfont grow-icon_doubt" style="margin-left: 5px"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="已收金额" min-width="100" prop="alreadyPaid"></el-table-column>
              <el-table-column label="待收金额" min-width="180" prop="unclearedDebt"></el-table-column>
              <el-table-column label="挂账总额" min-width="180" prop="unclearedDebtFixed"></el-table-column>
              <el-table-column label="结账收款备注" min-width="200" prop="remark"></el-table-column>
            </el-table>
          </div>
          <div style="width: 15%; padding-left: 30px; box-sizing: border-box">
            <el-table :data="tableData2" style="width: 100%">
              <el-table-column label="消费客户与统计（客户数）" prop="filder14"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- 核销导入弹窗 -->
      <import-dialog
        v-if="importDialogVisibility"
        :getLastResultApi="batchImportWriteOffResultApi"
        :importFailDetail="batchImportWriteOffFailResultExportUrlApi"
        :showTip="false"
        :uploadFileApi="batchImportWriteOffApi"
        :visibility.sync="importDialogVisibility"
        templateFileUrl="https://growxny-dev-center.oss-cn-guangzhou.aliyuncs.com/template/20240731/%E6%94%B6%E6%AC%BE%E9%94%80%E8%B4%A6%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
        title="批量核销导入"></import-dialog>
      <export-dialog
        :filterForm="form"
        :pageOption="pageOption"
        :total="total"
        :url="exportUrl"
        :visibility.sync="showExportDialog"></export-dialog>
      <template slot="footer">
        <pagination :total="total" @pageChange="changePage"></pagination>
      </template>
    </template>
  </base-container>
</template>

<script>
import pagination from "@/components/Pagination.vue";
import importDialog from "@/components/businessComponent/importDialog.vue";
import ExportDialog from "@/components/ExportDialog";
//接口
import { searchInvoice } from "@/api/storeManagement/index";
import {
  batchImportWriteOff,
  batchImportWriteOffFailResultExportUrl,
  batchImportWriteOffResult,
  exportDetailOnAccountListUrl,
  getDetailOnAccountList,
} from "@/api/financial/financialOver";
import { getBillSourceTypeList } from "@/api/billSource";
import operateCompanyCascader from "@/components/businessComponent/operateCompanyCascader.vue";
// 工具
import { mapState } from "vuex";
import bus from "@/service/bus";

export default {
  name: "receivableDetailsSecond",
  components: {
    pagination,
    importDialog,
    ExportDialog,
    operateCompanyCascader,
  },
  props: {
    forms: {
      type: Object,
      default: () => ({}),
    },
    times: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        consumerType: "",
        invoiceCompanyId: "",
        times: [],
      },
      filterForm: {},
      //导出的页面设置
      exportUrl: exportDetailOnAccountListUrl,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      batchImportWriteOffApi: batchImportWriteOff,
      batchImportWriteOffResultApi: batchImportWriteOffResult,
      batchImportWriteOffFailResultExportUrlApi: batchImportWriteOffFailResultExportUrl,
      importDialogVisibility: false,
      showExportDialog: false,
      status: -1,
      tableData: [],
      showMore: false,
      customers: [],
      tableData2: [],
      //搜索开票主体门店字段
      searchinvoiceLoading: false,
      invoiceList: [],
      //待收金额字段
      unclearedDebt: [
        {
          value: 0,
          label: "大于0",
        },
        {
          value: 1,
          label: "等于0",
        },
      ],
      //消费客户字段
      customerList: [
        {
          value: 0,
          label: "自费",
        },
        {
          value: 1,
          label: "包干",
        },
        {
          value: 2,
          label: "保险公司",
        },
        {
          value: 3,
          label: "三包公司",
        },
      ],
      //事故责任字段
      dutyTypeList: [
        {
          value: 0,
          label: "全责",
        },
        {
          value: 1,
          label: "无责",
        },
        {
          value: 2,
          label: "主责",
        },
        {
          value: 3,
          label: "次责",
        },
        {
          value: 4,
          label: "同责",
        },
      ],
      //业务来源字段
      searchBillSourceLoading: false,
      billSourceList: [],

      //选中数据
      selectedData: [],
    };
  },
  watch: {
    status() {
      this.getList();
    },
  },
  computed: {
    ...mapState({
      companyId: state => state.user.userInfo.companyId,
    }),
  },
  activated() {
    //2022-11-17  需求，别的页面跳转过来带的参数
    if (this.$route.params.row) {
      this.form.companyId = this.$route.params.row.companyId ?? "";
      this.form.invoiceCompanyId = this.$route.params.row.invoiceCompanyId ?? "";
      this.form.consumerType = this.$route.params.row.consumerType ?? "";
      if (this.$route.params.times) {
        this.$set(this.form, "times", this.$route.params.times);
      } else {
        this.pickTimes();
      }
      this.getList();
    }
  },
  created() {
    this.form.operateCompanyId = [this.companyId];
    bus.$on("refreshData", this.getList);
    this.searchInvoice();
    // this.searchBillSource();
    //处理传值过来的数据
    // if (Object.keys(this.forms).length) {     2022-11-17 注释修改页面调整
    //   this.form.companyId = this.forms.companyId ?? '';
    //   this.form.invoiceCompanyId = this.forms.invoiceCompanyId ?? '';
    //   this.form.consumerType = this.forms.consumerType ?? '';
    //   // this.form.clientId =
    //   //   this.forms.consumer.name || this.forms.consumer.phone;
    // }
    // if (this.times.length != 0) {
    //   this.$set(this.form, 'times', this.times);
    // } else {
    //   this.pickTimes();
    // }
    // this.getList();
    //2022-11-17  不刷新页面
    if (!this.$route.params.row) {
      this.pickTimes();
      this.getList();
    }
  },
  mounted() {},
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <el-checkbox vOn:change={this.selectAll}>列表全选</el-checkbox>;
          return;
        }
        if (index === 1) {
          sums[index] = "合计";
          return;
        }
        if (["billPaid", "contractTotal", "alreadyPaid", "unclearedDebt"].includes(column.property)) {
          sums[index] = data
            .reduce((prev, curr) => {
              const value = Number(this.$lodash.get(curr, column.property, 0));
              return prev + value;
            }, 0)
            .toFixed(2);
          sums[index] += " ";
        }
      });

      return sums;
    },
    selectAll(a) {
      if (a == true) {
        this.$refs.multipleTable.toggleAllSelection();
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    /**
     * 重置筛选表单
     */
    resetFilterForm() {
      this.filterForm = {};
    },
    empty() {
      this.form = {};
      this.form.operateCompanyId = [];
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      this.getList(false);
    },
    //表格选中项
    handleSelectionChange(selectedData) {
      this.selectedData = selectedData;
    },
    //远程搜索开票主体门店列表
    searchInvoice(keyWord = "") {
      this.searchinvoiceLoading = true;
      searchInvoice(keyWord, this.companyId)
        .then(res => {
          this.invoiceList = res;
        })
        .finally(() => {
          this.searchinvoiceLoading = false;
        });
    },
    // 选择开票主体门店
    selectInvoice(invoiceId) {},
    //删除开票主体门店
    deleteInvoice() {},
    //远程搜索业务来源列表
    searchBillSource(keyWord = "") {
      this.searchBillSourceLoading = true;
      getBillSourceTypeList({ name: keyWord })
        .then(res => {
          this.billSourceList = res.records;
        })
        .finally(() => {
          this.searchBillSourceLoading = false;
        });
    },
    // 选择业务来源
    selectBillSource(billSourceId) {},
    //删除业务来源
    deleteBillSource() {},
    // 补零函数
    doHandleZero(zero) {
      var date = zero;
      if (zero.toString().length == 1) {
        date = "0" + zero;
      }
      return date;
    },
    //设置查询日期的默认时间
    pickTimes() {
      var tYear = new Date().getFullYear();
      var tMonth = new Date().getMonth();
      tMonth = tYear + "-" + this.doHandleZero(tMonth + 1) + "-" + "01";
      this.form.times = [tMonth, new Date().Format("yyyy-MM-dd")];
    },
    //获取数据列表
    getList(reset = true) {
      if (reset) {
        this.pageOption.current = 1;
        this.total = 0;
      }
      var allTimes = {
        startDate: this.form.times?.[0] ? this.form.times?.[0] + " 00:00:00" : "",
        endDate: this.form.times?.[1] ? this.form.times?.[1] + " 23:59:59" : "",
        intoStartDate: this.form.intoTime?.[0] ? this.form.intoTime?.[0] + " 00:00:00" : "",
        intoEndDate: this.form.intoTime?.[1] ? this.form.intoTime?.[1] + " 23:59:59" : "",
        billType: this.status == -1 ? "" : this.status,
        operateCompanyId: this.form.operateCompanyId,
      };
      var data = Object.assign(this.form, allTimes, this.pageOption);
      getDetailOnAccountList(data).then(res => {
        console.log("res", res.records);
        // 修改总条数
        this.total = res.total;
        this.tableData = res.records;
      });
    },

    /**
     * 跳转收款销账页面
     */
    collection() {
      if (this.selectedData.length == 0) {
        this.$message({
          type: "warning",
          message: "请先选择要收款的数据",
        });
      } else {
        let first = this.selectedData[0];
        //判断选中的每一项的客户电话或者是开票主体是否是相同的
        let isHasSame = this.selectedData.every(
          item => item.client.clientPhone === first.client.clientPhone && item.invoiceName === item.invoiceName,
        );
        console.log("item", this.selectedData);
        if (isHasSame) {
          this.$router.push({
            name: "collectionChargeOffs",
            params: {
              tableData: this.selectedData,
              times: this.form.times,
            },
          });
        } else {
          this.$message({
            type: "warning",
            message: "客户/开票主体不一致",
          });
        }
      }
    },
    /**
     * 跳转详情页
     */
    goDetail(id) {
      this.$router.push({
        name: "carBillDetail",
        query: {
          id,
        },
      });
    },
  },
  beforeDestroy() {
    bus.$off("refreshData", this.getList);
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 30px 30px 0;
  box-sizing: border-box;
}

//设置表格中合计行颜色
::v-deep .el-table__footer-wrapper tbody td.el-table__cell {
  color: $orange !important;
}

.content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}

.custom-tabs {
  display: flex;

  .collect-btn {
    width: 101px;
    height: 32px;
    background: $orange;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
    margin: 5px 29px 0px 0px;
    font-size: 14px;
    border-radius: 4px;
  }
}

.last-type-sty {
  display: flex;

  .el-input {
    margin-left: 20px;
  }
}

//导出列表样式
.export-dialog__body {
  margin: 20px 0;
  text-align: center;

  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }

  .export-dialog__tip {
    margin-top: 20px;
  }
}
</style>
