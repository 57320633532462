<template>
  <div class="container">
    <div class="content">
      <div
        class="flex index-path"
        v-if="localHasAuth(744) || localHasAuth(745)"
      >
        <div class="path-item" v-auth="744">
          <div class="flex">
            <el-button
              class="editCar"
              @click="gotoNewSysPage(1007, 'miniProgramBookingRecordList', {})"
            >
              {{ overviewData.todayBookingWaitCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>当天预约待处理</p>
          </div>
        </div>
        <div class="path-item" v-auth="745">
          <div class="flex">
            <el-button
              class="editCar"
              @click="
                gotoNewSysPage(1013, 'miniProgramBookingNumberTakeList', {})
              "
            >
              {{ overviewData.takeNumberWaitCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>当前排号数</p>
          </div>
        </div>
      </div>
      <div class="flex index-path" v-if="localHasAuth(746) || localHasAuth(747) || localHasAuth(748) || localHasAuth(749) || localHasAuth(750) || localHasAuth(751)">
        <div class="path-item" v-auth="746">
          <div class="flex">
            <el-button
              class="editCar"
              @click="gotoNewSysPage(35, 'carBillList', { billState: 48 })"
            >
              {{ overviewData.notDetectionBillCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>未检车</p>
          </div>
        </div>
        <div class="path-item" v-auth="747">
          <div class="flex">
            <el-button
              class="editCar"
              @click="gotoNewSysPage(35, 'carBillList', { billState: 49 })"
            >
              {{ overviewData.notQuoteBillCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>未报价</p>
          </div>
        </div>
        <div class="path-item" v-auth="748">
          <div>
            <el-button
              class="editCar"
              @click="gotoNewSysPage(35, 'carBillList', { billState: 50 })"
            >
              {{ overviewData.notQuoteApproveBillCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>未审核</p>
          </div>
        </div>
        <div class="path-item" v-auth="749">
          <div>
            <el-button
              class="editCar"
              @click="gotoNewSysPage(35, 'carBillList', { billState: 51 })"
            >
              {{ overviewData.notDispatchBillCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>未提交施工</p>
          </div>
        </div>
        <div class="path-item" v-auth="750">
          <div>
            <el-button
              class="editCar"
              @click="
                gotoNewSysPage(
                  94,
                  'constructionBill',
                  { billState: 47 },
                  { dispatchType: 4 }
                )
              "
            >
              {{ overviewData.notBeginTaskCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>待施工</p>
          </div>
        </div>
        <div class="path-item" v-auth="751">
          <div>
            <el-button
              class="editCar"
              @click="
                gotoNewSysPage(
                  94,
                  'constructionBill',
                  { billState: 47 },
                  { dispatchType: 0 }
                )
              "
            >
              {{ overviewData.beginAndDoNotDoneTaskCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>施工中未完工</p>
          </div>
        </div>
      </div>
      <div class="flex index-path" v-if="localHasAuth(752) || localHasAuth(753) || localHasAuth(754) || localHasAuth(755)">
        <div class="path-item" v-auth="752">
          <div>
            <el-button class="editCar" @click="goOldPage('waitPicking')">
              {{ overviewData.waitPickingCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>待领料出库</p>
          </div>
        </div>
        <div class="path-item" v-auth="753">
          <div>
            <el-button class="editCar" @click="goOldPage('waitPurchase')">
              {{ overviewData.waitPurchaseBillCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>待采购</p>
          </div>
        </div>
        <div class="path-item" v-auth="754">
          <div class="flex">
            <el-button
              class="editCar"
              @click="goOldPage('purchaseWaitInInventory')"
            >
              {{ overviewData.purchaseBillWaitInInventoryCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>采购待入库</p>
          </div>
        </div>
        <div class="path-item" v-auth="755">
          <div class="flex">
            <el-button
              class="editCar"
              @click="gotoNewSysPage(1018, 'waitReturnInInventory', {}, {})"
            >
              {{ overviewData.waitReturnInInventoryCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>待退料入库</p>
          </div>
        </div>
      </div>
      <div class="flex index-path" v-if="localHasAuth(756) || localHasAuth(757)">
        <div class="path-item" v-auth="756">
          <div>
            <el-button
              class="editCar"
              @click="gotoNewSysPage(35, 'carBillList', { billState: 46 })"
            >
              {{ overviewData.taskCompleteAndNotCheckBillCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>已完工未结账</p>
          </div>
        </div>
        <div class="path-item" v-auth="757">
          <div>
            <el-button
              class="editCar"
              @click="gotoNewSysPage(35, 'carBillList', { billState: 47 })"
            >
              {{ overviewData.checkAndNotTakeCarBillCount }}
            </el-button>
          </div>
          <div class="path-tip">
            <p>已结账未提车</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hasAuth } from '@/utils/permissions';
import { overview } from '@/api/system';

//旧系统请求前缀
import { admin2PagePrefix } from '@/config/compatibilityOlderSystems';

export default {
  name: 'taskOverview',
  components: {},
  data() {
    return {
      overviewData: {
        todayBookingWaitCount: 0,
        takeNumberWaitCount: 0,
        notDetectionBillCount: 0,
        notQuoteBillCount: 0,
        notQuoteApproveBillCount: 0,
        notDispatchBillCount: 0,
        notBeginTaskCount: 0,
        beginAndDoNotDoneTaskCount: 0,
        waitPickingCount: 0,
        waitPurchaseBillCount: 0,
        purchaseBillWaitInInventoryCount: 0,
        waitReturnInInventoryCount: 0,
        taskCompleteAndNotCheckBillCount: 0,
        checkAndNotTakeCarBillCount: 0,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getData();
    },
    getData() {
      const _this = this;
      // 根据权限查询数据
      if (hasAuth(744)) {
        overview({ dataType: 'todayBookingWaitCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }
      if (hasAuth(745)) {
        overview({ dataType: 'takeNumberWaitCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }

      if (hasAuth(746)) {
        overview({ dataType: 'notDetectionBillCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }
      if (hasAuth(747)) {
        overview({ dataType: 'notQuoteBillCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }
      if (hasAuth(748)) {
        overview({ dataType: 'notQuoteApproveBillCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }
      if (hasAuth(749)) {
        overview({ dataType: 'notDispatchBillCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }
      if (hasAuth(750)) {
        overview({ dataType: 'notBeginTaskCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }
      if (hasAuth(751)) {
        overview({ dataType: 'beginAndDoNotDoneTaskCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }

      if (hasAuth(752)) {
        overview({ dataType: 'waitPickingCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }
      if (hasAuth(753)) {
        overview({ dataType: 'waitPurchaseBillCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }
      if (hasAuth(754)) {
        overview({ dataType: 'purchaseBillWaitInInventoryCount' }).then(
          (res) => {
            _this.overviewData = Object.assign(_this.overviewData, res || {});
          }
        );
      }
      if (hasAuth(755)) {
        overview({ dataType: 'waitReturnInInventoryCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }

      if (hasAuth(756)) {
        overview({ dataType: 'taskCompleteAndNotCheckBillCount' }).then(
          (res) => {
            _this.overviewData = Object.assign(_this.overviewData, res || {});
          }
        );
      }
      if (hasAuth(757)) {
        overview({ dataType: 'checkAndNotTakeCarBillCount' }).then((res) => {
          _this.overviewData = Object.assign(_this.overviewData, res || {});
        });
      }
    },
    localHasAuth(code) {
      return hasAuth(code);
    },
    gotoNewSysPage(authCode, menuName, otherParams, paramsObj = {}) {
      if (!hasAuth(authCode)) {
        this.$message.warning('没有权限访问');
        return;
      }
      const queryObj = Object.assign({}, otherParams, {
        t: new Date().getTime(),
        sourcePage: 'overview',
      });
      this.$router.push({
        name: menuName,
        query: queryObj,
        params: paramsObj,
      });
    },
    //跳转老系统页面
    goOldPage(val) {
      //跳转到旧系统维修领料页面
      if (val === 'waitPicking') {
        let path = `${admin2PagePrefix}#/stock/outOfStock/repairPicking?keepRoute=true&sourcePage=overview`;
        window.open(path);
      } else if (val === 'waitPurchase') {
        let path = `${admin2PagePrefix}#/stock/purchaseWait?keepRoute=true&sourcePage=overview`;
        window.open(path);
      } else if (val === 'purchaseWaitInInventory') {
        let path = `${admin2PagePrefix}#/stock/purchaseList?keepRoute=true&sourcePage=overview&purchaseBillState=0`;
        window.open(path);
      } else if (val === 'waitReturnInInventory') {
        this.$message.warning('功能还在建设中，无法跳转');
        //let path = `${admin2PagePrefix}#/stock/query/inventorySummary?keepRoute=true`;
        //window.open(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//流程样式
.index-path {
  margin-bottom: 20px;
  overflow: hidden;
  .path-item {
    width: 120px;
    float: left;
    text-align: center;
    button {
      text-align: center !important;
      width: 100px;
      height: 100px;
      margin: 0;
      text-align: inherit;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #303133;
    }
    .flex {
      display: block;
    }
    .editCar {
      border-radius: 50px;
      width: 100px;
      height: 100px;
      cursor: pointer;
      & span {
        cursor: pointer;
      }
      &:hover {
        color: #303133;
        border: 1px solid #33ab79;
        background: #fff !important;
      }
    }
  }
  .path-arr {
    flex-grow: 1;
    height: 1px;
    margin: 22px 5px;
    position: relative;
    background: #ccc;
    &:after {
      content: '';
      position: absolute;
      right: -3px;
      top: -4px;
      transform: rotate(91deg);
      width: 0;
      height: 0;
      border-bottom: 10px solid #cccccc;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
  }
  .path-tip {
    p {
      margin-top: 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      text-align: center;
    }
  }
  .path-tip-left {
    margin-left: 5.4%;
  }
}
.container {
  width: 832px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  .avatar {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      border: 3px solid #f0f2f5;
      border-radius: 50%;
      object-fit: contain;
      overflow: hidden;
    }
  }
  .info {
    flex: 1;
    margin-left: 20px;
    .el-row {
      font-size: 14px;
      font-weight: 400;
      .user-name {
        margin-right: 13px;
        font-size: 16px;
        font-weight: 500;
      }
      & + .el-row {
        margin-top: 12px;
      }
      .el-col:nth-child(1) {
        text-align: right;
      }
      span {
        font-size: 14px;
      }
    }
  }
}
</style>
