<template>
  <el-dialog
    :title="title"
    :visible="visibility"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      size="small"
      :model="editForm"
      :rules="rules"
      label-width="110px"
      style="margin-top: 18px"
    >
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="解决方案" prop="solutionType">
            <el-select
              v-model="editForm.solutionType"
              clearable
              filterable
              placeholder="请选择解决方案"
            >
              <el-option
                v-for="item in solutionTypeEnumList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="卡点责任人" prop="stuckPointResponsibleUserName">
            <el-input
              v-model="editForm.stuckPointResponsibleUserName"
              maxlength="200"
              placeholder="请输入卡点责任人"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="清单责任人" prop="detailListResponsibleUserName">
            <el-input
              v-model="editForm.detailListResponsibleUserName"
              maxlength="200"
              placeholder="请输入清单责任人"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12" :offset="0">
          <el-form-item label="完成时间" prop="strandedCompletionTime">
            <el-date-picker
              class="w100"
              type="datetime"
              v-model="editForm.strandedCompletionTime"
              placeholder="请选择完成时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              @input="dateRangeChange()"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24" :offset="0">
          <el-form-item label="滞留原因" prop="strandedReason">
            <el-input
              v-model="editForm.strandedReason"
              type="textarea"
              :rows="4"
              show-word-limit
              maxlength="100"
              placeholder="请输入滞留原因"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button size="small" type="primary" @click="save"> 确 定 </el-button>
      <el-button size="small" @click="handleClose"> 取 消 </el-button>
    </template>
  </el-dialog>
</template>

<script>
// 接口
import { saveOrUpdateCarStrandedSolution } from '@/api/carBill/carWreckedAndStranded';

export default {
  name: 'editCarStrandedDailyStatDetailDialog',
  components: {},
  props: {
    visibility: Boolean,
    id: [Number, String],
    rowEntityData: [Object],
    solutionTypeEnumList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 表单信息
      editForm: {},
      // 表单规则
      rules: {
        solutionType: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择解决方案',
          },
        ],
        stuckPointResponsibleUserName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入卡点责任人',
          },
        ],
        detailListResponsibleUserName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入清单责任人',
          },
        ],
        strandedCompletionTime: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择完成时间',
          },
        ],
        strandedReason: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入滞留原因',
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return '编辑滞留解决方案';
    },
  },
  created() {
    this.init();
  },
  methods: {
    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
    init() {
      this.editForm = this.rowEntityData;
      if (this.rowEntityData.solutionType !== undefined) {
        this.editForm.solutionType = this.rowEntityData.solutionType + '';
        this.$set(
          this.editForm,
          'solutionType',
          this.rowEntityData.solutionType + ''
        );
      } else {
        this.$set(this.editForm, 'solutionType', '');
      }
    },
    /**
     * 保存
     */
    save() {
      this.$refs.form
        .validate()
        .then((result, object) => {
          var body = Object.assign({}, this.editForm, {
            billId: this.rowEntityData.billId,
          });
          saveOrUpdateCarStrandedSolution(body).then((res) => {
            this.$message.success('保存成功！');
            this.$emit('save');
            this.handleClose();
          });
        })
        .catch((error) => {
          console.log(error);
          var msg =
            '<p>' +
            Object.values(error)
              .map((el) => el.map((v) => v.message))
              .join('<br />') +
            '</p>';
          this.$message({
            type: 'warning',
            dangerouslyUseHTMLString: true,
            message: msg,
          });
        });
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit('update:visibility', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    width: 100px;
  }
  .el-form-item__content {
    margin-left: 100px;
  }
}
.selected-car-model-list {
  margin: 6px 0 12px 0;
  .el-tag {
    margin-top: 6px;
    margin-left: 6px;
  }
}
</style>
