<template>
  <el-dialog :before-close="handleClose" :close-on-click-modal="false" :title="title" :visible="visibility" width="50%">
    <div class="container">
      <div v-if="showTip" class="tip color626">
        <!-- 提示语插槽 -->
        <slot></slot>
        <h3 class="warning">为保证导入速率，请于19:00——次日7:00期间进行导入</h3>
      </div>
      <div class="step-list">
        <div class="step">
          <div class="flex-y-center">
            <div class="index flex-center">1</div>
            <div class="title">第一步: 下载模板文件</div>
          </div>
          <div class="content">
            <a v-if="templateFileUrl" :href="templateFileUrl" class="blue pointer" download style="text-decoration: none; font-size: 14px">
              下载模板
              <i class="iconfont grow-icon_download"></i>
            </a>
          </div>
        </div>
        <div class="step">
          <div class="flex-y-center">
            <div class="index flex-center">2</div>
            <div class="title">第二步：选择Excel文件</div>
          </div>
          <div class="content">
            <el-button :disabled="internalDisabled" class="btn-choice" type="primary" @click="chooseFile">选择文件</el-button>
            <p v-show="file" class="color666" style="margin-top: 16px; font-size: 14px">已选择: {{ $lodash.get(file, "name") }}</p>
          </div>
        </div>
        <div class="step">
          <div class="flex-y-center">
            <div class="index flex-center">3</div>
            <div class="title">第三步：导入数据</div>
          </div>
          <div class="content">
            <el-button :disabled="internalDisabled" class="btn-choice" type="primary" @click="upload">导 入</el-button>
            <p v-if="result[operator]" class="result">
              成功
              <span class="primary">{{ result[importSuccessNum] }}条</span>
              ，失败
              <span class="red">{{ result[importFailNum] }}条</span>
              ；
            </p>
            <p v-if="result[operator]" class="result">
              操作人：{{ result[operator] }}，操作时间：{{ result[operateDate] }}
              <span v-if="result[importFailNum]" class="blue pointer" @click="downloadResult">查看详情</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <template slot="footer">
      <!-- <el-button
        size="small"
        type="primary"
        @click="upload"
        :disabled="internalDisabled"
      >
        确 定
      </el-button> -->
      <el-button size="small" @click="handleClose">关 闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 工具
import { chooseFile, downloadFile } from "@/utils/fileApi";
// 接口

export default {
  name: "importDialog",
  // components: {},
  props: {
    visibility: Boolean,
    showTip: {
      type: Boolean,
      require: false,
      default: true,
    },
    /**
     * 标题
     */
    title: {
      type: String,
      require: true,
      default: "导入",
    },
    /**
     * 导入模板下载链接
     */
    templateFileUrl: {
      type: String,
      require: true,
    },
    /**
     * 导入接口
     */
    uploadFileApi: {
      type: Function,
      require: true,
    },
    /**
     * 获取上次导入结果接口
     */
    getLastResultApi: {
      type: Function,
      require: true,
    },
    /**
     * 下载上次导入失败结果路径
     */
    importFailDetail: {
      type: String,
      require: true,
    },
    /**
     * 下载上次导入失败结果 请求参数
     */
    downloadResultParams: {
      type: Object,
      default: () => ({}),
    },
    /**
     * 导入结果 - 成功条数 - 键名
     */
    importSuccessNum: {
      type: String,
      default: "importSuccessNum",
    },
    /**
     * 导入结果 - 失败条数 - 键名
     */
    importFailNum: {
      type: String,
      default: "importFailNum",
    },
    /**
     * 导入结果 - 操作人 - 键名
     */
    operator: {
      type: String,
      default: "operator",
    },
    /**
     * 导入结果 - 操作时间 - 键名
     */
    operateDate: {
      type: String,
      default: "operateDate",
    },
    /**
     * 禁用
     */
    disabled: Boolean,
  },
  data() {
    return {
      file: null,
      result: {},
    };
  },
  computed: {
    internalDisabled() {
      if (process.env.NODE_ENV === "development") {
        return false;
      }
      if (this.disabled !== undefined) {
        return this.disabled;
      }
      let now = new Date().getHours();
      return !(now >= 19 || now < 7);
    },
  },
  created() {
    this.getLastResult();
  },
  methods: {
    chooseFile() {
      chooseFile("application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet").then(file => {
        this.file = file[0];
      });
    },
    /**
     * 获取上次导入结果
     */
    getLastResult() {
      if (typeof this.getLastResultApi === "function") {
        this.getLastResultApi().then(res => {
          this.result = res;
        });
      } else {
        console.error("请正确配置获取上次导入结果接口！");
      }
    },
    /**
     * 导入
     */
    upload() {
      if (!this.file) {
        // 上传
        return this.$message.warning("请先选择文件！");
      }
      if (typeof this.uploadFileApi === "function") {
        this.uploadFileApi(this.file).then(res => {
          console.log(res);
          this.result = res;
          this.$emit("save");
        });
      } else {
        console.error("请正确配置导入接口！");
      }
    },

    /**
     * 下载上次导入结果文件
     */
    downloadResult() {
      if (this.importFailDetail) {
        downloadFile({
          url: this.importFailDetail,
          data: this.downloadResultParams,
        });
      } else {
        console.error("请配置下载上次导入结果文件路径！");
      }
    },

    /**
     * 关闭抽屉
     */
    handleClose() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 16px;
  background: #fff;
}

.tip {
  padding: 24px;
  background: #f5f7fa;
  font-size: 14px;

  p + p {
    margin-top: 14px;
  }

  .warning {
    // margin-top: 24px;
    font-size: 20px;
    font-weight: 500;
    color: #303133;
  }
}

.step-list {
  margin-top: 24px;

  .step {
    & + .step {
      margin-top: 16px;
    }

    .index {
      width: 32px;
      height: 32px;
      border: 2px solid #c0c4cc;
      border-radius: 50%;
      font-size: 20px;
      font-weight: 500;
      color: #c0c4cc;
    }

    .title {
      margin-left: 24px;
      font-size: 16px;
      color: #303133;
      font-weight: 500;
    }

    .content {
      margin: 20px 0 0 120px;

      .result {
        margin-top: 16px;
        font-size: 14px;
        color: #666;
      }
    }
  }
}

.btn-choice {
  width: 98px;
  height: 32px !important;
  line-height: 9px;
  margin-top: -10px;
}
</style>
