<template>
  <base-container ref="baseContainer" paddingTop>
    <template>
      <div class="mainTitle">门店未结算单据汇总 截至{{ statDayTime }}(含)</div>
    </template>
    <!-- 以下是表格 -->
    <template>
      <div style="display: flex; justify-content: end">
        <div></div>
        <el-button
          v-auth="761"
          size="small"
          type="text"
          class="blue"
          @click="downloadAllRecords()"
          >导出汇总<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <div class="flex h100">
        <el-table
          class="custom-table"
          :data="tableData"
          show-summary
          :summary-method="getSummaries"
          border
          v-fixedScrollBar="$refs.baseContainer"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            width="210"
            label="操作门店"
            prop="companyName"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            width="60"
            label="理赔"
            prop="claimSettlementCount"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="viewDetail(row, 0)">
                {{ row.claimSettlementCount }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            width="60"
            label="索赔"
            prop="claimForCompensationCount"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="blue pointer" @click="viewDetail(row, 1)">
                {{ row.claimForCompensationCount }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="150" label="客户单据" header-align="center">
            <el-table-column
              width="110"
              label="自费在修单据"
              prop="selfPayingRepairingCount"
              header-align="center"
            >
              <template #default="{ row }">
                <span class="blue pointer" @click="viewDetail(row, 2)">
                  {{ row.selfPayingRepairingCount }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              width="110"
              label="正常时效单据"
              prop="selfPayingNormalValidCount"
              header-align="center"
            >
              <template #default="{ row }">
                <span class="blue pointer" @click="viewDetail(row, 3)">
                  {{ row.selfPayingNormalValidCount }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              width="82"
              label="滞留单据"
              prop="selfPayingStrandedCount"
              header-align="center"
            >
              <template #default="{ row }">
                <span class="warnBillCount pointer" @click="viewDetail(row, 4)">
                  {{ row.selfPayingStrandedCount }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              width="60"
              label="合计"
              prop="selfPayingAllCount"
              header-align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            width="85"
            label="未结算单据总计"
            prop="unSettledCount"
            header-align="center"
          >
            <template #default="{ row }">
              <span class="warnBillCount">
                {{ row.unSettledCount }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="150" label="数据对比" header-align="center">
            <el-table-column
              width="96"
              label="日均建单量"
              prop="dailyAvgEntryCount"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              width="82"
              label="未结算率"
              prop="unSettledRate"
              header-align="center"
            >
              <template #default="{ row }"> {{ row.unSettledRate }}% </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            width="96"
            label="滞留车台次"
            prop="carStrandedCount"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            width="100"
            label="说明"
            prop="remark"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </template>
  </base-container>
</template>
<script>
// 组件
import {
  carStrandedCountDailyList,
  carStrandedCountDailyListExportUrl,
} from '@/api/carBill/carWreckedAndStranded';
import { hasAuth } from '@/utils/permissions';
import { downloadFile } from '@/utils/fileApi';

export default {
  name: 'carStrandedDailyStatList',
  components: {},
  data() {
    return {
      // 导出设置
      exportUrl: carStrandedCountDailyListExportUrl,
      statDayTime: '',
      tableData: [],
      sumData: {},
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const days = today.getDate();
      const currentDate = new Date(`${year}-${month}-${days}`);
      const todayDateTime = new Date(
        currentDate.setDate(currentDate.getDate() - 1)
      );
      this.statDayTime = todayDateTime.Format('yyyy年MM月dd日');
      this.getData();
    },
    /**
     * 获取数据
     */
    getData() {
      var res = hasAuth(760);
      if (!res) {
        return;
      }
      const _this = this;
      carStrandedCountDailyList().then((res) => {
        _this.statDayTime = res.statDayTimeStr;
        _this.tableData = res.statItems;
        _this.sumData = res.sumStatItem;
      });
    },
    getSummaries(param) {
      const _this = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计';
          return;
        }
        if (index > 1) {
          if (['unSettledRate'].includes(column.property)) {
            sums[index] =
              _this.$lodash.get(_this.sumData, column.property, '') + '%';
          } else {
            sums[index] = _this.$lodash.get(_this.sumData, column.property, '');
          }
        }
      });
      return sums;
    },
    downloadAllRecords() {
      downloadFile({
        url: this.exportUrl,
        data: Object.assign({}, {}),
      });
    },
    viewDetail(row, dataType) {
      var res = hasAuth(766);
      if (!res) {
        console.log('没有766权限');
        return;
      }
      this.$router.push({
        name: 'carStrandedDailyStatDetailList',
        query: {
          t: new Date().getTime(),
        },
        params: {
          filterForm: {
            companyId: row.companyId,
            statDayTime: row.statDayTime,
            strandedType: dataType + '',
          },
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mainTitle {
  text-align: center;
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  display: block;
}
.warnBillCount {
  font-weight: bold;
  color: red;
}
</style>
