<template>
  <base-container>
    <!-- 折线图表 -->

    <div class="chart-table-list">
      <div class="chart-table-header">
        <div class="buttons-sty">
          <el-button
            @click="status = 'status0'"
            :class="{
              active: status == 'status0',
            }"
            >收入分析</el-button
          >
          <el-button
            @click="status = 'status1'"
            :class="{
              active: status == 'status1',
            }"
            >支出分析</el-button
          >
        </div>
        <div class="conditions-sty">
          <el-form :model="form" label-width="100px" size="small">
            <el-col :span="9">
              <el-form-item label="选择月份">
                <el-date-picker
                  class="w100"
                  v-model="form.times"
                  type="month"
                  placeholder="选择月份"
                  @input="dateRangeChange()"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7" :offset="1">
              <el-form-item label="收入类型">
                <el-select
                  v-model="form.clientState"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in clientState"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-button size="small" type="primary">按天</el-button>
              <el-button size="small">按月</el-button>
            </el-col>
          </el-form>
        </div>
      </div>

      <div
        ref="chart1"
        style="width: 95%; height: 450px; margin-left: 2.5%"
      ></div>
    </div>
    <div class="content-container">
      <div style="display: flex; justify-content: end; margin-top: 30px">
        <el-button
          size="small"
          type="text"
          class="blue"
          @click="showExportDialog = true"
          >导出列表<i class="iconfont grow-icon_daochu el-icon--right"></i
        ></el-button>
      </div>
      <el-table height="100%" :data="carBillList" border>
        <el-table-column width="100" label="操作" prop="field6">
          <template slot-scope="scope">
            <span
              class="pointer text_operation blue"
              @click="getUpdate(scope.$index, scope.row)"
              >明细</span
            >
          </template>
        </el-table-column>
        <el-table-column mix-width="180" label="时间" prop="field7">
        </el-table-column>
        <el-table-column mix-width="180" label="这里" prop="field7">
        </el-table-column>
        <el-table-column mix-width="180" label="跟着" prop="field7">
        </el-table-column>
        <el-table-column mix-width="180" label="收入" prop="field7">
        </el-table-column>
        <el-table-column mix-width="180" label="类型" prop="field7">
        </el-table-column>
        <el-table-column mix-width="180" label="选项" prop="field7">
        </el-table-column>
        <el-table-column mix-width="180" label="显示" prop="field7">
        </el-table-column>
        <el-table-column mix-width="180" label="就好" prop="field7">
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="导出提示" :visible.sync="showExportDialog" center>
      <div class="export-dialog__body">
        <div class="export-dialog-page__input">
          <span>导出范围：第</span>
          <el-input
            type="number"
            size="small"
            v-model="startPage"
            :max="Math.ceil(total / pageOption.size)"
            :min="1"
          ></el-input>
          <span>至</span>
          <el-input
            type="number"
            size="small"
            v-model="endPage"
            :max="Math.ceil(total / pageOption.size)"
            :min="1"
          ></el-input>
        </div>
        <p class="export-dialog__tip">
          共{{ Math.ceil(total / pageOption.size) }}页，一次最多导出10000条数据
        </p>
      </div>
      <div slot="footer">
        <el-button @click="showExportDialog = false">取 消</el-button>
        <el-button type="primary" @click="doExport">确 定</el-button>
      </div>
    </el-dialog>
    <template slot="footer">
      <pagination @pageChange="changePage" :total="total"></pagination>
    </template>
  </base-container>
</template>
<script>
import { searchClient, exportCusterListUrl } from '@/api/customer/customer';
// 分页组件
import pagination from '@/components/Pagination.vue';
import { downloadFile } from '@/utils/fileApi';
// 工具
import Big from 'big.js';
import * as echarts from 'echarts';
export default {
  name: 'trendAnalysisTable',
  components: { pagination },
  data() {
    return {
      form: {},
      //导出的页面设置
      startPage: 1,
      endPage: 1,
      //分页组件
      total: 0,
      pageOption: {
        size: 10,
        current: 1,
      },
      showExportDialog: false,
      carBillList: [
        {
          field7: '琼·B250',
        },
        {
          field7: '琼·B250',
        },
      ],
      status: 'status0',
      //折线图
      chart1: '',
      chartOption1: {
        // title: {
        //   text: 'Stacked Area Chart',
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          x: 'left',
          data: ['当前', '上月同期'],
        },
        textStyle: {
          color: '#9999',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        grid: {
          // 图表距离边框的距离，可用百分比和数字（px）配置
          top: '10%',
          left: '1%',
          right: '1%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              show: true, //是否显示轴线
              lineStyle: {
                color: '#999', //刻度线的颜色
              },
            },
            data: [
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '30',
            ],
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '当前',
            type: 'line',
            showSymbol: false, //去除圆点
            stack: 'Total',
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#ADC6FC', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#F4F9FC', // 100% 处的颜色
                  },
                ]), //背景渐变色
              },
            },
            emphasis: {
              focus: 'series',
            },
            data: [
              520, 450, 100, 460, 480, 480, 249, 510, 90, 530, 300, 520, 530,
              540, 600, 670, 700, 720, 730, 725, 100, 450, 10, 690, 190, 20,
              490, 10, 60, 800, 630, 0,
            ],
          },
          {
            name: '上月同期',
            type: 'line',
            showSymbol: false, //去除圆点
            stack: 'Total',
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#DDF7ED', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#F9FDFB', // 100% 处的颜色
                  },
                ]), //背景渐变色
              },
            },
            emphasis: {
              focus: 'series',
            },
            data: [
              300, 90, 380, 540, 570, 690, 249, 520, 530, 560, 640, 670, 790,
              890, 900, 900, 430, 540, 560, 590, 800, 960, 700, 690, 500, 490,
              300, 10, 190, 290, 600, 90,
            ],
          },
        ],
        color: ['#78A7F6', '#33ab79'], // 两个折线的颜色
      },
      clientState: [
        {
          value: '2',
          label: '微信',
        },
        {
          value: '1',
          label: '支付宝',
        },
      ],
    };
  },

  created() {},
  mounted() {
    this.chart1 = echarts.init(this.$refs.chart1);
    this.chart1.setOption(this.chartOption1);
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window?.removeEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      this.chart1.resize();
    },
    /**
     * 更改页码
     */
    changePage(e) {
      this.pageOption = e;
      //   this.getDataList();
    },
    /**
     * 导出客户列表
     */
    doExport() {
      downloadFile({
        url: exportCusterListUrl,
        data: Object.assign(this.form, {
          exportStartNum: Big(this.startPage - 1).times(this.pageOption.size),
          exportEndNum: Big(this.endPage).times(this.pageOption.size),
        }),
      });
      this.showExportDialog = false;
    },

    //下面是解决设置日期默认值时重新选择无法刷新日期的方法
    dateRangeChange() {
      let _this = this;
      _this.$nextTick(() => {
        _this.$forceUpdate();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.base-container.default-padding > .main {
  padding: 0px !important;
}
//修改选择时间框长度
// .el-date-editor.el-input__inner {
//    width: 180px !important;
// }
//改变base-container头部间距
.base-container.default-padding {
  padding: 10px 0px !important;
}
.last-type-sty {
  display: flex;
  .el-input {
    margin-left: 20px;
  }
}
//导出列表样式
.export-dialog__body {
  margin: 20px 0;
  text-align: center;
  .export-dialog-page__input {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input {
      width: 140px;
      margin: 0 15px;
    }
  }
  .export-dialog__tip {
    margin-top: 20px;
  }
}
//折线图样式
.chart-table-list {
  width: 99%;
  height: 550px;
  margin-left: 5px;
  margin-top: 10px;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.1);
  .chart-table-header {
    width: 95%;
    margin-left: 2%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-top: 20px;
    .buttons-sty {
      margin: 0px 20px 0px;
      width: 208px;
      height: 42px;
      background: #fcfeff;
      box-shadow: inset 0px 0px 1px 1px #f5f8fa, inset 1px 1px 1px 0px #ededed;
      border-radius: 100px;
      button {
        margin-top: 4px;
        margin-left: 5px;
        width: 96px;
        height: 34px;
        border: 0px;
        border-radius: 17px;
      }
      .active {
        color: $primary;
        background: rgba(85, 183, 142, 0.1);
        border-radius: 17px;
      }
    }
    .conditions-sty {
      button {
        border: 0px;
        border-radius: 4px;
        border: 1px solid #55b78e;
      }
    }
  }
}
</style>
