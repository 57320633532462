<!-- eslint-disable vue/no-unused-vars -->
<template>
  <base-container :customStyle="'padding-top:8px;'">
    <div class="car-detail-box">
      <div ref="carInfoRef" id="uq-car-customer-info" class="customer-info">
        <div class="customer-info-header flex-x-between">
          <div class="flex">
            <div class="bill-type flex-center flex-shrink0">
              <div class="bill-type-text">
                {{ billMessage.billType | dict("carBillType") }}
              </div>
            </div>
            <div class="car-logo flex-shrink0">
              <img v-if="carInfo.carLogoIcon" :src="carInfo.carLogoIcon" />
              <img v-else src="@/assets/img/carLogo.png" />
            </div>
            <div class="flex-x-between flex-col flex-shrink0" style="margin-left: 16px; padding: 6px 0">
              <div class="car-info flex-y-end">
                <span class="car-number pointer" @click="goCarDetail">{{ carInfo.carNumber }}</span>
                <span v-if="carInfo.brandName" class="car-info-tag car-brand">{{ carInfo.brandName }}</span>
                <!-- <span v-if="carInfo.vehicleType" class="car-info-tag car-vehicle-type">{{
                  carInfo.vehicleType
                }}</span> -->

                <span class="car-info-tag history pointer" @click="viewHistory">历史进店 {{ carInfo.intoStoresCount }} 次</span>
                <span v-if="carInfo.isRiskControlCar === 1" class="car-info-tag riskControlCar">风控车</span>
              </div>
              <div class="flex">
                <div v-if="carInfo.clientId" class="client-info">
                  <i
                    v-if="[0, 1, 2].includes(carInfo.clientLevel)"
                    :class="levelIcons[carInfo.clientLevel]"
                    :title="levelIconsWord[carInfo.clientLevel]"
                    class="iconfont icon_grade"
                    style="margin-right: 14px"></i>
                  <span class="client-name pointer" @click="goClientDetail">{{ carInfo.clientName }}</span>

                  <!-- <span
                    class="pointer"
                    style="
                      margin-left: 16px;
                      font-size: 14px;
                      text-decoration: underline;
                    "
                    v-if="carInfo.clientRemark"
                    @click="remarksDrawerVisibilityEvent(1)"
                    >注意事项</span
                  > -->
                  <span
                    v-auth="546"
                    class="primary pointer"
                    style="margin-left: 16px; font-size: 14px; text-decoration: underline"
                    @click="clientCommandDrawerVisibility = true">
                    客户指令
                  </span>
                </div>
                <div v-else style="margin-top: 3px">
                  <span class="color999" style="margin-left: 5px; font-size: 14px">暂未绑定客户</span>
                  <span v-auth="63" class="blue pointer" style="margin-left: 5px; font-size: 14px" @click="showBindingCustomerDialog">
                    绑定客户
                  </span>
                  <span
                    v-if="carInfo.clientRemark"
                    class="pointer"
                    style="margin-left: 16px; font-size: 14px; text-decoration: underline"
                    @click="remarksDrawerVisibilityEvent(1)">
                    注意事项
                  </span>
                </div>
                <div v-if="carAgreementId">
                  <span
                    v-auth="579"
                    class="primary pointer"
                    style="margin-left: 16px; font-size: 14px; text-decoration: underline"
                    @click="agreementPriceDialogVisibility = true">
                    协议报价
                  </span>
                </div>
                <el-popover v-if="underWarrantyState == 0" placement="bottom" trigger="hover" width="300">
                  <template slot="reference">
                    <el-button class="warranty-info" plain type="primary">
                      未知
                      <i class="iconfont grow-icon_doubt" style="font-size: 12px; margin-left: 3px"></i>
                    </el-button>
                  </template>
                  <div
                    v-if="
                      vehicleVo.length == 0 &&
                      threePowerVo.length == 0 &&
                      powerBatteryVo.length == 0 &&
                      batteryVo.length == 0 &&
                      wearingPartsVo.length == 0
                    ">
                    <span class="warranty-title">未知</span>
                    <span class="tips">（暂无该车型质保规则）</span>
                  </div>
                  <div v-else>
                    <span class="warranty-title">未知</span>
                    <span class="tips">（注册时间为空或暂无进店里程）</span>
                    <div v-if="vehicleVo.length != 0">
                      <div v-for="(item, index) in vehicleVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">1.整车质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                    <div v-if="threePowerVo.length != 0">
                      <div v-for="(item, index) in threePowerVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">2.三电质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                    <div v-if="powerBatteryVo.length != 0">
                      <div v-for="(item, index) in powerBatteryVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">3.动力电池质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                    <div v-if="batteryVo.length != 0">
                      <div v-for="(item, index) in batteryVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">4.电芯质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                    <div v-if="wearingPartsVo.length != 0">
                      <div v-for="(item, index) in wearingPartsVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">5.易损件质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                  </div>
                </el-popover>
                <el-popover v-if="underWarrantyState == 1" placement="bottom" trigger="hover" width="300">
                  <template slot="reference">
                    <el-button class="warranty-info" plain type="primary">
                      过保
                      <i class="iconfont grow-icon_doubt" style="font-size: 12px; margin-left: 3px"></i>
                    </el-button>
                  </template>
                  <div>
                    <p>过保</p>
                    <div v-if="vehicleVo.length != 0">
                      <div v-for="(item, index) in vehicleVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">1.整车质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                    <div v-if="threePowerVo.length != 0">
                      <div v-for="(item, index) in threePowerVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">2.三电质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                    <div v-if="powerBatteryVo.length != 0">
                      <div v-for="(item, index) in powerBatteryVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">3.动力电池质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                    <div v-if="batteryVo.length != 0">
                      <div v-for="(item, index) in batteryVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">4.电芯质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                    <div v-if="wearingPartsVo.length != 0">
                      <div v-for="(item, index) in wearingPartsVo" :key="index" class="tips">
                        <span v-show="index == 0" class="warranty-title">5.易损件质保</span>
                        <span>({{ item.month }}月/{{ item.km }}公里)</span>
                        <p>{{ item.content }}</p>
                      </div>
                    </div>
                  </div>
                </el-popover>
                <el-popover v-if="underWarrantyState == 2 && vehicleVo.length != 0" placement="bottom" trigger="hover" width="300">
                  <template slot="reference">
                    <el-button class="warranty-info" plain type="primary">
                      整车质保
                      <i class="iconfont grow-icon_doubt" style="font-size: 12px; margin-left: 3px"></i>
                    </el-button>
                  </template>
                  <div v-if="vehicleVo.length != 0">
                    <p>
                      <span class="warranty-title">整车质保</span>
                      <span class="tips">({{ vehicleVo[0].month }}月/{{ vehicleVo[0].km }}公里)</span>
                    </p>
                    <p class="tips">{{ vehicleVo[0].content }}</p>
                  </div>
                </el-popover>
                <el-popover v-if="underWarrantyState == 2 && threePowerVo.length != 0" placement="bottom" trigger="hover" width="300">
                  <template slot="reference">
                    <el-button class="warranty-info" plain type="primary">
                      三电质保
                      <i class="iconfont grow-icon_doubt" style="font-size: 12px; margin-left: 3px"></i>
                    </el-button>
                  </template>
                  <div>
                    <p>
                      <span class="warranty-title">三电质保</span>
                      <span class="tips">({{ threePowerVo[0].month }}月/{{ threePowerVo[0].km }}公里)</span>
                    </p>
                    <p class="tips">{{ threePowerVo[0].content }}</p>
                  </div>
                </el-popover>
                <el-popover v-if="underWarrantyState == 2 && powerBatteryVo.length != 0" placement="bottom" trigger="hover" width="300">
                  <template slot="reference">
                    <el-button class="warranty-info" plain type="primary">
                      动力电池质保
                      <i class="iconfont grow-icon_doubt" style="font-size: 12px; margin-left: 3px"></i>
                    </el-button>
                  </template>
                  <div>
                    <p>
                      <span class="warranty-title">动力电池质保</span>
                      <span class="tips">({{ powerBatteryVo[0].month }}月/{{ powerBatteryVo[0].km }}公里)</span>
                    </p>
                    <p class="tips">{{ powerBatteryVo[0].content }}</p>
                  </div>
                </el-popover>
                <el-popover v-if="underWarrantyState == 2 && batteryVo.length != 0" placement="bottom" trigger="hover" width="300">
                  <template slot="reference">
                    <el-button class="warranty-info" plain type="primary">
                      电芯质保
                      <i class="iconfont grow-icon_doubt" style="font-size: 12px; margin-left: 3px"></i>
                    </el-button>
                  </template>
                  <div>
                    <p>
                      <span class="warranty-title">电芯质保</span>
                      <span class="tips">({{ batteryVo[0].month }}月/{{ batteryVo[0].km }}公里)</span>
                    </p>
                    <p class="tips">{{ batteryVo[0].content }}</p>
                  </div>
                </el-popover>
                <el-popover v-if="underWarrantyState == 2 && wearingPartsVo.length != 0" placement="bottom" trigger="hover" width="300">
                  <template slot="reference">
                    <el-button class="warranty-info" plain type="primary">
                      易损件质保
                      <i class="iconfont grow-icon_doubt" style="font-size: 12px; margin-left: 3px"></i>
                    </el-button>
                  </template>
                  <div>
                    <p>
                      <span class="warranty-title">易损件质保</span>
                      <span class="tips">({{ wearingPartsVo[0].month }}月/{{ wearingPartsVo[0].km }}公里)</span>
                    </p>
                    <p class="tips">{{ wearingPartsVo[0].content }}</p>
                  </div>
                </el-popover>
              </div>
            </div>
            <div style="margin-left: 24px">
              <i
                :class="['bill-state', 'iconfont', 'blue', $lodash.get(carBillStateIconDict[billMessage.billState], 'label', '')]"
                :style="{
                  color: $lodash.get(carBillStateIconDict[billMessage.billState], 'color', ''),
                }"></i>
              <i
                v-if="[1, 2, 4, 5].includes(billMessage.billState)"
                :class="{
                  'bill-state': true,
                  newicon: true,
                  'grow-a-icon_yitiche3x': !!billMessage.takeTime,
                  'grow-a-icon_weijiezhang3x': !billMessage.takeTime,
                }"
                :style="{
                  'margin-left': '10px',
                  color: billMessage.takeTime ? '#33ab79' : '#84878c',
                }"></i>
            </div>
            <p class="car-remark">{{ carInfo.carRemark }}</p>
          </div>
          <div class="flex-y-center">
            <el-popover placement="top-end" trigger="hover" width="800">
              <template slot="reference">
                <el-button class="process-btn" plain type="primary" @click="historicalDrawerVisibility = true">流程详情</el-button>
              </template>
              <div class="bill-process flex-y-center">
                <div class="state-name">
                  <span>接待开单</span>
                  <span class="time">{{ billMessage.dateCreated }}</span>
                </div>
                <div class="split-string">
                  <div class="top-text">
                    <span v-show="billMessage.planFinishTime">预交车: {{ billMessage.planFinishTime }}</span>
                    <span v-show="billMessage.waitInStore">在店等</span>
                    <span v-show="billMessage.undetermined">待定</span>
                  </div>
                  <div class="line"></div>
                  <div class="bottom-text orange">
                    <span v-if="billMessage.planFinishTime && billMessage.billState == 0">{{ distancePlannedTime }}</span>
                  </div>
                </div>
                <div class="state-name">
                  <div class="top-text"></div>
                  <span>质检交车</span>
                  <span class="time"></span>
                </div>
                <div class="split-string">
                  <div class="top-text plan-time"></div>
                  <div class="line"></div>
                  <div class="bottom-text orange"></div>
                </div>
                <div class="state-name">
                  <div class="top-text"></div>
                  <span>提交结账</span>
                  <span class="time"></span>
                </div>
                <div class="split-string">
                  <div class="top-text orange">
                    <span v-show="billMessage.takeTime">客户已提车</span>
                  </div>
                  <div class="line"></div>
                  <div class="bottom-text orange">
                    {{ billMessage.takeTime }}
                  </div>
                </div>
                <div class="state-name">
                  <span>车单结账</span>
                  <span v-if="billMessage.checkDate" class="time">{{ billMessage.checkDate }}</span>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <div ref="carBillRef" id="uq-car-bill-info-form" class="bill-info-form">
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="label">接车员：</div>
            <div class="value">{{ billMessage.pickUpUserName }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">进店时间：</div>
            <div class="value">{{ billMessage.dateCreated }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">单据号：</div>
            <div class="value">{{ billMessage.billCode }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">预交车时间：</div>
            <div class="value">
              <span v-if="billMessage.planFinishTime">{{ billMessage.planFinishTime }}</span>
              <span v-if="billMessage.waitInStore">在店等</span>
              <span v-if="billMessage.undetermined">待定</span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="label">送修人：</div>
            <div class="value">{{ billMessage.customerName }} {{ billMessage.customerPhone }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">进店里程：</div>
            <div class="value">
              {{ billMessage.mileage ? billMessage.mileage + " KM" : "" }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="label">消费客户：</div>
            <div class="value">
              <span v-if="billMessage.billType == 2">
                <template v-if="[0, 1].includes(otherData.isInsuranceTurnSelf)">
                  {{ otherData.beTurnConsumer }}
                  <span
                    v-if="settlementWay && ![1, 3].includes(billMessage.billState)"
                    :style="{
                      margin: '0 5px',
                      color: settlementWay === '现结' ? '#33ab79' : 'red',
                    }">
                    {{ settlementWay }}
                  </span>
                  <el-tag size="mini">{{ otherData.isInsuranceTurnSelf ? "转自费" : "免赔" }}</el-tag>
                </template>
                <template v-else>
                  {{ carBillInsuranceInfo.claimsInsuranceCompanyName }}
                </template>
              </span>
              <span v-else-if="billMessage.billType == 3">{{ carBillUndertakeInfo.undertakeInsuranceCompanyName }}</span>
              <span v-else>
                <span>{{ payerInfo.consumerName }}</span>
                <span
                  v-if="settlementWay"
                  :style="{
                    'margin-left': '5px',
                    color: settlementWay === '现结' ? '#33ab79' : 'red',
                  }">
                  {{ settlementWay }}
                </span>
                <span v-if="otherData.isOthersSelfPayState" class="border-tag">他人自费</span>
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="label">开票主体：</div>
            <div class="value">
              <span v-if="[0, 1, undefined, null].includes(billMessage.billType)">{{ billMessage.invoice }}</span>
              <span v-if="billMessage.billType == 2">{{ carBillInsuranceInfo.insuranceCompanyName }}</span>
              <span v-if="billMessage.billType == 3">{{ carBillUndertakeInfo.undertakeCompanyName }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="label">操作门店：</div>
            <div class="value">
              {{ otherData.operateCompanyName }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="label">施工类型：</div>
            <div class="value">
              {{ billMessage.difficulty | dict("difficulty") }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="label">业务来源：</div>
            <div class="value">{{ billMessage.billSourceTypeName }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">环检图片：</div>
            <div class="value">
              <span v-if="!billImgs.length" class="color999">暂无附件图片</span>
              <el-badge v-else :value="billImgs.length">
                <img :src="billImgs[0]" alt="" class="thumbnail-img" @click="previewImgs(billImgs, billImgsTitleArr)" />
              </el-badge>
            </div>
          </el-col>
        </el-row>
        <!-- 理赔 -->
        <el-row v-if="billMessage.billType == 2" :gutter="10">
          <el-col :span="6">
            <div class="label">保险公司：</div>
            <div class="value">
              {{ carBillInsuranceInfo.claimsInsuranceCompanyName }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="label">报案号：</div>
            <div class="value">
              <span class="pointer" style="text-decoration: underline" @click="carlossevent">
                {{ carBillInsuranceInfo.caseNum }}
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="label">事故责任：</div>
            <div class="value">
              {{ carBillInsuranceInfo.dutyType | dict("dutyType") }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="label">理赔图片：</div>
            <div class="value">
              <span v-if="!insuranceImgs.length" class="color999">暂无附件图片</span>
              <el-badge v-else :value="insuranceImgs.length">
                <img :src="insuranceImgs[0]" alt="" class="thumbnail-img" @click="previewImgs(insuranceImgs, insuranceImgTitles)" />
              </el-badge>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="billMessage.billType == 2" :gutter="10">
          <el-col :span="6">
            <div class="label">定损状态：</div>
            <div class="value">
              <p>
                <template v-if="carBillInsuranceInfo.lossDeterminationState === 2 && carBillInsuranceInfo.investigationState === 2">
                  待复勘
                </template>
                <template v-else-if="carBillInsuranceInfo.lossDeterminationState === 2 && carBillInsuranceInfo.recoveryState === 2">
                  待收旧
                </template>
                <template v-else>
                  {{ carBillInsuranceInfo.lossDeterminationState | dict("lossDeterminationState") }}
                </template>
              </p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="label">定损金额：</div>
            <div class="value">
              <template v-if="carBillInsuranceInfo.findLossAmount !== undefined">
                {{ carBillInsuranceInfo.findLossAmount | toThousands }} 元
              </template>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="label"></div>
            <div class="value"></div>
          </el-col>
          <el-col :span="6">
            <div class="label"></div>
            <div class="value"></div>
          </el-col>
        </el-row>
        <!-- 理赔 -->
        <!-- 索赔 -->
        <el-row v-if="billMessage.billType == 3" :gutter="10">
          <el-col :span="6">
            <div class="label">三包厂家：</div>
            <div class="value">
              {{ carBillUndertakeInfo.undertakeInsuranceCompanyName }}
            </div>
          </el-col>
          <el-col :span="18">
            <div class="label">索赔单号：</div>
            <div class="value">
              <span
                v-for="(item, index) in carBillUndertakeInfo.claimsNum"
                :key="index"
                class="pointer"
                style="margin-left: 5px; text-decoration: underline">
                {{ item }}
              </span>
            </div>
          </el-col>
        </el-row>
        <!-- 双系统录单 -->
        <el-row
          v-if="
            billMessage.doubleSysInputState !== null &&
            billMessage.doubleSysInputState !== undefined &&
            [1, 2].includes(billMessage.doubleSysInputState)
          "
          :gutter="10">
          <el-col :span="6">
            <div class="label">双系统录单：</div>
            <div class="value">
              {{ billMessage.doubleSysInputState === 1 ? "待录单" : "已录单" }}
            </div>
          </el-col>
          <el-col :span="18">
            <div class="label">双系统单号：</div>
            <div class="value">
              {{ billMessage.doubleSysNum }}
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col v-if="billMessage.billType == 2" :span="6">
            <div class="label">推修对象：</div>
            <div class="value">{{ otherData.pushClient }}</div>
          </el-col>
          <el-col :span="billMessage.billType == 2 ? 6 : 12">
            <div class="label">车主要求：</div>
            <div class="value">{{ billMessage.remark }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">初诊与备注：</div>
            <div class="value">{{ billMessage.firstVisitRemark }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">车单备注：</div>
            <div class="value">
              <el-tag v-if="otherData.isClaimUnderWarranty === 1" class="car-bill-tag" size="small">在保</el-tag>
              <el-tag v-if="otherData.isClaimUnderWarranty === 0" class="car-bill-tag" size="small">不在保</el-tag>
              <el-tag v-if="otherData.isClaimActivity === 1" class="car-bill-tag" size="small">有活动</el-tag>
              <el-tag v-if="otherData.isClaimActivity === 0" class="car-bill-tag" size="small">无活动</el-tag>
              <el-tag v-if="billMessage.isReturnCar" class="car-bill-tag" size="small">退车整备</el-tag>
              <el-tag v-if="billMessage.isRepair" class="car-bill-tag" size="small">返修</el-tag>
              <el-tag v-if="billMessage.isSupplementaryOrder" class="car-bill-tag" size="small">补单</el-tag>
              <el-tag v-if="billMessage.isClaimSettlToSelfPayment === 1" class="car-bill-tag" size="small">理赔转自费</el-tag>
              <el-tag v-if="billMessage.isClaimSettlFullLoss === 1" class="car-bill-tag" size="small">全损</el-tag>
              <el-tag v-if="billMessage.isClaimExternalService === 1" class="car-bill-tag" size="small">外服</el-tag>
              <el-tag v-if="billMessage.doubleSysInputState === 1" class="car-bill-tag" size="small">双系统待录单</el-tag>
              <el-tag v-if="billMessage.doubleSysInputState === 2" class="car-bill-tag" size="small">双系统已录单</el-tag>
              {{ billMessage.companyRemark }}
            </div>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="10">
          <el-col :offset="18" :span="6">
            <div class="label">车单标签：</div>
            <div class="value">
              <el-tag
                v-if="billMessage.isReturnCar"
                size="small"
                class="el-icon--right"
                >退车整备</el-tag
              >
              <el-tag
                v-if="billMessage.isRepair"
                size="small"
                class="el-icon--right"
                >返修</el-tag
              >
              <el-tag
                v-if="billMessage.isSupplementaryOrder"
                size="small"
                class="el-icon--right"
                >补单</el-tag
              >
            </div>
          </el-col>
        </el-row> -->
      </div>
      <div ref="carBillHeaderRef" id="uq-car-bill-services-header" class="btns flex-x-between">
        <div class="flex-y-end">
          <div class="bao-btn">报价项目</div>
          <div class="flex-y-end" style="margin-bottom: 4px">
            <div class="border-button material-button" @click="materialBillDrawerVisibility = true">
              <p class="pointer">材料领用状态</p>
              <div>
                <span>待领：{{ otherData.materialBillUnProcessedNum || 0 }},</span>
                <span>已领：{{ otherData.materialBillProcessedNum || 0 }}</span>
              </div>
            </div>
            <div class="border-button material-button" @click="inspectCarDrawerVisibility = true">
              <p class="pointer">检车单</p>
              <div>
                <span>{{ carDisposeStateNum }}</span>
              </div>
            </div>
            <div v-if="[0].includes(billMessage.billState)" v-auth="507" class="border-button" @click="copyVal">
              <p class="pointer">审核报价</p>
            </div>
            <template v-if="billMessage.billState == 0">
              <!-- v-if="otherData.checkCurrentCompanyHasEnableDispatch" -->
              <div v-auth="470" class="border-button" @click="submitConstruction">提交施工</div>
              <!-- <span v-else style="margin: 0 0 13px 16px" class="orange"
                ><i class="el-icon-warning orange"></i> 未开启车间工单
              </span> -->
            </template>
            <template v-if="goodsException && [1, 2, 5].includes(billMessage.billState)">
              <div class="border-button orange-btn" @click="goodsExceptionDialogVisibility = true">
                <i class="el-icon-warning-outline orange" style="margin-right: 5px"></i>
                温馨提示
              </div>
            </template>
          </div>
        </div>
        <!-- 1.0 隐藏 -->
        <!-- <span class="primary pointer" @click="submitCarTipsDialog = true"
            >提交检车</span
          > -->
        <!-- bug764隐藏 -->
        <!-- <span class="primary pointer">审核报价</span> -->
      </div>
      <el-table
        ref="servicesTable"
        :data="serviceTableData"
        :max-height="servicesTableMaxHeight"
        :span-method="spanMethod"
        :tree-props="{ children: 'billGoods', hasChildren: 'hasChildren' }"
        border
        class="service-table"
        default-expand-all
        row-key="id"
        style="width: 100%">
        <el-table-column label="项目名称" min-width="180">
          <template #default="{ row }">
            <el-tag v-if="row.isAgreePrice" size="mini" type="info">协议</el-tag>
            <span v-if="row.name" :class="{ orange: row.emptyService }">{{ `${row.title}${row.title ? "." : ""}` }}{{ row.name }}</span>
            <p v-if="row.lastUseDate" class="red">最近服务: {{ row.lastUseDate }}</p>
          </template>
        </el-table-column>
        <el-table-column label="项目编码" min-width="100" prop="code"></el-table-column>
        <el-table-column label="单价" prop="price" width="80">
          <template #default="{ row }">
            <span :key="tableKey + 1">
              {{ row.price | toThousands }}
            </span>
          </template>
        </el-table-column>
        <!-- 协议价赠送配置 -->
        <el-table-column label="赠送" min-width="100" show-overflow-tooltip>
          <template #header>
            <el-tooltip effect="light" placement="bottom-start">
              <div slot="content" style="color: #666; font-size: 13px; line-height: 20px">
                <div class="flex">满足足够条件，附赠的服务(可以是项目或者商品配件)</div>
              </div>
              <i class="iconfont grow-icon_doubt"></i>
            </el-tooltip>
            赠送
          </template>
          <template #default="{ row }">
            <template v-if="row.isAgreePrice">
              <el-tag v-if="row.agreementFree" size="mini" type="info">赠送</el-tag>
              {{ row.agreementFreeRemark }}
            </template>
          </template>
        </el-table-column>
        <!-- 折旧费配置 -->
        <el-table-column label="折旧费" min-width="120">
          <template #header>
            <el-tooltip effect="light" placement="bottom-start">
              <div slot="content" style="color: #666; font-size: 13px; line-height: 20px">
                <div class="flex">配件换新等抵扣</div>
              </div>
              <i class="iconfont grow-icon_doubt"></i>
            </el-tooltip>
            折旧费
          </template>
          <template #default="{ row }">
            <template v-if="row.isDepreciation">
              <el-tag v-if="row.isDepreciation" size="mini" type="info">折旧</el-tag>
              <span>¥{{ row.depreciationCharge | toThousands }}</span>
              <hr />
              {{ row.depreciationRemark }}
            </template>
          </template>
        </el-table-column>
        <el-table-column label="折扣" prop="discount" width="60">
          <template #default="{ row }">
            <span :key="tableKey + 2">
              {{ row.discount || "无" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="数量" prop="date" width="70">
          <template #default="{ row }">
            <span :key="tableKey + 3">
              <span v-if="row.showAllNum" class="orange">
                <p>报价{{ row.num }}</p>
                <p v-if="row.waitPickNum">待领{{ row.waitPickNum }}</p>
                <p v-if="row.pickedNum">已领{{ row.pickedNum }}</p>
              </span>
              <span v-else>{{ row.num }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="小计" prop="date" width="100">
          <template #default="{ row }">
            <div>
              <span :key="tableKey + 4">
                {{ row.total | toThousands }}
              </span>
              <div v-if="otherData.paid != otherData.total">
                <span>整单折扣后:</span>
                <span>¥{{ row.billDiscount | toThousands }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="项目合计" width="100">
          <template #default="{ row }">
            <div v-if="otherData.paid == otherData.total">
              <div>{{ row.itemTotalBefore | toThousands }}</div>
            </div>
            <div v-else>
              <div>{{ row.itemTotalAfter | toThousands }}</div>
              <del>{{ row.itemTotalBefore | toThousands }}</del>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="84">
          <template #header>
            <div class="flex-y-center">
              <span>成本小计</span>
            </div>
          </template>
          <template #default="{ row }">
            <span v-if="row.goodsId">
              <span
                v-if="showCost"
                v-auth="{
                  code: 169,
                  handle: 'dataMarking',
                }">
                {{ row.totalCostPrice | toThousands }}
              </span>
              <span v-else>****</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="项目毛利" width="94">
          <template #default="{ row }">
            <span v-if="showCost" v-auth="{ code: 169, handle: 'dataMarking' }">
              {{ row.itemsGrossProfit | toThousands }}
            </span>
            <span v-else>****</span>
          </template>
        </el-table-column>
        <!-- 待结账、已结账页面去除施工状态显示 -->
        <el-table-column label="施工技师" width="100">
          <template #default="{ row }">
            <div :key="tableKey + 5">
              <div v-if="$lodash.get(row, 'technicianInfoVos.length', false)">
                <div>
                  {{
                    $lodash
                      .get(row, "technicianInfoVos", [])
                      .map(item => item.technicianName)
                      .join("、")
                  }}
                </div>
              </div>
            </div>
            <div v-if="![1, 2].includes(billMessage.billState)" class="orange">
              {{ row.dispatchTaskState | dict("dispatchTaskState") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="故障 | 完工图" width="110">
          <template #default="{ row }">
            <div class="flex-y-center" style="padding: 10px 0">
              <span v-if="!$lodash.get(row, 'preConstructionPics.length', 0)" style="width: 32px; text-align: center">无</span>
              <el-popover v-else placement="top" trigger="hover">
                <upload-file-list :value="row.preImages" disabled></upload-file-list>
                <div class="video-list">
                  <video
                    v-for="(src, index) in row.preVideos"
                    :key="index"
                    :poster="playImg"
                    :src="src"
                    class="thumbnail-video"
                    preload="auto"
                    @click="handleClickVideo($event, row.preVideos, index)">
                    <source :src="src" type="video/mp4" />
                    您的设备不支持 video 标签。
                  </video>
                </div>
                <template slot="reference">
                  <el-badge :value="row.preConstructionPics.length" style="margin-right: 8px">
                    <img :src="row.prePoster" class="thumbnail-img" />
                  </el-badge>
                </template>
              </el-popover>

              <div style="width: 2px; height: 32px; margin: 0 8px; background: #ebeef5"></div>

              <span v-if="!$lodash.get(row, 'afterConstructionPics.length', 0)" style="width: 32px; text-align: center">无</span>
              <el-popover v-else placement="top" trigger="hover">
                <upload-file-list :value="row.afterImages" disabled></upload-file-list>
                <div class="video-list">
                  <video
                    v-for="(src, index) in row.afterVideos"
                    :key="index"
                    :poster="playImg"
                    :src="src"
                    class="thumbnail-video"
                    preload="auto"
                    @click="handleClickVideo($event, row.afterVideos, index)">
                    <source :src="src" type="video/mp4" />
                    您的设备不支持 video 标签。
                  </video>
                </div>
                <template slot="reference">
                  <el-badge :value="row.afterConstructionPics.length" style="margin-right: 8px">
                    <img :src="row.afterPoster" class="thumbnail-img" />
                  </el-badge>
                </template>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <template slot="empty">
          <div style="border-bottom: 1px solid #ebeef5">未添加服务</div>
        </template>
      </el-table>
    </div>
    <template slot="footer">
      <div id="uq-car-bill-footer" class="footers">
        <div class="flex-col" style="justify-content: center; padding-left: 30px">
          <div class="bill-price-box">
            单据总额：
            <span class="rmb">¥</span>
            <span>
              <span>
                <span class="price-number">{{ otherData.paid | toThousands }}</span>
                <del v-if="otherData.paid != otherData.total" class="original-price">¥{{ otherData.total | toThousands }}</del>
              </span>
            </span>
            <span v-if="billMessage.billType == 1 && billMessage.billState == 1">
              <el-tooltip effect="light" placement="top">
                <template slot="content">包干金额,结账当月显示为查询时点的预分摊金额,结账次月显示为实际分摊金额</template>
                <i class="el-tooltip iconfont grow-icon_doubt color666" style="margin-left: 11px"></i>
              </el-tooltip>
              包干金额:
              <span>
                <span class="rmb">¥</span>
                <span v-auth="{ code: 473, handle: 'dataMarking' }" class="price-number">
                  {{ otherData.contractAmount | toThousands }}
                </span>
              </span>
            </span>
            <span style="margin-left: 15px">
              成本合计：
              <span v-auth="{ code: 169, handle: 'dataMarking' }">
                <span v-if="showCost">¥ {{ otherData.finalTotalCostPrice | toThousands }}</span>
                <span v-else>****</span>
              </span>
              单据毛利：
              <span v-auth="{ code: 169, handle: 'dataMarking' }">
                <span v-if="showCost">¥ {{ otherData.billGrossProfit | toThousands }}</span>
                <span v-else>****</span>
              </span>
            </span>
            <span>
              <i
                v-show="showCost"
                v-auth="169"
                class="pointer iconfont grow-yanjing_xianshi_o el-icon--right"
                style="font-size: 24px"
                @click="changeShowCostState"></i>
              <i
                v-show="!showCost"
                v-auth="169"
                class="pointer iconfont grow-yanjing_yincang_o el-icon--right"
                style="font-size: 24px"
                @click="changeShowCostState"></i>
            </span>
          </div>
          <p class="price-detail">
            <!-- 项目报价总额：¥{{ otherData.total | toThousands
            }} -->
            = 服务：¥{{ serviceTotalPrice | toThousands }} + 商品：¥{{ goodsTotalPrice | toThousands }}
            <span v-if="otherData.total != otherData.paid">- 整单折扣优惠：¥{{ (otherData.total - otherData.paid) | toThousands }}</span>
          </p>
        </div>
        <div v-if="dataLoaded" class="buttons">
          <template v-if="claimVo !== undefined && claimVo.claimAmount !== undefined">
            <el-popover placement="top-end" title="认领明细" trigger="hover" width="873">
              <el-table :data="carBillClaimDetailList" border>
                <el-table-column label="回款主体" property="invoicingEntity" width="200"></el-table-column>
                <el-table-column label="回款时间" property="receivingDatetime" width="160"></el-table-column>
                <el-table-column label="认领金额" property="claimAmount" width="90"></el-table-column>
                <el-table-column label="认领日期" property="claimDateTime" width="160"></el-table-column>
                <el-table-column label="认领人" property="claimOperator" width="100"></el-table-column>
                <el-table-column label="付款时间" property="paymentDateTime" width="160"></el-table-column>
              </el-table>
              <span
                slot="reference"
                class="price-number"
                style="font-size: 18px; color: #ee8f33; margin-right: 30px"
                @click="gotoClaimReportDetail">
                认领金额：
                <span class="rmb" style="font-size: 12px; color: #ee8f33">¥</span>
                {{ claimVo.claimAmount | toThousands }}
              </span>
            </el-popover>
          </template>
          <!-- 车单状态: 服务中 -->
          <div v-if="billMessage.billState == 0">
            <el-button v-auth="4" type="primary" @click="editCarBill">编辑车单</el-button>
            <el-dropdown placement="top" split-button style="margin: 0px 10px" trigger="click" @click="submitInvoicingEvent">
              提交结账
              <template slot="dropdown">
                <el-dropdown-menu>
                  <el-dropdown-item v-auth="471" @click.native="pickUpCarDialogEvent">挂单</el-dropdown-item>
                  <el-dropdown-item v-auth="215" @click.native="cancelReceptionDialogEvent">取消接待</el-dropdown-item>
                  <el-dropdown-item @click.native="ChangesCarTypeDialogEvent">变更业务类型</el-dropdown-item>
                  <el-dropdown-item v-auth="451" @click.native="strandedNoteDialogEvent">滞留说明</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <!-- 车单状态: 待结账 -->
          <div v-else-if="billMessage.billState == 2">
            <el-button v-auth="5" type="primary" @click="stayInvoicingDialogEvent">结账</el-button>
            <el-dropdown placement="top" style="margin: 0px 10px" trigger="click">
              <el-button>
                更多操作
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <template v-if="billMessage.takeTime">
                  <el-dropdown-item @click.native="handleRevocationTakeCar">撤销提车</el-dropdown-item>
                </template>
                <template v-else>
                  <el-dropdown-item @click.native="handleTakeCar">提车</el-dropdown-item>
                </template>
                <el-dropdown-item v-auth="227" @click.native="handleResetService">重新服务</el-dropdown-item>
                <el-dropdown-item v-auth="575" @click.native="carBillLock('editCarBillDialogVisibility')">编辑车单</el-dropdown-item>
                <el-dropdown-item v-auth="451" @click.native="strandedNoteDialogEvent">滞留说明</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 车单状态: 挂单中 -->
          <div v-else-if="billMessage.billState == 4 || billMessage.billState == 5">
            <el-button style="margin-right: 20px" type="primary" @click="CancellationAreCancelledDialogEvent">撤销挂单</el-button>
            <!-- 挂单中 已完工或免施工 -->
            <template v-if="[1, 6].includes(billMessage.dispatchState)">
              <el-dropdown placement="top" split-button style="margin: 0px 10px" trigger="click" @click="submitInvoicingEvent">
                提交结账
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-auth="4" @click.native="editCarBill">编辑车单</el-dropdown-item>
                  <el-dropdown-item v-auth="451" @click.native="strandedNoteDialogEvent">滞留说明</el-dropdown-item>
                  <template v-if="billMessage.takeTime">
                    <el-dropdown-item @click.native="handleRevocationTakeCar">撤销提车</el-dropdown-item>
                  </template>
                  <template v-else>
                    <el-dropdown-item @click.native="handleTakeCar">提车</el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <!-- 挂单中 未完工 -->
            <template v-else>
              <el-button @click="strandedNoteDialogEvent">滞留说明</el-button>
            </template>
          </div>

          <!-- 车单状态: 已结账 -->
          <div v-else-if="billMessage.billState == 1">
            <el-dropdown placement="top" split-button style="margin: 0px 10px" trigger="click">
              <el-popover placement="top-start" trigger="hover" width="729">
                <p class="tip-word">
                  <span>结账收款</span>
                  <i
                    v-if="billMessage.billType == 1 && billMessage.billState == 1"
                    class="iconfont grow-icon_warn_32 orange"
                    style="margin: 0px 4px">
                    <span>包干业务，收款金额即为包干金额，结账当月显示为查询时点的预分摊金额，结账次月显示为实际分摊金额</span>
                  </i>
                </p>
                <el-table
                  :data="payTypeTable"
                  :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '500',
                  }"
                  border>
                  <el-table-column label="序号" type="index" width="60"></el-table-column>
                  <el-table-column label="支付方式" mix-width="180" prop="payType"></el-table-column>
                  <el-table-column label="金额" mix-width="100" prop="money"></el-table-column>
                  <template slot="append">
                    <div class="table-append">
                      <div class="table_footer">
                        <p>结账收银员：{{ payInfo.cashier }}</p>
                        <p>结账时间：{{ payInfo.checkDate }}</p>
                        <p>收款总额：￥{{ payInfoTotal | toThousands }}</p>
                      </div>
                      <p class="table_footer_deriptt">结账备注：{{ payInfo.remark }}</p>
                    </div>
                  </template>
                </el-table>
                <p v-if="hasChargeOffs" class="cancel_word">
                  销账收款
                  <span class="orange">未销账：￥</span>
                  <span class="orange">{{ (notChargeOffs - chargeOffsAmount) | toThousands }}</span>
                </p>
                <el-table
                  v-if="hasChargeOffs"
                  :data="chargeOffsTable"
                  :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '500',
                  }"
                  :span-method="arraySpanMethod"
                  border>
                  <el-table-column label="序号" prop="index" width="60"></el-table-column>
                  <el-table-column label="销账收款单号" mix-width="180" prop="writeOffNum"></el-table-column>
                  <el-table-column label="销账时间" prop="repaymentDateTime" width="100"></el-table-column>
                  <el-table-column label="销账收银员" min-width="100" prop="cashier"></el-table-column>
                  <el-table-column label="支付方式" prop="payType" width="100"></el-table-column>
                  <el-table-column label="收款金额" min-width="100" prop="money"></el-table-column>
                </el-table>

                <div slot="reference">支付明细</div>
              </el-popover>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-auth="120" @click.native="reverseSettlementDialogEvent">反结算</el-dropdown-item>
                <template v-if="billMessage.takeTime">
                  <el-dropdown-item @click.native="handleRevocationTakeCar">撤销提车</el-dropdown-item>
                </template>
                <template v-else>
                  <el-dropdown-item v-auth="451" @click.native="strandedNoteDialogEvent">滞留说明</el-dropdown-item>
                  <el-dropdown-item @click.native="handleTakeCar">提车</el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-dropdown placement="top" size="small" style="margin-left: 10px" trigger="click" @command="handlePrint">
            <el-button>
              打印
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="settlement">结算单</el-dropdown-item>
              <!-- <el-dropdown-item command="receipt"
                >结算单(小票)</el-dropdown-item
              > -->
              <!-- <el-dropdown-item command="offer">报价单</el-dropdown-item> -->
              <el-dropdown-item command="material">材料单</el-dropdown-item>
              <!-- <el-dropdown-item command="proxy">委托书</el-dropdown-item> -->
              <el-dropdown-item command="dispatch">派工单</el-dropdown-item>
              <el-dropdown-item command="customerConfirm">接待单</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </template>

    <!-- 材料单抽屉 -->
    <material-bill-drawer
      v-if="materialBillDrawerVisibility"
      :createCompanyId="otherData.companyId"
      :createCompanyName="otherData.operateCompanyName"
      :materialBillId="otherData.materialBillId"
      :visibility.sync="materialBillDrawerVisibility"></material-bill-drawer>
    <!-- 检车单抽屉 -->
    <inspect-car-drawer
      v-if="inspectCarDrawerVisibility"
      :billId="id"
      :billState="billMessage.billState"
      :billType="billMessage.billType"
      :typeStatus="typeStatus"
      :visibility.sync="inspectCarDrawerVisibility"></inspect-car-drawer>
    <!-- 挂单弹窗 -->
    <car-bill-suspend-dialog
      v-if="carBillSuspendDialogVisibility"
      :billId="id"
      :visibility.sync="carBillSuspendDialogVisibility"
      @save="getData"></car-bill-suspend-dialog>
    <!-- 取消接待的弹窗 -->
    <el-dialog :before-close="handleCloses" :close-on-click-modal="false" :visible.sync="cancelReceptionDialog" top="15%" width="40%">
      <template slot="title">
        <p>
          <span>取消接待</span>
          <i class="iconfont grow-icon_warn_32 orange" style="margin: 0px 4px"></i>
          <span style="font-size: 14px; color: #999999">确定取消接待，接车单转为“已取消”状态，且无法恢复重复服务</span>
        </p>
      </template>
      <el-input v-model="cancelTextarea" :rows="4" placeholder="请输入取消接待原因（必填）" type="textarea"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="cancelTipsDialogEvent">确 定</el-button>
        <el-button size="small" @click="handleCloses">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 取消接待点击确认时不能取消情况下的提示弹窗 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="cancelTipsDialog" title="提示" top="15%" width="30%">
      <p style="text-align: center">定损单非待报损状态无法取消，请先撤销至待报损状态</p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelTipsDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="jump">跳转</el-button>
      </span>
    </el-dialog>
    <!-- 提交检车的提示弹窗 -->
    <el-dialog
      :before-close="closeCarTips"
      :close-on-click-modal="false"
      :visible.sync="submitCarTipsDialog"
      title="温馨提示"
      top="15%"
      width="20%">
      <p style="text-align: center; margin-bottom: 10px">确认要提交到车间检车项目？</p>
      <p style="text-align: center">
        <i class="iconfont grow-icon_warn_32 orange">
          <span style="font-size: 12px; color: #999999">注：操作成功，有【提交检车】通知推送给有接收权限的员工</span>
        </i>
      </p>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="closeCarTips">确 定</el-button>
        <el-button size="small" @click="closeCarTips">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 重新服务的弹窗 -->
    <el-dialog :before-close="handleCloses" :close-on-click-modal="false" :visible.sync="toServiceDialog" top="15%" width="35%">
      <template slot="title">
        <p>
          <span style="font-size: 16px">重新服务</span>
          <i class="iconfont grow-icon_warn_32 orange" style="margin: 0px 4px"></i>
          <span style="font-size: 14px; color: #999999">确定重新服务，接车单将返回到服务中状态</span>
        </p>
      </template>
      <div class="remark-box">
        <el-input v-model="resetServiceReason" :rows="4" placeholder="请输入重新服务原因（必填）" type="textarea"></el-input>
      </div>
      <template slot="footer">
        <el-button size="small" type="primary" @click="resetService">确 定</el-button>
        <el-button size="small" @click="toServiceDialog = false">取 消</el-button>
      </template>
    </el-dialog>
    <!-- 反结算的弹窗 -->
    <el-dialog :before-close="handleCloses" :close-on-click-modal="false" :visible.sync="reverseSettlementDialog" top="15%" width="35%">
      <template slot="title">
        <p>
          <span style="font-size: 16px">反结算</span>
          <i class="iconfont grow-icon_warn_32 orange" style="margin: 0px 4px"></i>
          <span style="font-size: 14px; color: #999999">如之前结账有使用优惠券，反结算后将不返还</span>
        </p>
      </template>
      <div class="remark-box">
        <el-input v-model="reverseSettlementReason" :rows="4" placeholder="请输入反结算的原因（必填）" type="textarea"></el-input>
      </div>

      <template slot="footer">
        <div class="flex-x-end flex-y-end">
          <!-- <div>
            <el-checkbox
              v-model="reverseSettlement"
              :label="reverseSettlement ? '车辆有返场' : '车辆未返场'"
            ></el-checkbox>
          </div> -->
          <div>
            <el-button size="small" type="primary" @click="reverseSettlements">确 定</el-button>
            <el-button size="small" @click="reverseSettlementDialog = false">取 消</el-button>
          </div>
        </div>
      </template>
    </el-dialog>

    <!-- 提交结账弹窗 -->
    <invoicing-dialog
      v-if="invoicingDialog"
      :billId="billMessage.billId"
      :billType="billMessage.billType"
      :customer="carInfo"
      :invoicingDialog.sync="invoicingDialog"
      :money="otherData.paid"
      :serviceTableData="serviceTableData"
      :takeCar="billMessage.takeTime"
      @confirm="getData"
      @editMaterialBill="handleEditMaterialBill"></invoicing-dialog>
    <!-- 待结账状态下底部的结账按钮弹窗 -->
    <stay-invoicing-dialog
      v-if="stayInvoicingDialog"
      :amountOfDocuments="otherData.paid || otherData.total"
      :beHang="otherData.beHang"
      :billId="billMessage.billId"
      :billType="billMessage.billType"
      :carInfo="carInfo"
      :companyId="companyId"
      :customerName="carInfo.clientName"
      :insuranceCompanyName="carBillInsuranceInfo.claimsInsuranceCompanyName"
      :orderTime="billMessage.dateCreated"
      :otherData="otherData"
      :payerInfo="payerInfo"
      :stayInvoicingDialog.sync="stayInvoicingDialog"
      :undertakeInsuranceCompanyName="carBillUndertakeInfo.undertakeInsuranceCompanyName"
      @updateData="getData"></stay-invoicing-dialog>
    <!-- 挂单中-撤销挂单 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="CancellationAreCancelledDialog" title="撤销挂单" top="15%" width="30%">
      <div style="text-align: center; color: #666666; font-size: 14px">确定撤销挂单，返回服务中，重新服务车辆？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="CancellationConfirm">确 定</el-button>
        <el-button size="small" @click="CancellationAreCancelledDialog = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 变更业务类型 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="ChangesCarTypeDialog" title="变更业务类型" top="15%" width="30%">
      <div style="display: flex">
        <p style="margin-top: 13px">变更为：</p>
        <p></p>
        <div class="custom-tabs nopadding">
          <div
            :class="{
              'custom-tab-item': true,
              active: typeStatus == 0,
              disabled: billMessage.billType == 0,
            }"
            @click="typeStatus = 0">
            自费
          </div>
          <div
            :class="{
              'custom-tab-item': true,
              active: typeStatus == 2,
              disabled: billMessage.billType == 2,
            }"
            @click="typeStatus = 2">
            理赔
          </div>
          <div
            :class="{
              'custom-tab-item': true,
              active: typeStatus == 3,
              disabled: billMessage.billType == 3,
            }"
            @click="typeStatus = 3">
            索赔
          </div>
          <div
            :class="{
              'custom-tab-item': true,
              active: typeStatus == 1,
              disabled: billMessage.billType == 1,
            }"
            @click="typeStatus = 1">
            包干
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="ChangesCarTypeDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="ChangesCarTypeComfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑物料单 -->
    <edit-material-bill-dialog
      v-if="editMaterialBillDialogVisibility"
      :id="otherData.materialBillId"
      :billId="billMessage.billId"
      :carId="carInfo.carId"
      :visibility.sync="editMaterialBillDialogVisibility"
      @save="getData"></edit-material-bill-dialog>

    <!-- 客户备注抽屉 -->
    <remarks-drawer
      v-if="remarksDrawerVisibility"
      :clientRemark="clientRemark"
      :remarksDrawerVisibility.sync="remarksDrawerVisibility"></remarks-drawer>

    <!-- 车辆绑定客户 -->
    <car-binding-customer
      v-if="carBindingCustomerDialogVisibility"
      :carId="carInfo.carId"
      :visibility.sync="carBindingCustomerDialogVisibility"
      @binding="handleBindingClient"></car-binding-customer>
    <!-- 商品出库与报价异常弹窗 -->
    <goods-exception-dialog
      :inconsistentPricesList="inconsistentPricesList"
      :inconsistentQuantityList="inconsistentQuantityList"
      :visibility.sync="goodsExceptionDialogVisibility"></goods-exception-dialog>
    <!-- 定损单详情弹窗 -->
    <car-loss-record-drawer
      v-if="lossRecordDrawer"
      :billId="this.id"
      :carLossDeterminationBillId="carBillInsuranceInfo.carLossDeterminationBillId"
      :visibility.sync="lossRecordDrawer"
      @updateData="getData()"></car-loss-record-drawer>

    <div style="position: fixed; left: -9999px">
      <settlementPrintTemplate ref="settlementPrintTemplate"></settlementPrintTemplate>
      <dispatchPrintTemplate ref="dispatchPrintTemplate"></dispatchPrintTemplate>
      <materialPrintTemplate ref="materialPrintTemplate"></materialPrintTemplate>
      <customerConfirmPrintTemplate ref="customerConfirmPrintTemplate"></customerConfirmPrintTemplate>
    </div>
    <videoPlayerDialog
      v-if="showVideoPlayerDialog"
      :index="previewVideoIndex"
      :videos="previewVideos"
      :visibility.sync="showVideoPlayerDialog"></videoPlayerDialog>
    <!-- 客户指令 -->
    <client-command-drawer
      v-if="clientCommandDrawerVisibility"
      :clientId="carInfo.clientId"
      :defaultActiveName="billMessage.billType | dict('defaultActiveName')"
      :visibility.sync="clientCommandDrawerVisibility"></client-command-drawer>
    <!-- 流程详情抽屉 -->
    <historical-operation-drawer
      v-if="historicalDrawerVisibility"
      :billId="billMessage.billId"
      :visibility.sync="historicalDrawerVisibility"></historical-operation-drawer>
    <!-- 更新滞留说明弹窗 -->
    <stranded-note-dialog
      v-if="strandedNoteDialogVisibility"
      :billId="id"
      :visibility.sync="strandedNoteDialogVisibility"></stranded-note-dialog>
    <!-- 待结账状态下编辑车单弹窗 -->
    <edit-car-bill-dialog
      v-if="editCarBillDialogVisibility"
      :billId="id"
      :billType="billMessage.billType"
      :carBillInfo="billMessage"
      :clientInfo="clientInfo"
      :isSpecificClient="isSpecificClient()"
      :remark="billMessage.companyRemark"
      :visibility.sync="editCarBillDialogVisibility"
      @save="getData"></edit-car-bill-dialog>

    <!-- 协议报价弹窗 -->
    <el-dialog v-if="agreementPriceDialogVisibility" :visible.sync="agreementPriceDialogVisibility" title="协议报价" top="10vh" width="94%">
      <div class="agreementPriceDialog-clientInfo">
        {{ carInfo.clientName }}
        <template v-if="carInfo.companyFullName">/ {{ carInfo.companyFullName }}</template>
        <template v-if="carInfo.clientName != clientInfo.consumerName">
          <i class="el-icon-warning orange el-icon--left"></i>
          当前车单消费客户为“{{ clientInfo.consumerName }}”与签约客户不一致，本单不按协议报价
        </template>
      </div>
      <agreement-price-list :id="carAgreementId" :watermarkText="watermarkText" showSearch watermark></agreement-price-list>
    </el-dialog>

    <!-- 服务商品报价与消费客户协议价不一致弹窗 -->
    <inconsistent-agreement-price-dialog
      v-if="inconsistentAgreementPriceDialogVisibility"
      :id="id"
      :customerName="payerInfo.consumerName"
      :list="inconsistentAgreementPriceList"
      :visibility.sync="inconsistentAgreementPriceDialogVisibility"></inconsistent-agreement-price-dialog>
  </base-container>
</template>
<script>
import uploadFileList from "@/components/uploadFileList";
// 编辑物料单
import editMaterialBillDialog from "@/components/businessComponent/editMaterialBillDialog";
//材料单抽屉
import materialBillDrawer from "@/components/businessComponent/materialBillDrawer.vue";
//检车单抽屉
import inspectCarDrawer from "@/components/businessComponent/inspectCarDrawer.vue";
//提交结账弹窗
import invoicingDialog from "@/views/carBill/carBillDetail/popoverAndDrawer/invoicingDialog.vue";
//待结账状态下的结账弹窗
import stayInvoicingDialog from "@/views/carBill/carBillDetail/popoverAndDrawer/stayInvoicingDialog.vue";
//客户备注弹窗
import remarksDrawer from "@/views/carBill/editCarBill/remarksDrawer.vue";
//流程详情弹窗
import historicalOperationDrawer from "@/components/businessComponent/historicalOperationDrawer.vue";
// 绑定客户弹窗
import carBindingCustomer from "@/components/businessComponent/carBindingCustomer";
// 商品与出库异常弹窗
import goodsExceptionDialog from "@/views/carBill/carBillDetail/popoverAndDrawer/goodsExceptionDialog.vue";
// 定损单详情弹窗
import carLossRecordDrawer from "@/views/carBill/carLossDeterminationBill/carLossDeterminationBillDetailDrawer.vue";
import clientCommandDrawer from "@/components/businessComponent/clientCommandDrawer";

// 滞留说明弹窗
import strandedNoteDialog from "@/components/businessComponent/strandedNoteDialog";
// 待结账状态编辑车单弹窗
import editCarBillDialog from "@/components/businessComponent/editCarBillDialog";
// 挂单弹窗
import carBillSuspendDialog from "./popoverAndDrawer/carBillSuspendDialog.vue";
// 协议报价弹窗
import agreementPriceList from "@/views/customerManagement/agreement/agreementDetail/agreementPriceList.vue";
// 提交时校验 服务商品报价与消费客户协议价不一致弹窗
import inconsistentAgreementPriceDialog from "./popoverAndDrawer/inconsistentAgreementPriceDialog.vue";

import videoPlayerDialog from "@/components/videoPlayerDialog.vue";
// 打印
import settlementPrintTemplate from "@/components/printTemplate/settlementPrintTemplate.vue";
import dispatchPrintTemplate from "@/components/printTemplate/dispatchPrintTemplate.vue";
import materialPrintTemplate from "@/components/printTemplate/materialPrintTemplate.vue";
import customerConfirmPrintTemplate from "@/components/printTemplate/customerConfirmPrintTemplate.vue";
import { hasAuth, verifyAuth } from "@/utils/permissions";
// 字典
import { carBillStateIcon, dutyType, lossDeterminationState } from "@/service/dict/dictData";
// 接口
import {
  carBillLinkContent,
  checkCarBillUpdate,
  checkQuotationApi,
  getCarBillCancelReception,
  getCarBillDetail,
  getCarBillNoServiceTake,
  getCarBillReturnService,
  getCarBillReverseSettlement,
  getCarDetectionDisposeState,
  getCarQueryWarranty,
  getClientSettlementWay as getClientSettlementWayApi,
  getMaterialBillDetails,
  recordPrintLog,
  revocationTakeCarApi,
  submitDispatchBill,
  takeCarApi,
} from "@/api/carBill";

//支付明细接口-销账接口+支付方式接口
import { clientPersonalAccounts } from "@/api/customer/customer";

// 方法
import verifyBeforeEditCarBill from "@/views/carBill/lock";
import { isImage, isVideo, previewImage } from "@/utils/fileApi";
import lodash from "lodash";
import { levelIcons, levelIconsWord } from "@/const/client";

import { mapMutations, mapState } from "vuex";

import { getDHMS } from "@/utils/tools/date";

import playImg from "@/assets/img/play.jpeg";

export default {
  name: "carBillDetail",
  components: {
    uploadFileList,
    materialBillDrawer,
    invoicingDialog,
    stayInvoicingDialog,
    editMaterialBillDialog,
    remarksDrawer,
    carBindingCustomer,
    goodsExceptionDialog,
    settlementPrintTemplate,
    dispatchPrintTemplate,
    materialPrintTemplate,
    customerConfirmPrintTemplate,
    carLossRecordDrawer,
    videoPlayerDialog,
    clientCommandDrawer,
    inspectCarDrawer,
    historicalOperationDrawer,
    strandedNoteDialog,
    editCarBillDialog,
    carBillSuspendDialog,
    agreementPriceList,
    inconsistentAgreementPriceDialog,
  },
  data() {
    return {
      id: null,
      // 车单类型（0：自费，1：包干，2：理赔，3：索赔）

      // 环检图片
      billImgs: [],
      // 环检图片标题
      billImgsTitleArr: [],
      // 理赔图片
      insuranceImgs: [],
      // 理赔图片标题
      insuranceImgTitles: [],
      // 索赔图片
      undertakeImgs: [],
      // 索赔图片标题
      undertakeImgTitles: [],

      // 车单信息
      billMessage: {},
      // 客户信息
      carInfo: {},
      // 他人自费客户信息
      payerInfo: {},
      // 理赔信息
      carBillInsuranceInfo: {},
      // 索赔信息
      carBillUndertakeInfo: {},
      // 服务项,
      serviceTableData: [],
      // 单据其他数据
      otherData: {},
      // 车单认领金额
      claimVo: {},
      // 车单认领明细
      carBillClaimDetailList: {},

      // 服务项总额
      serviceTotalPrice: 0,
      // 商品总额
      goodsTotalPrice: 0,

      // 商品与出库异常提示弹窗可见性
      goodsExceptionDialogVisibility: false,
      // 商品与出库是否存在异常
      goodsException: false,
      // 商品出库数量与报价数量不一致
      inconsistentQuantityList: [],
      // 商品报价小于成本价
      inconsistentPricesList: [],

      carBillStateIconDict: carBillStateIcon,
      // 客户等级对应图标
      levelIcons,
      levelIconsWord,

      tableKey: 999,

      // 材料单抽屉可见性
      materialBillDrawerVisibility: false,
      //检车单抽屉可见性
      inspectCarDrawerVisibility: false,
      // 车辆绑定客户弹窗可见性
      carBindingCustomerDialogVisibility: false,

      //挂单弹窗
      carBillSuspendDialogVisibility: false,
      textarea: "",
      //取消接待弹窗
      cancelReceptionDialog: false,
      cancelTextarea: "",
      //取消接待不符合条件时提示弹窗
      cancelTipsDialog: false,
      //提交检车关闭弹窗
      submitCarTipsDialog: false,
      checked: true,
      //重新服务弹窗
      toServiceDialog: false,
      resetServiceReason: "",
      //提交结账弹窗
      invoicingDialog: false,
      //待结账中的结账弹窗
      stayInvoicingDialog: false,
      //反结算弹窗
      reverseSettlementDialog: false,
      reverseSettlementReason: "",
      reverseSettlement: false,
      //挂单中-撤销挂单
      CancellationAreCancelledDialog: false,
      //判断施工图片是否存在的字段
      isHasPictures: false,

      // 编辑物料单弹窗可见性
      editMaterialBillDialogVisibility: false,

      //客户备注抽屉可见性
      remarksDrawerVisibility: false,
      clientRemark: "",

      //支付明细中的字段
      payTypeTable: [],
      payInfo: {},
      payInfoTotal: "",
      chargeOffsTable: [],
      notChargeOffs: "", //结账收款表中的挂账金额
      chargeOffsAmount: "", // 销账金额合计
      hasChargeOffs: false,
      //变更业务类型弹窗可见性
      ChangesCarTypeDialog: false,
      typeStatus: -1,
      //定损单详情弹窗可见性
      lossRecordDrawer: false,

      previewVideos: [],
      previewVideoIndex: 0,
      showVideoPlayerDialog: false,
      playImg,

      //质保状态
      underWarrantyState: "",
      vehicleVo: [], //整车质保
      threePowerVo: [], //三电质保
      powerBatteryVo: [], //动力电池质保
      batteryVo: [], //电芯质保
      wearingPartsVo: [], //易损件质保

      //客户指令抽屉可见性
      clientCommandDrawerVisibility: false,
      carDisposeStateNum: "",
      //流程详情抽屉可见性
      historicalDrawerVisibility: false,

      // 更新滞留说明弹窗可见性
      strandedNoteDialogVisibility: false,
      // 待结账状态下编辑车单弹窗可见性
      editCarBillDialogVisibility: false,

      // 数据是否加载完毕
      dataLoaded: false,

      // 客户协议价
      agreementPriceDialogVisibility: false,

      // 车单服务商品存在与消费客户协议价不一致
      inconsistentAgreementPriceList: [],
      inconsistentAgreementPriceDialogVisibility: false,

      // 消费客户 现结/月结
      settlementWay: "",
      servicesTableMaxHeight: 300,
    };
  },
  computed: {
    ...mapState({
      companyId: state => state.user.userInfo.companyId,
      // 是否显示成本
      showCost: state => state.user.showCost,
    }),
    distancePlannedTime() {
      if (this.billMessage.planFinishTime) {
        let now = new Date();
        let plan = new Date(this.billMessage.planFinishTime);
        let difference = plan - now;
        let word = difference > 0 ? "剩余" : "已超时";
        difference = Math.abs(difference);
        let res = getDHMS(difference);
        return `${word}${res[0] ? res[0] + "天" : ""}${res[1]}时${res[2]}分`;
      } else {
        return "";
      }
    },
    clientInfo() {
      var val;
      switch (this.billMessage.billType) {
        case (0, 1):
          val = this.payerInfo;
          break;
        case 2:
          if ([0, 1].includes(this.otherData.isInsuranceTurnSelf)) {
            val = {
              consumerName: this.otherData.beTurnConsumer,
            };
          } else {
            val = {
              consumerName: this.carBillInsuranceInfo.claimsInsuranceCompanyName,
            };
          }
          break;
        case 3:
          val = {
            consumerName: this.carBillUndertakeInfo.undertakeInsuranceCompanyName,
          };
          break;

        default:
          val = this.payerInfo;
      }
      return val;
    },
    carAgreementId() {
      return this.$lodash.get(this.carInfo, "billClientMemberMessageVo.agreementClientId", 0);
    },
    customerAgreementId() {
      return this.$lodash.get(this.payerInfo, "billClientMemberMessageVo.agreementClientId", 0);
    },
    watermarkText() {
      return `${this.$store.state.user.userInfo.realName} ${this.$store.state.user.companyName} ${new Date().toLocaleString()}`;
    },
  },
  watch: {
    //如果$route.fullPath改变了，就是从当前页面离开了，就销毁掉全局的监听事件，不然这全局事件会一直存在，造成内存泄漏！
    "$route.fullPath": {
      handler() {
        console.log("window----->>>", window);
        console.log("window.removeEventListener----->>>", window?.removeEventListener);
        window?.removeEventListener("resize", this.handleResize);
      },
    },
  },
  beforeDestroy() {
    console.log("beforeDestroy------->>>");
    // window?.removeEventListener("resize", this.handleResize);
  },
  destroyed() {
    console.log("destroyed------->>>");
  },
  mounted() {
    console.log("window--->>>", window);
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.getData();
    this.checkCarQueryWarranty();
    this.getCarDisposeState();
    // 首次加载时也计算高度
    // this.calculateHeight();
    this.setTableHei()
  },
  methods: {
    ...mapMutations(["changeShowCostState"]),
    handleResize: lodash.debounce(function () {
      // 这里调用你的高度计算函数
      // this.calculateHeight();
      this.setTableHei()
    }, 500),
    calculateHeight() {
      // 假设你想基于某些逻辑计算高度
      // 例如，让高度等于窗口高度的80%
      const height0 = document.getElementsByClassName("main-view")[0]?.offsetHeight || 0;
      const height1 = document.getElementById("uq-car-customer-info")?.offsetHeight || 0;
      const height2 = document.getElementById("uq-car-bill-info-form")?.offsetHeight || 0;
      const height3 = document.getElementById("uq-car-bill-services-header")?.offsetHeight || 0;
      const height4 = document.getElementById("uq-car-bill-footer")?.offsetHeight || 0;
      // let height = window.innerHeight - 550;
      let height = height0 - height1 - height2 - height3 - height4 - 15;
      if (height < 350) {
        height = 350;
      }
      console.log("height:", height, this.$refs.servicesTable);
      this.servicesTableMaxHeight = height;
    },
    /** 动态控制表格高度 **/
    setTableHei(){
      this.$nextTick(()=>{
        let windowsHei = document.documentElement.clientHeight;
        let carInfoHei = this.$refs.carInfoRef.offsetHeight;
        let carBillHei = this.$refs.carBillRef.offsetHeight;
        let carBillHeaderHei = this.$refs.carBillHeaderRef.offsetHeight;
        this.servicesTableMaxHeight = windowsHei - carInfoHei - carBillHei - carBillHeaderHei - 240
      })
    },
    /**
     * 双系统录单情况，只有特定客户才展示
     */
    isSpecificClient() {
      return this.carInfo?.isDualSystem || false;
      // 环球、芯享智慧、小桔、亚滴、喜滴经租、舒适橙、橙电
      // 过期的也放进去了
      /*
      const _clientId = this.carInfo.clientId || 0;
      return [
        1701149, 2430786, 2644703, 2662917, 2817977, 2817984, 2818460, 3488578,
        4019765, 4093652, 4427814, 4494515, 4866950, 4868852, 4901353, 4919403,
        4928895, 4928928, 4937689, 4952039, 5072013, 5844812, 5846879, 5908015,
        6654376, 6844802, 6845302, 7150651, 7390929, 7503534, 7503709, 7503800,
        7503865, 7503895, 7503902, 7503915, 7503982, 7504203, 7504206, 7504271,
        7504299, 7504315, 7504326, 7504355, 7504391, 7504407, 7504429, 7504601,
        7504676, 7504677, 7504687, 7504754, 7504792, 7504798, 7506711, 7506766,
        7506841, 7506896, 7507152, 7507166, 7507191, 7507355, 7507439, 7507519,
        7507552, 7507581, 7507585, 7507586, 7507608, 7507723, 7507791, 7507875,
        7508005, 7508046, 7508292, 7508412, 7508515, 7508522, 7508523, 7508732,
        7508733, 7508797, 7508798, 7508799, 7508800, 7508801, 1368261796438016,
        1380220722585600, 1390115893075968, 7507158, 7507161, 7508036, 7508346,
        7508348, 7508392, 7508396, 7508404, 7508410, 7508417, 7508441, 7508455,
        7508468, 7508475, 7508476, 7508478, 7508531, 7508534, 7508541, 7508558,
        1373005794451457, 1373005810900992, 1373005818699776, 1373006065426432,
        1384609048027136, 1384609048035328, 1384609048076288, 1386100995768320,
        1404928895303680,
      ].includes(_clientId);
      */
    },
    getData() {
      this.dataLoaded = false;
      getCarBillDetail(this.id).then(res => {
        console.log(res);
        let {
          billMessage,
          carBillClientMessage,
          carBillConsumerMessage,
          carBillInsuranceInfo,
          carBillUndertakeInfo,
          serviceItems,
          claimVo,
          carBillClaimDetailList,
          ...otherData
        } = res;
        this.$set(this, "billMessage", billMessage || {});
        // this.billMessage = billMessage || {};
        this.carInfo = carBillClientMessage || {};
        this.payerInfo = carBillConsumerMessage || {};
        this.carBillInsuranceInfo = carBillInsuranceInfo || {};
        this.carBillUndertakeInfo = carBillUndertakeInfo || {};
        this.claimVo = claimVo || {};
        this.carBillClaimDetailList = carBillClaimDetailList || {};
        // this.serviceTableData = serviceItems || [];
        this.otherData = otherData;

        // 单据类型为自费 && 单据状态不为已结账、已取消 && 有消费客户ID  获取消费客户月结、现结状态
        if (this.billMessage.billType === 0 && ![1, 3].includes(this.billMessage.billState) && this.payerInfo.consumerId) {
          this.getClientSettlementWay(this.payerInfo.consumerId);
        }

        // 理赔单 &&  勾选了免赔或者转自费后的消费客户，要显示现结或者月结
        if (
          this.billMessage.billType === 2 &&
          [0, 1].includes(otherData.isInsuranceTurnSelf) &&
          ![1, 3].includes(this.billMessage.billState)
        ) {
          this.getClientSettlementWay(otherData.beTurnConsumerId);
        }

        // 环检图片
        let billImgs = [];
        let billImgsTitleArr = [];
        [
          { title: "车身正面", key: "carBodyPositivePic" },
          { title: "车身左侧", key: "carBodyLeftPic" },
          { title: "车身后面", key: "carBodyBehindPic" },
          { title: "车身右侧", key: "carBodyRightPic" },
          // 'carRoofPic',
          { title: "行驶证", key: "runningLicenceImg" },
          { title: "仪表盘", key: "carInstrumentPanelPic" },
          { title: "车辆铭牌", key: "vehicleNameplateImg" },
          { title: "电池铭牌", key: "batteryNameplateImg" },
        ].forEach(item => {
          if (billMessage?.carBodyInfoVo?.[item.key]) {
            billImgs.push(billMessage.carBodyInfoVo[item.key]);
            billImgsTitleArr.push(item.title);
          }
        });
        if (billMessage?.carBodyInfoVo?.carOtherPics?.length) {
          billImgs.push(...billMessage?.carBodyInfoVo?.carOtherPics);
          billImgsTitleArr.push(...billMessage?.carBodyInfoVo?.carOtherPics.map(() => "其他图片"));
        }
        this.billImgs = billImgs;
        this.billImgsTitleArr = billImgsTitleArr;

        // 理赔图片
        let insuranceImgs = [];
        [
          // 'runningLicenceImg',
          "picDrivingLicence",
          "feeListPicA",
          "feeListPicB",
          "feeListPicC",
        ].forEach(key => {
          if (carBillInsuranceInfo?.[key]) {
            insuranceImgs.push(carBillInsuranceInfo[key]);
          }
        });
        if (carBillInsuranceInfo?.feeListPicOthers?.length) {
          insuranceImgs.push(...carBillInsuranceInfo?.feeListPicOthers);
          this.insuranceImgTitles.push(...carBillInsuranceInfo?.feeListPicOthers.map(() => "其他理赔图片"));
        }
        this.insuranceImgs = insuranceImgs;

        // 索赔图片
        let undertakeImgs = [];
        if (carBillUndertakeInfo.undertakePics?.length) {
          undertakeImgs.push(...carBillUndertakeInfo.undertakePics);
          this.undertakeImgTitles.push(...carBillUndertakeInfo.undertakePics.map(() => "索赔图片"));
        }
        this.undertakeImgs = undertakeImgs;

        var serviceTableData = serviceItems.map(service => {
          service.id = service.billServiceId;
          if (!service.id) {
            service.id = Math.random();
            service.name = "提示: 只出库未报价商品客户不可见不打印";
            service.emptyService = true;
            service.goodsInfoVos?.forEach(g => {
              g.showAllNum = true;
              g.num = 0;
            });
          } else {
            service.name = service.serviceItemName;
          }
          service.code = service.serviceItemCode;

          service.billGoods = service.goodsInfoVos;
          if (service?.billGoods?.length) {
            service.billGoods?.forEach(item => {
              item.id = item.billGoodsId;
              if (!item.id) item.id = Math.random();
              item.name = item.goodsName;
              item.code = item.goodsCode;
              item.parentId = service.id;

              /**
               * 判断是否显示待领
               * 当报价单商品=材料单商品数，且商品待领数非0时，显示待领数量
               * 当报价商品!=材料单商品时，显示当前报价数、待领数、已领数。当待领数为0时，不显示待领0 字样。
               */
              let materialCount = (item.pickedNum || 0) + (item.waitPickNum || 0);
              if ((materialCount && item.num != materialCount) || item.waitPickNum > 0) {
                item.showAllNum = true;
              }
            });
          }
          // 处理故障、完工图片视频
          if (service.preConstructionPics) {
            let pre = [...service.preConstructionPics];
            let preImages = [];
            let preVideos = [];
            pre.forEach(url => {
              // 图片
              if (isImage(url)) {
                preImages.push(url);
              } else if (isVideo(url)) {
                // 视频
                preVideos.push(url);
              }
            });
            service.preImages = preImages;
            service.preVideos = preVideos;
            // 封面
            if (preImages.length) {
              service.prePoster = preImages[0];
            } else {
              service.prePoster = playImg;
            }
          }
          if (service.afterConstructionPics) {
            let after = [...service.afterConstructionPics];
            let afterImages = [];
            let afterVideos = [];
            after.forEach(url => {
              // 图片
              if (isImage(url)) {
                afterImages.push(url);
              } else if (isVideo(url)) {
                // 视频
                afterVideos.push(url);
              }
            });
            service.afterImages = afterImages;
            service.afterVideos = afterVideos;
            // 封面
            if (afterImages.length) {
              service.afterPoster = afterImages[0];
            } else {
              service.afterPoster = playImg;
            }
          }

          return service;
        });
        this.setIndex(serviceTableData);
        this.serviceTableData = serviceTableData;
        this.calculateTotalAmount();
        console.log("serviceTableData", this.serviceTableData);
        if (this.billMessage.billState == 1) {
          this.getPaymentDetails();
        }
        this.checkGoodsException();

        this.dataLoaded = true;
      });
    },
    //获取检车单处理状态
    getCarDisposeState() {
      getCarDetectionDisposeState(this.id).then(res => {
        this.carDisposeStateNum = res;
      });
    },
    /**
     * 表格合并方法
     */
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if ([8, 10, 11, 12].includes(columnIndex)) {
        if (!row.parentId) {
          var length = row.billGoods?.length || 0;
          if (length) {
            return {
              rowspan: length + 1,
              colspan: 1,
            };
          } else {
            return [1, 1];
          }
        } else {
          return [0, 0];
        }
      }
    },
    /**
     * 设置序号
     */
    setIndex(arr, indexArr = [], children = "billGoods") {
      arr.forEach((item, index) => {
        let cloneArr = [];
        if (indexArr.length) {
          cloneArr = indexArr.concat(index + 1);
        } else {
          cloneArr.push(index + 1);
        }
        item.title = cloneArr.join(".");
        if (item[children]?.length) {
          this.setIndex(item[children], cloneArr);
        }
      });
    },
    /**
     * 计算总金额
     */
    calculateTotalAmount() {
      // 服务项目报价
      var servicePrice = 0;
      // 商品报价
      var goodsPrice = 0;

      this.serviceTableData.forEach(service => {
        let itemTotalBefore = 0;
        if (+service.total) {
          servicePrice += +service.total;
          itemTotalBefore += +service.total;
        }
        if (service.billGoods?.length) {
          service.billGoods.forEach(item => {
            if (+item.total) {
              goodsPrice += +item.total;
              itemTotalBefore += +item.total;
            }
          });
        }
        if (!service.itemTotalBefore) service.itemTotalBefore = itemTotalBefore;
      });
      this.serviceTotalPrice = servicePrice;
      this.goodsTotalPrice = goodsPrice;
    },

    /**
     * 编辑车单
     */
    async editCarBill() {
      let result = await verifyBeforeEditCarBill(this.id);
      if (!result) return;
      let route = {
        name: "editCarBill",
        path: "/carBill/editCarBill",
        fullPath: `/carBill/editCarBill?id=${this.billMessage.billId}`,
        query: {
          type: this.billMessage.billType,
          id: this.billMessage.billId,
          typeStatus: this.typeStatus, //多加一个变更业务的判断字段
        },
      };
      this.$store.dispatch("REPLACE_TAG", route);
    },
    // 检测车单是否锁定 如未锁定则继续显示对应弹窗
    async carBillLock(dialog, verify = true) {
      if (verify) {
        let result = await verifyBeforeEditCarBill(this.id);
        if (!result) return;
      }
      this[dialog] = true;
    },
    /**
     * 跳转到认领明细报表
     */
    gotoClaimReportDetail() {
      if (hasAuth(1004)) {
        this.$router.push({
          name: "transaction_claim_detail",
          query: {
            companyId: this.otherData.companyId,
            carNumber: this.carInfo.carNumber,
            billCode: this.billMessage.billCode,
            t: new Date().getTime(),
          },
        });
      } else {
        this.$message.warning("没有查看认领明细报表权限");
      }
    },
    // 提交结账--解单/锁单
    async submitInvoicingEvent() {
      // 产品：车管端上线后再校验
      // if (
      //   [
      //     'carBodyPositivePic',
      //     'carBodyRightPic',
      //     'carBodyBehindPic',
      //     'carBodyLeftPic',
      //     'carRoofPic',
      //   ].every((item) => !this.billMessage.carBodyInfoVo[item]) &&
      //   !this.billMessage?.carBodyInfoVo?.carOtherPics?.length
      // ) {
      //   this.$alert('该单据未上传环检图片，请上传环检图片', '提示');
      //   return;
      // }

      // 判断报价项目是否上传施工图片
      // this.isHasPictures = this.serviceTableData.every(
      //   (item) =>
      //     item.preConstructionPics?.length && item.afterConstructionPics?.length
      // );
      // if (!this.isHasPictures) {
      //   this.$alert('该单据未上传施工图片，请上传施工图片', '提示');
      //   return;
      // }

      //  报价项目 技师 和 业务员 必填
      if (this.serviceTableData?.length) {
        let msgArr = [];
        this.serviceTableData.forEach(service => {
          let msg = [];
          if (service.serviceItemId && !service.technicianInfoVos?.length && !service.isConstructionFree) {
            msg.push("施工技师");
          }
          if (service.serviceItemId && !service.sellerId) {
            msg.push("业务员");
          }
          if (msg.length) {
            msgArr.push(`请设置报价项目【${service.serviceItemName}】的${msg.join("、")}`);
          }
        });
        if (msgArr.length) {
          console.log("reject", msgArr);

          const h = this.$createElement;
          this.$msgbox({
            title: "提示",
            message: h(
              "div",
              null,
              msgArr.map(item => h("p", null, item)),
            ),
            confirmButtonText: "确定",
          });
          return;
        }
      }

      // 索赔单  索赔单号必填
      if (this.billMessage.billType === 3) {
        if (!this.carBillUndertakeInfo?.claimsNum?.length) {
          this.$alert("该单据未填写索赔单号，请填写索赔单号！", "提示");
          return;
        }
      }

      // 车单施工状态必须为已完工
      if (this.otherData.checkCurrentCompanyHasEnableDispatch && ![undefined, 1, 6].includes(this.billMessage.dispatchState)) {
        this.$alert("施工单未“车间完工”，无法提交结账", "提示");
        return;
      }

      //存在待领材料未领用
      // if (
      //   this.otherData.materialBillProcessedNum <
      //   this.otherData.materialBillUnProcessedNum
      // ) {
      //   this.$confirm('当前仍有材料未出库,请先出库再提交结账', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //   })
      //     .then(() => {})
      //     .catch(() => {});
      //   return;
      // }

      // 提交结账时增加对当前里程与上次进店里程大小的判断逻辑
      if (this.otherData.checkoutState == 1) {
        this.$confirm("本次进店里程小于上次里程，请确定里程数并上传仪表盘照片再次提交结账", "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "编辑车单",
          type: "warning",
        })
          .then(() => {
            this.editCarBill();
          })
          .catch(() => {});
        return;
      } else if (this.otherData.checkoutState == 2) {
        const res = await this.$confirm("本次进店里程小于上次里程，请确定里程输入准确后提交结账", "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "提交结账",
          type: "warning",
        }).catch(() => {
          return;
        });
      } else if (this.otherData.checkoutState == 3) {
        this.$alert("请填写进店里程", "提示");
        return;
      }
      // 校验协议价
      if ([0, 1].includes(this.billMessage.billType)) {
        const res = await checkQuotationApi(this.id);
        if (!res.isConsistent) {
          // this.$message.warning('车单项目报价与协议价存在不一致');
          this.inconsistentAgreementPriceList = res.service;
          this.inconsistentAgreementPriceDialogVisibility = true;
          return;
        }
      }
      this.carBillLock("invoicingDialog");
    },
    //服务中-取消接待-解单锁单
    cancelReceptionDialogEvent() {
      if (
        !this.otherData.checkCurrentCompanyHasEnableDispatch ||
        this.billMessage.dispatchState === undefined ||
        ![0, 1, 5].includes(this.billMessage.dispatchState)
      ) {
        if (this.billMessage.lossDeterminationState === false) {
          this.cancelTipsDialog = true;
        } else {
          this.carBillLock("cancelReceptionDialog");
        }
      } else {
        this.$message.warning("施工单处于施工中/质检中/车间完工状态不能进行取消接待");
      }
    },
    //服务中-取消接待弹窗的确认按钮事件
    cancelTipsDialogEvent() {
      if (["", undefined].includes(this.cancelTextarea)) {
        this.$message.warning("请先填写取消接待原因");
        return;
      }
      var data = {
        billId: this.billMessage.billId,
        companyId: this.companyId,
        remark: this.cancelTextarea,
      };
      getCarBillCancelReception(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "取消接待成功",
          });
          this.cancelReceptionDialog = false;
          this.getData();
        }
      });
    },
    //取消接待关闭弹窗
    handleCloses() {
      this.cancelReceptionDialog = false;
      this.reverseSettlementDialog = false;
      this.toServiceDialog = false;
    },
    //服务中-变更业务类型事件
    ChangesCarTypeDialogEvent() {
      this.carBillLock("ChangesCarTypeDialog");
    },
    //服务中-变更业务类型确认按钮事件
    ChangesCarTypeComfirm() {
      if ([-1, ""].includes(this.typeStatus)) {
        this.$message({
          type: "warning",
          message: "请选择业务",
        });
        return;
      }
      // if (this.typeStatus === 0) {
      //   this.$message({
      //     message: '注:转自费业务无法对已添加的项目带入协议',
      //     type: 'warning',
      //   });
      // }
      var data = {
        billId: this.billMessage.billId,
        updateBillType: this.typeStatus,
      };
      checkCarBillUpdate(data).then(res => {
        if (res.type !== 1) {
          this.editCarBill();
        } else {
          this.$alert(res.message);
          this.typeStatus = -1;
          return;
        }
      });
    },
    //提交检车关闭弹窗
    closeCarTips() {
      this.submitCarTipsDialog = false;
    },
    /**
     * 提交施工
     */
    async submitConstruction() {
      let result = await verifyBeforeEditCarBill(this.id);
      if (!result) return;
      let list = this.serviceTableData.filter(item => [0, 5].includes(item.dispatchTaskState));
      if (!list.length) {
        this.$alert("当前车单服务项不存在待派工的项目，无需重复提交施工", "提示");
        return;
      }
      this.confirmSubmit();
    },
    /**
     * 确认提交施工
     */
    confirmSubmit() {
      submitDispatchBill(this.billMessage.billId).then(res => {
        this.$message.success("提交施工成功");
        this.getData();
      });
    },
    //服务中-底部按钮-更多操作-挂单-解单/锁单
    pickUpCarDialogEvent() {
      this.carBillLock("carBillSuspendDialogVisibility");
    },
    noServiceTake() {
      if (["", undefined].includes(this.textarea)) {
        return this.$message.warning("请先填写挂单原因");
      }
      var data = {
        billId: this.billMessage.billId,
        companyId: this.companyId,
        remark: this.textarea,
      };
      getCarBillNoServiceTake(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "挂单成功!",
          });
          this.carBillSuspendDialogVisibility = false;
          this.getData();
        }
      });
    },
    //待结账中底部按钮-结账-解单/锁单
    stayInvoicingDialogEvent() {
      this.carBillLock("stayInvoicingDialog", false);
    },
    //待结账中底部按钮-更多操作-重新服务
    handleResetService() {
      // 取消 【待结账】状态 操作【重新服务】的并发检验
      this.carBillLock("toServiceDialog", ![2, 5].includes(this.billMessage.billState));
    },
    resetService() {
      if (!this.resetServiceReason) {
        this.$message.warning("请输入重新服务的原因");
      } else {
        var data = {
          billId: this.billMessage.billId,
          companyId: this.companyId,
          remark: this.resetServiceReason,
        };
        getCarBillReturnService(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "重新服务成功!",
            });
            this.toServiceDialog = false;
            this.getData();
          }
        });
      }
    },
    //已结账-更多操作-反结算-接单/锁单
    reverseSettlementDialogEvent() {
      // 产品gd 测试qq 反结算不需要校验触发并发
      // this.carBillLock('reverseSettlementDialog');
      this.reverseSettlementDialog = true;
    },
    reverseSettlements() {
      if (this.reverseSettlementReason === "") {
        this.$message.warning("请先填写反结算原因");
        return;
      }
      var data = {
        billId: this.billMessage.billId,
        companyId: this.companyId,
        description: this.reverseSettlementReason,
      };
      getCarBillReverseSettlement(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "反结算成功!",
          });
          this.reverseSettlementDialog = false;
          this.getData();
        }
      });
    },
    CancellationAreCancelledDialogEvent() {
      this.carBillLock("CancellationAreCancelledDialog");
    },
    //挂单中-撤销挂单
    CancellationConfirm() {
      var data = {
        billId: this.billMessage.billId,
        companyId: this.companyId,
      };
      getCarBillReturnService(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "重新服务成功!",
          });
          this.CancellationAreCancelledDialog = false;
          this.getData();
        }
      });
    },

    /**
     * 填写滞留说明
     */
    strandedNoteDialogEvent() {
      this.strandedNoteDialogVisibility = true;
    },

    /**
     * 操作提车
     */
    handleTakeCar() {
      this.$confirm("确认提车?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        takeCarApi(this.id).then(res => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getData();
        });
      });
    },

    /**
     * 撤销提车
     */
    handleRevocationTakeCar() {
      this.$confirm("确认撤销提车?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        revocationTakeCarApi(this.id).then(res => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getData();
        });
      });
    },

    //跳转按钮-跳转到接车单-定损单
    async jump() {
      await verifyAuth(457);
      this.cancelTipsDialog = false;
      this.$router.push({
        name: "carLossDeterminationBillList",
        params: {
          nodeKey: 0,
          showMore: true,
          form: {
            billCode: this.billMessage.billCode,
          },
        },
      });
    },
    //表格合并
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 1, 2, 3].includes(columnIndex)) {
        if (row.isParent) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else if (row.isChild) {
          return [0, 0];
        } else {
          return [1, 1];
        }
      }
    },
    //支付明细的请求接口
    getPaymentDetails() {
      clientPersonalAccounts(this.billMessage.billId).then(res => {
        this.payInfo = res.data;
        this.chargeOffsTable = res.data.writeOff;
        this.payInfoTotal = res.data.paid
          .reduce((prev, item) => {
            return prev + +item.money || 0;
          }, 0)
          .toFixed(2);
        //判断是否存在挂账方式
        this.hasChargeOffs = res.data.paid.some(item => item.payType == "挂账");
        this.payTypeTable = res.data.paid;
        this.handleList();
        //存在挂账时取挂账的金额,此金额为销账收款的金额
        this.payTypeTable.map(item => {
          if (item.payType == "挂账") {
            this.notChargeOffs = item.money;
          }
        });
      });
    },
    // 支付明细
    handleList() {
      var arr = [];
      var cloneData = this.$lodash.cloneDeep(this.chargeOffsTable);
      let rowIndex = 0;
      cloneData.forEach(item => {
        if (item.paid?.length) {
          item.rowspan = item.paid.length;
          item.paid.forEach((el, index) => {
            if (index === 0) {
              el.index = ++rowIndex;
              el.isParent = true;
              el.rowspan = item.paid.length;
            } else {
              el.isChild = true;
            }
            arr.push(
              Object.assign(this.$lodash.cloneDeep(el), {
                id: item.id,
                cashier: item.cashier,
                repaymentDateTime: item.repaymentDateTime,
                writeOffNum: item.writeOffNum,
                money: el.money,
                payType: el.payType,
              }),
            );
          });
        } else {
          item.index = ++rowIndex;
          arr.push(item);
        }
      });
      this.chargeOffsTable = arr;
      //处理数据总额相加
      this.chargeOffsAmount = this.chargeOffsTable
        .reduce((prev, item) => {
          return prev + +item.money || 0;
        }, 0)
        .toFixed(2);
    },

    /**
     * 编辑物料单
     */
    handleEditMaterialBill() {
      // 车单结账页面已判断是否解锁
      this.editMaterialBillDialogVisibility = true;
    },
    /**
     * 查看车辆历史消费记录
     */
    async viewHistory() {
      await verifyAuth(52);
      const id = this.carInfo.carId;
      this.$router.push({
        name: "carDetail",
        query: {
          id,
        },
        params: {
          activeTabName: "second",
        },
      });
    },
    /**
     * 查看客户详情
     */
    async goClientDetail() {
      await verifyAuth(107);
      this.$router.push({
        name: "customerDetails",
        query: {
          id: this.carInfo.clientId,
        },
      });
    },
    /**
     * 查看车辆详情
     */
    async goCarDetail() {
      await verifyAuth(52);
      this.$router.push({
        name: "carDetail",
        query: {
          id: this.carInfo.carId,
        },
      });
    },
    //客户备注触发事件
    remarksDrawerVisibilityEvent(value) {
      if (value == 1) {
        this.clientRemark = this.carInfo.clientRemark;
      } else {
        this.clientRemark = this.payerInfo.consumerRemark;
      }
      this.remarksDrawerVisibility = true;
    },

    /**
     * 显示车辆绑定客户弹窗
     */
    showBindingCustomerDialog() {
      this.carBindingCustomerDialogVisibility = true;
    },

    /**
     * 处理绑定客户事件
     */
    handleBindingClient() {
      this.getData();
    },

    /**
     * 打印
     * @param command 打印内容
     */
    async handlePrint(command) {
      let {
        billMessage,
        carInfo,
        payerInfo,
        carBillInsuranceInfo,
        carBillUndertakeInfo,
        serviceTableData,
        serviceTotalPrice,
        goodsTotalPrice,
      } = this;
      billMessage.operateCompanyName = this.otherData.operateCompanyName;
      billMessage.companyAddress = this.otherData.companyAddress;
      billMessage.telephone = this.otherData.telephone;
      billMessage.alternativeTelephone = this.otherData.alternativeTelephone;

      serviceTableData = this.$lodash.cloneDeep(serviceTableData);
      serviceTableData = serviceTableData.filter(s => s.serviceItemId);

      let discountAmount = this.otherData.total - this.otherData.paid;

      // 服务列表
      let serviceList = serviceTableData.map(s => {
        return {
          name: s.serviceItemName,
          num: s.num,
          price: s.price,
          discount: s.discount,
          total: s.total,
          technicians: s.isConstructionFree
            ? "免施工"
            : this.$lodash
                .get(s, "technicianInfoVos", [])
                .map(item => item.technicianName)
                .join("、"),
        };
      });
      // 折前服务合计金额
      let beforeTotalServicePrice = serviceList.reduce((prev, item) => prev + (item.num * item.price || 0), 0);
      // 折后服务合计金额
      let afterTotalServicePrice = serviceList.reduce(
        (prev, item) => prev + (+item.total || 0),
        // (prev, item) =>
        //   prev + +item.billDiscount > 0 ? +item.billDiscount : +item.total || 0,
        0,
      );
      // 商品列表
      let goodsList = serviceTableData.reduce((prev, s) => {
        if (s.billGoods?.length) {
          return prev.concat(
            s.billGoods.map(g => ({
              name: g.goodsName,
              code: g.goodsCode,
              num: g.num,
              price: g.price,
              discount: g.discount,
              total: g.total,
            })),
          );
        } else {
          return prev;
        }
      }, []);

      // 折前商品合计金额
      let beforeTotalGoodsPrice = goodsList.reduce(
        (prev, item) => prev + (item.num * item.price || 0),

        0,
      );
      // 折后商品合计金额
      let afterTotalGoodsPrice = goodsList.reduce(
        (prev, item) => prev + (+item.total || 0),
        // (prev, item) =>
        //   prev + +item.billDiscount > 0 ? +item.billDiscount : +item.total || 0,
        0,
      );
      // 需要支付
      let needPay = this.otherData.paid;
      // 结账收银员
      let { cashier = "", paid: paidList = [] } = this.payInfo;
      let needPaidTotal = 0;
      let paidTotal = 0;
      paidList.forEach(item => {
        if (item.payType !== "挂账") paidTotal += item.money;
        else needPaidTotal += item.money;
      }, 0);
      // let needPaidTotal = needPay - paidTotal;

      let materialList = [];
      // 材料单
      if (command === "material") {
        await getMaterialBillDetails({
          id: this.otherData.materialBillId,
          companyId: this.companyId,
        }).then(res => {
          let { materialService } = res;
          materialService.forEach(item => {
            if (item.materialGoods?.length) {
              item.materialGoods.forEach(g => {
                materialList.push({
                  code: g.goods.code,
                  name: g.goods.goodsName,
                  num: g.num,
                });
              });
            }
          });
        });
      }

      // 车单标签打印
      let billLabels = [];
      if (billMessage.isReturnCar) {
        billLabels.push("退车整备");
      }
      if (billMessage.isRepair) {
        billLabels.push("返修");
      }
      if (billMessage.isSupplementaryOrder) {
        billLabels.push("补单");
      }
      if (this.otherData.isClaimUnderWarranty === 1) {
        billLabels.push("在保");
      }
      if (this.otherData.isClaimUnderWarranty === 0) {
        billLabels.push("不在保");
      }
      if (this.otherData.isClaimActivity === 1) {
        billLabels.push("有活动");
      }
      if (this.otherData.isClaimActivity === 0) {
        billLabels.push("无活动");
      }

      switch (command) {
        // 结算单
        case "settlement":
          recordPrintLog({
            billId: this.id,
            operationType: 37,
            message: "进入打印结算单",
          });
          this.$refs?.settlementPrintTemplate?.doPrint({
            billType: billMessage.billType,
            billMessage,
            carInfo,
            payerInfo,
            carBillInsuranceInfo,
            carBillUndertakeInfo,
            serviceList,
            beforeTotalServicePrice,
            afterTotalServicePrice,
            goodsList,
            beforeTotalGoodsPrice,
            afterTotalGoodsPrice,
            serviceTotalPrice,
            goodsTotalPrice,
            discountAmount,
            needPay,
            cashier,
            paidList,
            paidTotal,
            needPaidTotal,
          });
          break;
        // 派工单
        case "dispatch":
          recordPrintLog({
            billId: this.id,
            operationType: 38,
            message: "进入打印派工单",
          });
          this.$refs?.dispatchPrintTemplate?.doPrint({
            billMessage,
            carInfo,
            serviceList,
            goodsList,
            company: {
              // companyName: this.$store?.state?.user?.companyName,
              companyName: this.otherData.operateCompanyName,
            },
            userName: this.$store?.state?.user?.userInfo?.realName,
          });
          break;
        // 材料单
        case "material":
          recordPrintLog({
            billId: this.id,
            operationType: 39,
            message: "进入打印材料单",
          });
          this.$refs?.materialPrintTemplate?.doPrint({
            billMessage,
            carInfo,
            company: {
              // companyName: this.$store?.state?.user?.companyName,
              companyName: this.otherData.operateCompanyName,
            },
            materialList,
          });
          break;
        // 接待单
        case "customerConfirm":
          recordPrintLog({
            billId: this.id,
            operationType: 40,
            message: "进入打印接待单",
          });
          this.$refs?.customerConfirmPrintTemplate?.doPrint({
            billMessage,
            carInfo,
            label: billLabels.join(),
            company: {
              // companyName: this.$store?.state?.user?.companyName,
              companyName: this.otherData.operateCompanyName,
            },
          });
          break;
      }
    },

    /**
     * 预览图片
     * @param {Array<String>} imgs 图片数组
     */
    previewImgs(imgs, titles) {
      previewImage(imgs, 0, titles);
    },

    /**
     * 检查商品是否有异常
     */
    checkGoodsException() {
      let list = this.$lodash.cloneDeep(this.serviceTableData);
      let dict = {};
      list.forEach(s => {
        if (s.emptyService) s.name = "";
        let { billGoods, ...other } = s;
        delete other.goodsInfoVos;
        billGoods?.forEach(g => {
          g.type = "goods";
          if (!dict[g.materialGoodsId]) {
            dict[g.materialGoodsId] = [{ ...other, billGoods: [g] }];
          } else {
            dict[g.materialGoodsId].push({ ...other, billGoods: [g] });
          }
        });
      });

      console.log(dict);

      // 数量异常
      let inconsistentQuantityList = [];
      // 成本异常
      let inconsistentPricesList = [];

      Object.values(dict).forEach(arr => {
        let total = 0;
        let pickedTotal = 0;

        let priceTotal = 0;
        let costPriceTotal = 0;

        // 1. 已出库但报价项目中不存在此商品
        if (arr.some(s => !s.billServiceId)) {
          // 报价数量修改为0
          arr.forEach(s => {
            s.billGoods?.forEach(g => (g.num = 0));
          });
          inconsistentQuantityList.push(...arr);
          return;
        }

        arr.forEach(s => {
          let g = s.billGoods[0];
          total += +g.num;
          pickedTotal += +g.pickedNum || 0;
          priceTotal += +g.total;
          costPriceTotal += +g.totalCostPrice || 0;
        });

        // 2. 出库数量不一致
        if (total !== pickedTotal) {
          inconsistentQuantityList.push(...arr);
        }

        // 3. 报价小于成本
        if (priceTotal < costPriceTotal) {
          inconsistentPricesList.push(...arr);
        }
      });

      // 排序
      inconsistentQuantityList.sort((a, b) => +a.title - +b.title);
      inconsistentPricesList.sort((a, b) => +a.title - +b.title);

      // 拷贝 防止后续操作服务项时影响
      inconsistentQuantityList = this.$lodash.cloneDeep(inconsistentQuantityList);
      inconsistentPricesList = this.$lodash.cloneDeep(inconsistentPricesList);

      // 合并相同服务
      let merge = arr => {
        let templateDict = {};
        for (var i = 0; i < arr.length; ) {
          let id = arr[i].id;
          if (templateDict[id] === undefined) {
            templateDict[id] = i;
            i++;
          } else {
            arr[templateDict[id]].billGoods?.push(...arr[i].billGoods);
            arr.splice(i, 1);
          }
        }
      };
      merge(inconsistentQuantityList);
      merge(inconsistentPricesList);

      console.log("出库异常", inconsistentQuantityList);
      console.log("成本异常", inconsistentPricesList);

      this.inconsistentQuantityList = inconsistentQuantityList;
      this.inconsistentPricesList = inconsistentPricesList;
      this.goodsException = !!(this.inconsistentQuantityList.length || this.inconsistentPricesList.length);
    },
    carlossevent() {
      if (this.carBillInsuranceInfo === undefined || this.carBillInsuranceInfo.carLossDeterminationBillId === undefined) {
        this.$message.warning("当前车单无相应定损单，可能是历史理赔补单");
        return;
      }
      this.lossRecordDrawer = true;
    },
    handleClickVideo(e, list, index) {
      e.preventDefault();
      this.previewVideos = list;
      this.previewVideoIndex = index;
      this.showVideoPlayerDialog = true;
    },
    //查询车辆质保情况
    checkCarQueryWarranty() {
      var data = {
        billId: this.id,
      };
      getCarQueryWarranty(data).then(res => {
        this.underWarrantyState = res.underWarrantyState;
        let { vehicleVo, threePowerVo, powerBatteryVo, batteryVo, wearingPartsVo } = res;
        this.vehicleVo = vehicleVo || []; //整车质保
        this.threePowerVo = threePowerVo || []; //三电质保
        this.powerBatteryVo = powerBatteryVo || []; //动力电池质保
        this.batteryVo = batteryVo || []; //电芯质保
        this.wearingPartsVo = wearingPartsVo || []; //易损件质保
      });
    },
    //审核报价-复制操作
    copyVal() {
      carBillLinkContent(this.billMessage.billId).then(res => {
        if (res.code == 200) {
          var textToCopy = res.data;
          // 使用 Clipboard API 复制文本到剪贴板
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              this.$message.success("审核报价链接复制成功");
            })
            .catch(error => {
              this.$message.error("复制失败");
              console.error("复制失败：", error);
            });
        }
      });
    },
    /**
     * 查询消费客户现结/月结状态
     * @param {number} id 客户ID
     */
    getClientSettlementWay(id) {
      getClientSettlementWayApi({
        clientId: id,
        billType: this.billMessage.billType,
        companyId: this.companyId,
      }).then(res => {
        console.log(res, "res.data");
        this.settlementWay = res;
      });
    },
  },
};
</script>
<style lang="scss">
.bill-process {
  display: flex;
  padding-right: 20px;
  font-size: 12px;
  color: #999;

  .state-name {
    display: flex;
    flex-direction: column;
    text-align: center;

    span:first-child {
      font-size: 14px;
    }

    .time {
      margin-top: 5px;
    }
  }

  .split-string {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -8px 10px 0;

    .top-text {
      height: 17px;
      box-sizing: border-box;
      // position: absolute;
      // bottom: 100%;
      padding-bottom: 5px;
      white-space: nowrap;

      &.plan-time {
        // color: #333;
      }
    }

    .line {
      height: 1px;
      width: 100%;
      background: #d8d8d8;
      min-width: 50px;
    }

    .bottom-text {
      margin-top: 10px;
    }
  }
}
</style>
<style lang="scss" scoped>
.car-detail-box {
  margin-top: 8px;
  color: #333;
}

.customer-info {
  background: #fff;
  // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05),
  //   0px -2px 4px 0px rgba(0, 0, 0, 0.05);
  // border-radius: 4px;
  overflow: hidden;

  .customer-info-header {
    display: flex;
    // height: 60px;
    // border-bottom: 1px solid #e6e6e6;
    // padding-top: 16px;
    .bill-type {
      width: 53px;
      height: 64px;
      background: $primary;
      color: #fff;
      font-size: 18px;
      font-weight: 500;

      .bill-type-text {
        width: 18px;
        line-height: 24px;
        word-wrap: break-word;
      }
    }

    .car-logo {
      width: 64px;
      height: 64px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .car-info {
      .car-number {
        color: #303133;
        font-size: 20px;
        font-weight: 500;
      }

      .car-info-tag {
        display: inline-block;
        margin-left: 16px;
        padding: 4px 6px;
        border-radius: 2px;
        border: 1px solid #ebeef5;
        font-size: 12px;

        &.car-brand {
          color: #606266;
        }

        &.history {
          color: $primary;
          background: #eef7f3;
        }
      }
    }

    .client-info {
      .icon_grade {
        color: $second;
      }

      .client-name,
      .client-phone {
        text-decoration: underline;
        color: #303133;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .bill-state {
      font-size: 60px;
    }

    .car-remark {
      height: 64px;
      margin: 0 16px;
      padding: 4px 0;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      color: #909399;
      line-height: 20px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; // 控制多行的行数
      -webkit-box-orient: vertical;
    }

    .process-btn {
      background: #fff;

      &:hover {
        color: $primary;
        background: #fff;
      }

      &:active,
      &:focus {
        color: $primary !important;
      }
    }
  }

  // .row2 {
  //   display: flex;
  //   justify-content: space-between;
  //   padding: 15px 30px;
  //   .car-and-client-info-box {
  //     display: flex;
  //     align-items: center;

  //     .client-info {
  //       margin-left: 40px;
  //     }
  //   }

  //   .row2-right {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //     .status {
  //       display: flex;
  //       justify-content: flex-end;
  //       i {
  //         font-size: 50px;

  //         margin-left: 20px;
  //       }
  //       .grow-icon_order {
  //         color: $blue;
  //       }
  //       .grow-icon_warn1 {
  //         color: #4ebebc;
  //       }
  //       .grow-icon_chedan {
  //         color: $orange;
  //       }
  //     }
  //   }
  // }
  // 用户信息 车辆信息和他人自费共用
  // .client-base-info {
  //   .icon-person {
  //     color: #44bf86;
  //     margin-left: 0;
  //   }
  //   .client-name {
  //     margin-left: 10px;
  //     color: #333;
  //   }
  //   .client-phone {
  //     margin-left: 15px;
  //     color: #333;
  //   }
  //   .icon_sex {
  //     color: $blue;
  //   }
  //   .icon_grade {
  //     color: $blue;
  //   }
  //   .grow-icon_girl {
  //     color: #f48080;
  //   }
  //   .icon_wechat {
  //     color: #999;
  //   }
  //   .icon_wechat_active {
  //     color: #00c800;
  //   }
  //   i {
  //     margin-left: 15px;
  //   }
  // }
  // .client-vip-info {
  //   .vip-text {
  //     margin-left: 10px;
  //     color: #666;
  //     font-size: 14px;
  //   }
  // }
  // 他人自费信息 理赔 索赔 内容框
  // .row3-info-box {
  //   display: flex;
  //   align-items: center;
  //   padding: 20px;
  //   border-top: 1px solid #e6e6e6;
  //   color: #333;
  //   > .label {
  //     width: 100px;
  //     margin-right: 20px;
  //     display: flex;
  //     align-items: center;
  //     color: #333;
  //   }
  //   .select-client-box {
  //     > div + div {
  //       margin-top: 15px;
  //     }
  //   }
  //   .row {
  //     display: flex;
  //     padding: 10px 0;
  //     // align-items: center;
  //     font-size: 14px;
  //     color: #333;
  //     .label {
  //       width: 70px;
  //       flex-shrink: 0;
  //     }
  //     .value {
  //       color: #666;
  //     }
  //     // + .row {
  //     //   margin-top: 10px;
  //     // }
  //   }
  // }

  //   ::v-deep .el-table td {
  //  background-color: transparent !important;
  //  border: 0; //去除表格
  // }
}

.thumbnail-img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  cursor: pointer;
}

.video-list {
  display: inline-flex;

  .thumbnail-video {
    margin-left: 10px;
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
}

.riskControlCar {
  color: #e84931;
  font-weight: bold;
  font-size: 15px !important;
}

.bill-info-form {
  margin: 16px 0;
  background: #fff;
  border-radius: 4px 8px;

  .border-tag {
    margin-left: 12px;
    padding: 4px;
    border: 1px solid #ebeef5;
    border-radius: 2px;
    color: #606266;
    font-size: 12px;
  }

  .el-row {
    // & + .el-row {
    //   margin-top: 20px;
    // }
    .el-col {
      display: flex;
    }

    .label,
    .value {
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
    }

    .label {
      width: 96px;
      height: 32px;
      display: -webkit-inline-box;
      -webkit-box-pack: initial;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      color: #303133;
    }

    .value {
      min-height: 32px;
      padding-top: 6px;
      box-sizing: border-box;
      color: #606266;
    }
  }
}

.service-table ::v-deep .el-table__empty-text {
  width: 100% !important;
}

.service-table.el-table ::v-deep .el-table__placeholder {
  width: 0;
}

.service-table.el-table ::v-deep .el-table__indent {
  padding: 0 !important;
}

.btns {
  margin-top: 30px;

  .bao-btn {
    display: inline-block;
    width: 138px;
    height: 46px;
    background: $bg;
    color: $primary;
    text-align: center;
    line-height: 48px;
    margin-right: 30px;
    border-radius: 4px 4px 0px 0px;
  }

  .border-button {
    padding: 10px 19px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    place-self: flex-end;
    border: 1px solid $primary;
    border-radius: 4px;
    cursor: pointer;
    color: $primary;
    font-size: 14px;

    & + .border-button {
      margin-left: 16px;
    }

    &.orange-btn {
      padding: 10px 10px;
      flex-direction: row;
      border-color: $orange;
      color: $orange;
    }
  }

  .material-button {
    padding: 5px 19px;

    > div:last-child {
      margin-top: 4px;
      color: $red;
      font-size: 12px;
    }
  }

  .process-btn {
    margin-top: 10px;

    span {
      font-size: 14px;
      margin-right: 30px;
    }
  }
}

.footers {
  margin-top: 15px;
  height: 72px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 4px 4px;

  .bill-price-box {
    // padding-left: 20px;
    color: #666;

    .title {
      font-size: 14px;
    }

    .rmb {
      font-size: 12px;
      color: $orange;
    }

    .price-number {
      color: $orange;
      font-size: 18px;
    }

    .original-price {
      margin-left: 5px;
      color: #666;
      font-size: 12px;
    }
  }

  .price-detail {
    // margin-left: 10px;
    margin-top: 10px;
    font-size: 12px;
    color: #909399;
  }

  .buttons {
    margin: 30px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
}

// 重新服务弹窗
.remark-box {
  display: flex;
}

.tipsWord {
  font-size: 12px;
  color: #999999;
}

.custer-imgs {
  display: flex;
}

.service-table.el-table ::v-deep .el-table__row--level-1 {
  background: #f5f7fa;
}

// 不显示收起图标
.service-table.el-table ::v-deep .el-table__expand-icon {
  display: none;
}

//支付明细样式
.tip-word {
  margin-bottom: 16px;

  span:first-child {
    font-size: 16px;
    color: #000000;
  }

  span:last-child {
    font-size: 10px;
    color: #999999;
  }
}

.table-append {
  background: #f9f9f9;
  padding: 11px 0px 20px;

  .table_footer {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px 20px;
    font-size: 14px;
    color: #000000;
  }

  .table_footer_deriptt {
    margin-left: 10px;
  }
}

.cancel_word {
  margin: 29px 0px 17px;
  font-size: 16px;
  color: #000000;

  span {
    font-size: 14px;
  }
}

.custom-tabs {
  display: flex;

  .custom-tab-item {
    padding: 12px 20px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #eee;
    cursor: pointer;

    &.active,
    &:hover {
      color: $primary;
      background: $bg;
    }
  }

  &.nopadding .custom-tab-item {
    margin-right: 10px;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    color: #b8b8b8;
    background: #f5f5f5;
  }
}

//质保样式
.warranty-info {
  display: inline-block;
  margin-left: 16px;
  padding: 4px 6px;
  background: #f5f7fa;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  font-size: 12px;
  color: #606266;
}

.warranty-title {
  color: #3c3d40;
  margin-right: 5px;
}

.tips {
  color: #909399;
  font-size: 12px;
}

.agreementPriceDialog-clientInfo {
  margin: 10px 0 20px;
  padding: 20px;
  background: #f5f7fa;
  border-radius: 4px;

  i {
    margin-left: 20px;
  }
}

.car-bill-tag {
  margin: 0 5px 5px 0;
}
</style>
